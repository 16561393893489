import type { FC } from "react";

import { useTranslation } from "src/translations";
import type { DocumentId } from "src/types";
import { FundedRequestButton } from "../../../components";
import { logger } from "../../logger";

type FundedInfoProps = {
    loading: boolean;
    projectDocumentId: DocumentId;
};

export const FundedRequestInfo: FC<FundedInfoProps> = ({ loading, projectDocumentId }) => {
    const { t } = useTranslation();

    return (
        <div className="z-30 hidden flex-col gap-4 rounded-lg bg-white p-8 shadow-calculator lg:sticky lg:top-12 lg:flex xl:top-4">
            <div className="text-2xl font-medium leading-8 text-black">{t("PropertyDetail.FundedInfo.Title")}</div>
            <div className="text-base font-normal text-black">{t("PropertyDetail.FundedInfo.Text")}</div>
            {<FundedRequestButton loading={loading} projectDocumentId={projectDocumentId} logger={logger} />}
        </div>
    );
};
