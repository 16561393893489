import type { FC } from "react";

import { useBannerQuery } from "src/serverApi";
import { type BannerPageEnum } from "src/types";
import { BannerBase } from "./BannerBase";

type BannerProps = Readonly<{
    page: BannerPageEnum;
}>;

export const Banner: FC<BannerProps> = ({ page }) => {
    const { data: banner } = useBannerQuery(page);

    if (!banner) return null;
    return (
        <BannerBase
            mobileSrc={banner?.small?.url ?? ""}
            tabletSrc={banner?.medium?.url ?? ""}
            desktopSrc={banner?.large?.url ?? ""}
            linkUrl={banner?.link}
            altText={
                banner?.small?.alternativeText ||
                banner?.medium?.alternativeText ||
                banner?.large?.alternativeText ||
                banner?.small?.caption ||
                banner?.medium?.caption ||
                banner?.large?.caption ||
                "Banner Image"
            }
        />
    );
};
