import type { DocumentId, FundedRequestListResponse, FundedRequestRequest, FundedRequestResponse } from "src/types";
import { rootApi } from "./rootApi";
import { FUNDED_REQUEST_TAG } from "./tags";
import { getCmsUrl } from "./utils";

export const fundedRequestApi = rootApi.injectEndpoints({
    endpoints: (build) => ({
        hasUserFundedRequestForProjectDocumentId: build.query<boolean, { userId: number; projectDocumentId: DocumentId }>({
            query: (params) => ({
                url: getCmsUrl("/api/funded-requests"),
                params: {
                    filters: {
                        user: { id: params.userId },
                        project: { documentId: params.projectDocumentId },
                    },
                },
            }),
            keepUnusedDataFor: 10,
            providesTags: [FUNDED_REQUEST_TAG],
            transformResponse: (response: FundedRequestListResponse) => (response?.data ? response?.data.length > 0 : false),
        }),
        createFundedRequest: build.mutation<FundedRequestResponse, FundedRequestRequest>({
            query: (body) => ({
                url: getCmsUrl("/api/funded-requests"),
                method: "POST",
                body,
            }),
            invalidatesTags: [FUNDED_REQUEST_TAG],
        }),
    }),
});

export const { useCreateFundedRequestMutation, useHasUserFundedRequestForProjectDocumentIdQuery } = fundedRequestApi;
