import { type FC, useEffect, useState } from "react";

type MessageCountDownProps = Readonly<{
    countDownTill?: Date;
    onCountDownEnd?: () => void;
}>;

export const MessageCountDown: FC<MessageCountDownProps> = ({ countDownTill, onCountDownEnd }) => {
    const initialTime = countDownTill ? Math.floor((countDownTill.getTime() - Date.now()) / 1000) : null;
    const [timeRemaining, setTimeRemaining] = useState(initialTime ?? 0);

    useEffect(() => {
        const timerInterval = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime <= 0) {
                    onCountDownEnd?.();
                    clearInterval(timerInterval);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timerInterval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const days = Math.floor(timeRemaining / (60 * 60 * 24));
    const hours = Math.floor((timeRemaining % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((timeRemaining % (60 * 60)) / 60);
    const seconds = Math.floor(timeRemaining % 60);

    const countdown = `${hours.toString().padStart(2, "0")}h ${minutes.toString().padStart(2, "0")}m ${seconds.toString().padStart(2, "0")}s`;
    if (days > 0) {
        return <div className="min-w-[110px]">{`${days}d ${countdown}`}</div>;
    }
    return <div className="min-w-[90px]">{countdown}</div>;
};
