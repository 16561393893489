import { add, isPast } from "date-fns";
import { createContext, type FC, type ReactNode, useContext, useMemo, useState } from "react";
import type { ReadonlyDeep } from "type-fest";

import { type Message as MessageType, UserVerificationTypeEnum } from "src/types";
import { useUserVerification } from "src/verification";
import { useMessagesQuery, useUserQuery } from "../serverApi";

type MessagesContextType = ReadonlyDeep<{
    hideMessage: () => void;
    isMessageVisible: boolean;
    message: MessageType | null;
    countDownTill?: Date;
}>;

const MessagesContext = createContext<MessagesContextType>({
    hideMessage: () => {},
    isMessageVisible: true,
    message: null,
    countDownTill: undefined,
});

export const MessagesProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [isMessageVisible, setIsMessageVisible] = useState(true);
    const { data: messages, isLoading: isMessageLoading } = useMessagesQuery();
    const { data: user, isLoading: isUserLoading } = useUserQuery();
    const { isVerificationDone, isVerificationLoading } = useUserVerification();

    const messageContent: MessageType | null = useMemo(() => {
        if (isUserLoading || isMessageLoading || isVerificationLoading) {
            return null;
        }
        return (
            messages?.data?.find((message) => {
                const daysCountdown = message?.visibleForDaysFromRegistration;
                if (daysCountdown && user?.createdAt) {
                    const dateThresholdAfterCreation = add(new Date(user.createdAt), { days: daysCountdown });
                    if (isPast(dateThresholdAfterCreation)) {
                        return false;
                    }
                }
                if (message.type === "Verification") {
                    return !isVerificationDone(UserVerificationTypeEnum.KYC) || !isVerificationDone(UserVerificationTypeEnum.AML);
                }
                if (message.type === "Greenhorn") {
                    return !isVerificationDone(UserVerificationTypeEnum.INVESTOR);
                }
                return true;
            }) ?? null
        );
    }, [isMessageLoading, isUserLoading, isVerificationDone, isVerificationLoading, messages?.data, user?.createdAt]);

    const contextValue = useMemo(
        () => ({
            hideMessage: () => setIsMessageVisible(false),
            isMessageVisible,
            message: messageContent,
            countDownTill:
                messageContent?.visibleForDaysFromRegistration && user?.createdAt
                    ? add(new Date(user?.createdAt), { days: messageContent.visibleForDaysFromRegistration })
                    : undefined,
        }),
        [isMessageVisible, messageContent, user?.createdAt],
    );

    return <MessagesContext.Provider value={contextValue}>{children}</MessagesContext.Provider>;
};

export const useMessagesContext = () => useContext(MessagesContext);
