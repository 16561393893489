import { APP_CONFIG_TAG, getCmsUrl, rootApi } from "src/serverApi";
import type { AppConfiguration, AppConfigurationResponse } from "src/types";

export const configApi = rootApi.injectEndpoints({
    endpoints: (builder) => ({
        AppConfig: builder.query<AppConfiguration | undefined, void>({
            query: () => ({
                url: getCmsUrl("/api/app-configuration"),
                method: "GET",
                params: {
                    populate: "*",
                },
            }),
            providesTags: [APP_CONFIG_TAG],
            transformResponse: (response: AppConfigurationResponse) => response.data,
        }),
    }),
});

export const { useAppConfigQuery } = configApi;
