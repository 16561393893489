import type { FC, HTMLAttributes, ReactNode } from "react";

import { cn } from "src/utils";
import { Button } from "./Button";

type ButtonWithIconProps = Readonly<{
    icon: ReactNode;
    isLoading?: boolean;
}> &
    HTMLAttributes<HTMLButtonElement>;

export const ButtonWithIcon: FC<ButtonWithIconProps> = ({ children, isLoading = false, icon, className, ...props }) => {
    return (
        <Button
            className={cn("inline-flex items-center justify-center gap-2", className)}
            type="submit"
            fullWidth
            isLoading={isLoading}
            {...props}
        >
            {icon}
            {children}
        </Button>
    );
};
