import * as RadixAccordion from "@radix-ui/react-accordion";
import { forwardRef, type ReactNode } from "react";

import { cn } from "src/utils";

type AccordionItemProps = Readonly<{
    value: string;
    title: ReactNode;
    content: ReactNode;
    triggerIcon?: ReactNode;
    triggerClassName?: string;
    className?: string;
}>;

export const AccordionItem = forwardRef<HTMLDivElement, AccordionItemProps>(
    ({ value, title, content, triggerIcon, triggerClassName, className }, ref) => (
        <RadixAccordion.Item ref={ref} value={value} className={className}>
            <RadixAccordion.Header asChild>
                <RadixAccordion.Trigger
                    className={cn(triggerClassName, "flex w-full items-center justify-between gap-6 [&>svg]:data-[state=closed]:rotate-45")}
                >
                    {title}
                    {triggerIcon}
                </RadixAccordion.Trigger>
            </RadixAccordion.Header>
            <RadixAccordion.Content className="overflow-hidden data-[state=closed]:animate-accordionSlideUp data-[state=open]:animate-accordionSlideDown">
                <div className="py-8">{content}</div>
            </RadixAccordion.Content>
        </RadixAccordion.Item>
    ),
);

AccordionItem.displayName = RadixAccordion.Item.displayName;
