import type { CustomProperties, CustomPropertiesSupplier, ILoggingEventBuilder, LogLevel, MessageSupplier } from "../types";

export type LoggingEventBuilderData = {
    logLevel: LogLevel;
    message?: string;
    messageParams?: unknown[];
    messageSupplier?: MessageSupplier;
    customProperties?: CustomProperties;
    customPropertiesSupplier?: CustomPropertiesSupplier;
    cause?: unknown;
};
export type LogBuilderDataFn = (data: LoggingEventBuilderData) => void;

export class LoggingEventBuilder implements ILoggingEventBuilder {
    private readonly data: LoggingEventBuilderData;

    private readonly logBuilderDataFn: LogBuilderDataFn;

    constructor(logBuilderDataFn: LogBuilderDataFn, logLevel: LogLevel) {
        this.data = {
            logLevel,
        };
        this.logBuilderDataFn = logBuilderDataFn;
    }

    public log(): void {
        this.logBuilderDataFn(this.data);
    }

    public setCause(error: unknown): ILoggingEventBuilder {
        this.data.cause = error;
        return this;
    }

    public setCustomProperties(customProperties: CustomProperties): ILoggingEventBuilder {
        this.data.customProperties = customProperties;
        return this;
    }

    public setCustomPropertiesSupplier(customPropertiesSupplier: CustomPropertiesSupplier): ILoggingEventBuilder {
        this.data.customPropertiesSupplier = customPropertiesSupplier;
        return this;
    }

    public setMessage(message: string): ILoggingEventBuilder {
        this.data.message = message;
        return this;
    }

    public setMessageParams(...messageParams: unknown[]): ILoggingEventBuilder {
        this.data.messageParams = messageParams;
        return this;
    }

    public setMessageSupplier(messageSupplier: MessageSupplier): ILoggingEventBuilder {
        this.data.messageSupplier = messageSupplier;
        return this;
    }
}
