import type { EmptyActionCreator, PayloadActionCreator } from "src/types";
import { createModuleActionCreators } from "src/utils";
import { NAME } from "./constants";
import type { PredefinedShowActionPayload, ShowActionPayload } from "./types";

const { createEmptyAction, createPayloadAction } = createModuleActionCreators(NAME);

export type ShowNotificationActions =
    | ReturnType<typeof showNotification>
    | ReturnType<typeof showSuccessNotification>
    | ReturnType<typeof showErrorNotification>
    | ReturnType<typeof showWarningNotification>
    | ReturnType<typeof showInfoNotification>;

/**
 * Shows notification of specified type.
 * By default, notification can be closed manually by user and is closed automatically after 5 seconds (DEFAULT_AUTO_CLOSE_MS).
 * If this or other notification action is called multiple times, then only content from last action is displayed.
 * Multiple notifications are not supported.
 */
export const showNotification: PayloadActionCreator<ShowActionPayload> = createPayloadAction("SHOW")<ShowActionPayload>();

/**
 * Shows success notification with title translated from "core.notifications.success.title" key.
 * By default, notification can be closed manually by user and is closed automatically after 5 seconds (DEFAULT_AUTO_CLOSE_MS).
 * If this or other notification action is called multiple times, then only content from last action is displayed.
 * Multiple notifications are not supported.
 */
export const showSuccessNotification: PayloadActionCreator<PredefinedShowActionPayload> =
    createPayloadAction("SHOW_SUCCESS")<PredefinedShowActionPayload>();

/**
 * Shows error notification with title translated from "core.notifications.error.title" key.
 * By default, notification can be closed manually by user and is closed automatically after 5 seconds (DEFAULT_AUTO_CLOSE_MS).
 * If this or other notification action is called multiple times, then only content from last action is displayed.
 * Multiple notifications are not supported.
 */
export const showErrorNotification: PayloadActionCreator<PredefinedShowActionPayload> =
    createPayloadAction("SHOW_ERROR")<PredefinedShowActionPayload>();

/**
 * Shows info notification with title translated from "core.notifications.info.title" key.
 * By default, notification can be closed manually by user and is closed automatically after 5 seconds (DEFAULT_AUTO_CLOSE_MS).
 * If this or other notification action is called multiple times, then only content from last action is displayed.
 * Multiple notifications are not supported.
 */
export const showInfoNotification: PayloadActionCreator<PredefinedShowActionPayload> =
    createPayloadAction("SHOW_INFO")<PredefinedShowActionPayload>();

/**
 * Shows warning notification with title translated from "core.notifications.warning.title" key.
 * By default, notification can be closed manually by user and is closed automatically after 5 seconds (DEFAULT_AUTO_CLOSE_MS).
 * If this or other notification action is called multiple times, then only content from last action is displayed.
 * Multiple notifications are not supported.
 */
export const showWarningNotification: PayloadActionCreator<PredefinedShowActionPayload> =
    createPayloadAction("SHOW_WARNING")<PredefinedShowActionPayload>();

/**
 * Hides currently displayed notification or does nothing if there is no notification displayed.
 */
export const hideNotification: EmptyActionCreator = createEmptyAction("HIDE");
