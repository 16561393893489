import { Routes } from "src/routes";
import { typeCheckAppModule } from "src/utils";
import { NAME } from "./constants";
import { EmailVerifiedPage } from "./EmailVerifiedPage";

export const emailVerified = typeCheckAppModule({
    NAME,
    Container: EmailVerifiedPage,
    route: Routes.emailVerified,
    secure: false,
});
