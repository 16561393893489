import { format } from "date-fns";
import { sprintf } from "sprintf-js";

import type { CustomProperties, Logger, LoggerConfig, LogLevel, LogLine } from "../types";
import { getCommonCustomProperties, logToConsole } from "../utils";
import { BasicLogger } from "./BasicLogger";
import type { LoggingEventBuilderData } from "./LoggingEventBuilder";

/**
 * Provides default implementation of shared functionality for remote loggers:
 * - Message parameters interpolation.
 * - Creation of final log line.
 * - Assembling of custom properties.
 */
export abstract class RemoteLogger extends BasicLogger implements Logger {
    private remoteLoggingEnabled: boolean;

    protected constructor(loggerName: string, config: LoggerConfig, remoteLoggingEnabled: boolean = true) {
        super(loggerName, config);
        this.remoteLoggingEnabled = remoteLoggingEnabled;
    }

    protected getLogLine({ logLevel, message, messageParams, messageSupplier }: LoggingEventBuilderData): string {
        let resultMessage = "";
        if (message !== undefined) {
            resultMessage = messageParams === undefined ? message : sprintf(message.replace("%O", "%j"), ...messageParams);
        } else if (messageSupplier !== undefined) {
            resultMessage = messageSupplier();
        }

        return `${this.getMessagePrefix(logLevel)} - ${resultMessage}`;
    }

    protected getMessagePrefix(logLevel: LogLevel): string {
        return `${format(new Date(), "yyyy-MM-dd HH:mm:ss")} ${logLevel.label} ${this.name}`;
    }

    protected abstract transportLog(level: LogLevel, logLine: LogLine, customProperties: CustomProperties, cause: unknown): void;

    protected log(logData: LoggingEventBuilderData) {
        const { logLevel, customProperties, customPropertiesSupplier, cause } = logData;
        const effectiveCustomProperties = {
            module: this.name,
            ...getCommonCustomProperties(),
            ...(customProperties ?? customPropertiesSupplier?.()),
        };
        const logLine = this.getLogLine(logData);

        logToConsole(logLevel, logLine, undefined, effectiveCustomProperties, cause);
        if (this.remoteLoggingEnabled) {
            this.transportLog(logLevel, logLine, effectiveCustomProperties, cause);
        }
    }

    public setRemoteLoggingEnabled(enabled: boolean) {
        this.remoteLoggingEnabled = enabled;
    }
}
