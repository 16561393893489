import { AddressAutofill } from "@mapbox/search-js-react";
import { Select, SelectItem } from "components/Select";
import type { FC } from "react";
import { CircleFlag } from "react-circle-flags";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { AccordionItem, Badge, CmsError, Heading, Input } from "src/components";
import { CloseIcon } from "src/icons";
import { useCountriesQuery } from "src/serverApi";
import { type User, UserVerificationTypeEnum } from "src/types";
import { getAmlFormScheme } from "./AmlFormScheme";
import { QuestionnaireSection } from "./enums";
import { getLocalizedRegionNameFromCountryCode } from "./utils";

type PersonalInfoProps = Readonly<{
    user: User;
}>;

export const PersonalInfo: FC<PersonalInfoProps> = ({ user }) => {
    const { t } = useTranslation();

    const { data: countries, isLoading: isCountriesLoading, error: countriesError, isError: isCountriesError } = useCountriesQuery();

    const schema = getAmlFormScheme(t);
    type FormData = z.infer<typeof schema>;

    const {
        register,
        control,
        formState: { errors },
    } = useFormContext<FormData>();

    return (
        <AccordionItem
            value={QuestionnaireSection.PersonalInfo}
            className="my-6 overflow-hidden rounded border bg-white p-6"
            title={
                user?.verification.some((verification) => verification.type === UserVerificationTypeEnum.KYC) ? (
                    <div className="flex items-center gap-x-3">
                        <Heading level={3} className="max-sm:text-left">
                            {t("AMLQuestionnaire.Section1Heading.Verified.Heading")}
                        </Heading>
                        <Badge intent="subtleSuccess" className="text-sm">
                            {t("AMLQuestionnaire.Section1Heading.Verified.Badge")}
                        </Badge>
                    </div>
                ) : (
                    <Heading level={3}>{t("AMLQuestionnaire.Section1Heading.NotVerified.Heading")}</Heading>
                )
            }
            triggerIcon={<CloseIcon className="size-8 transition-transform duration-300" />}
            content={
                <div className="flex flex-col gap-y-4 py-8">
                    {user?.userProfile && (
                        <p className="text-left text-sm text-secondary">{t("AMLQuestionnaire.Section1Heading.Verified.Note")}</p>
                    )}
                    <div className="flex w-full flex-col gap-x-5 gap-y-2 sm:flex-row">
                        <Input
                            type="text"
                            {...register("firstName")}
                            label={t("AMLQuestionnaire.Section1.Inputs.FirstName.Label")}
                            error={errors.firstName}
                            disabled={!!user?.userProfile?.firstName}
                        />
                        <Input
                            type="text"
                            {...register("lastName")}
                            label={t("AMLQuestionnaire.Section1.Inputs.LastName.Label")}
                            error={errors.lastName}
                            disabled={!!user?.userProfile?.lastName}
                        />
                    </div>
                    <Controller
                        name="residentialAddress"
                        control={control}
                        render={({ field }) => (
                            // Incompatible typings with typescript 5
                            // @ts-expect-error
                            <AddressAutofill accessToken={import.meta.env.VITE_MAPBOX_TOKEN}>
                                <Input
                                    {...field}
                                    ref={field.ref}
                                    type="text"
                                    label={t("AMLQuestionnaire.Section1.Inputs.Address.Label")}
                                    error={errors.residentialAddress}
                                    autoComplete="address-line1"
                                />
                            </AddressAutofill>
                        )}
                    />
                    <Input
                        type="text"
                        {...register("cityAddress")}
                        label={t("AMLQuestionnaire.Section1.Inputs.AddressCity.Label")}
                        error={errors.cityAddress}
                        autoComplete="address-level2"
                    />
                    <Input
                        type="text"
                        {...register("postalCode")}
                        label={t("AMLQuestionnaire.Section1.Inputs.AddressPostalCode.Label")}
                        error={errors.postalCode}
                        autoComplete="postal-code"
                    />
                    <Controller
                        name="countryAddress"
                        control={control}
                        render={({ field }) => (
                            <>
                                <Select
                                    {...field}
                                    label={t("AMLQuestionnaire.Section1.Inputs.AddressCountry.Label")}
                                    error={errors.countryAddress}
                                    disabled={isCountriesError || isCountriesLoading}
                                >
                                    {(countries ?? []).map(({ alpha2, alpha3 }) => (
                                        <SelectItem key={alpha3} value={alpha3}>
                                            <div className="flex items-center gap-2">
                                                <CircleFlag countryCode={alpha2.toLowerCase()} className="w-[18px]" />
                                                {getLocalizedRegionNameFromCountryCode(alpha2)}
                                            </div>
                                        </SelectItem>
                                    ))}
                                </Select>
                                {isCountriesError && <CmsError error={countriesError} fallbackMessage={t("Form.error")} />}
                            </>
                        )}
                    />
                    <div className="flex w-full flex-col gap-x-5 gap-y-2 sm:flex-row">
                        <Input
                            type="date"
                            {...register("dateOfBirth")}
                            label={t("AMLQuestionnaire.Section1.Inputs.DateOfBirth.Label")}
                            error={errors.dateOfBirth}
                            disabled={!!user?.userProfile?.dateOfBirth}
                        />
                        <Input
                            type="text"
                            {...register("socialSecurityNumber")}
                            label={t("AMLQuestionnaire.Section1.Inputs.SocialSecurityNumber.Label")}
                            error={errors.socialSecurityNumber}
                            optional
                            disabled={!!user?.userProfile?.socialSecurityNumber}
                        />
                    </div>
                    <Input
                        type="text"
                        {...register("idOrPassportNumber")}
                        label={t("AMLQuestionnaire.Section1.Inputs.IDPassportNumber.Label")}
                        error={errors.idOrPassportNumber}
                        disabled={!!user?.userProfile?.idOrPassportNumber}
                    />
                    <Input
                        type="text"
                        {...register("countryOfOrigin")}
                        label={t("AMLQuestionnaire.Section1.Inputs.CountryOfOrigin.Label")}
                        additionalInfo={t("AMLQuestionnaire.Section1.Inputs.CountryOfOrigin.AdditionalInfo")}
                        error={errors.countryOfOrigin}
                    />
                    <Input
                        type="text"
                        {...register("citizenship")}
                        label={t("AMLQuestionnaire.Section1.Inputs.Citizenship.Label")}
                        error={errors.citizenship}
                    />
                    <Input
                        type="date"
                        {...register("documentValidUntil")}
                        label={t("AMLQuestionnaire.Section1.Inputs.DocumentValidUntil.Label")}
                        error={errors.documentValidUntil}
                        disabled={!!user?.userProfile?.documentValidUntil}
                    />
                    <Input
                        type="text"
                        {...register("documentIssuedBy")}
                        label={t("AMLQuestionnaire.Section1.Inputs.DocumentIssuedBy.Label")}
                        error={errors.documentIssuedBy}
                        additionalInfo={t("AMLQuestionnaire.Section1.Inputs.DocumentIssuedBy.AdditionalInfo")}
                        disabled={!!user?.userProfile?.documentIssuedBy}
                    />
                </div>
            }
        />
    );
};
