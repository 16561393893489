import type { FC } from "react";

import { NoContent, PageLayout } from "src/components";
import { useProjectsInfoQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { BannerPageEnum } from "src/types";
import { ProjectCard } from "./components";

export const ProjectsPage: FC = () => {
    const { t } = useTranslation();
    const { data: projectsInfo, isLoading, isFetching } = useProjectsInfoQuery({});

    // TODO: sorting

    const loading = isLoading || isFetching;

    return (
        <PageLayout title={t("ProjectListPage.Title")} loading={loading} banner={BannerPageEnum.Projects}>
            {!loading && !projectsInfo?.data?.length && <NoContent transKey="ProjectListPageCard.NoProjects" />}
            <div className="mx-auto w-full max-w-[1250px] space-y-8">
                {projectsInfo?.data?.map((projectInfo) => <ProjectCard key={projectInfo.id} projectInfo={projectInfo} />)}
            </div>
        </PageLayout>
    );
};
