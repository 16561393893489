import { Routes } from "src/routes";
import { typeCheckAppModule } from "src/utils";
import { NAME } from "./constants";
import { OrdersPage } from "./OrdersPage";

export const orders = typeCheckAppModule({
    NAME,
    Container: OrdersPage,
    route: Routes.orders,
});
