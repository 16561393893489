import type { FC } from "react";

import { Heading } from "src/components";
import { useUserQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { UserVerificationTypeEnum } from "src/types";
import { logGtmEvent, useComponentDidMount } from "src/utils";
import { AmlForm } from "./AmlForm";

export const Aml: FC = () => {
    const { t } = useTranslation();

    const { data: user } = useUserQuery();

    const hasAml = user?.verification.some((verification) => verification.type === UserVerificationTypeEnum.AML);

    useComponentDidMount(() => {
        logGtmEvent({ event: "user_aml_entered" });
    });

    if (hasAml) return <Heading className="mt-16 text-center">{t("AMLQuestionnaire.completed")}</Heading>;
    return (
        <section className="mx-auto mt-6 flex w-full max-w-[39.5rem] flex-col gap-4 p-2">
            <div className="flex flex-col gap-4">
                <p>{t("AMLQuestionnaire.LegalDescription")}</p>
                <div className="flex flex-col gap-2">
                    <strong>{t("AMLQuestionnaire.Administrator")}:</strong>
                    {t("AMLQuestionnaire.CompanyName")}
                    <br />
                    {t("AMLQuestionnaire.ID")}
                    <br />
                    {t("AMLQuestionnaire.RegisteredOffice")}
                </div>
            </div>
            <AmlForm />
        </section>
    );
};
