import { useNavigate } from "react-router-dom";

import { Routes } from "src/routes";
import { Trans, useTranslation } from "src/translations";
import { Button } from "../Button";
import { Heading } from "../Heading";
import { Logo } from "../Layout/Logo";

export const Error404Page = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="flex h-screen flex-col items-center justify-center bg-gray-50">
            <div className="m-2 max-w-[600px] overflow-hidden rounded-lg border border-gray-200 bg-white p-4 shadow-lg md:m-0 md:p-8">
                <div className="flex justify-end">
                    <Logo className="max-w-[100px]" />
                </div>
                <Heading level={2} className="mb-6">
                    {t("ErrorPage.404.title")}
                </Heading>
                <p className="mb-4 text-gray-600">
                    <Trans
                        i18nKey="ErrorPage.404.urlDetail"
                        values={{
                            url: window.location.href,
                        }}
                        components={{
                            bold: <strong className="text-brand-primary" />,
                        }}
                    />
                </p>
                <p className="mb-4 text-gray-600">{t("ErrorPage.404.text")}</p>
                <Button onClick={() => navigate(Routes.projects.path)}>{t("ErrorPage.404.button")}</Button>
            </div>
        </div>
    );
};
