import { Arrow, Content, Portal, Root, Trigger } from "@radix-ui/react-popover";
import type { FC, ReactNode } from "react";

import { cn } from "src/utils";

type IconWithPopoverProps = {
    icon: ReactNode;
    className?: string;
    side?: "top" | "right" | "bottom" | "left";
    children: ReactNode;
};

export const IconWithPopover: FC<IconWithPopoverProps> = ({ icon, children, side = "bottom", className }) => (
    <Root>
        <Trigger asChild>
            <button type="button" aria-label="Additional information">
                {icon}
            </button>
        </Trigger>
        <Portal>
            <Content
                className={cn("z-50 mx-4 max-w-[18.75rem] rounded bg-brand-foreground p-2 text-xs text-white", className)}
                sideOffset={5}
                side={side}
            >
                {children}
                <Arrow className="fill-primary" />
            </Content>
        </Portal>
    </Root>
);
