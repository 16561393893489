import type { GtmEvent } from "src/types";
import { isDevelopment } from "./appVersion";
import { isNullish } from "./typeGuards";

type LogGtmEvent = (event: GtmEvent, every?: boolean) => void;

// TODO :: This is temporary, we don't want to log GTM events on development
const logGtmEventEnabled = false;

export const logGtmEvent: LogGtmEvent = (event, every = true) => {
    if (!window.dataLayer) {
        // eslint-disable-next-line
        console.error("Google Tag Manager has not been initialized!");
        return;
    }

    if (!isDevelopment && logGtmEventEnabled) {
        if (every) {
            window.dataLayer.push(event);
        } else {
            const eventIdentifier = JSON.stringify(event);
            const localStorageKey = `gtm-event-${eventIdentifier}`;
            const hasEventBeenLogged = localStorage.getItem(localStorageKey);
            if (isNullish(hasEventBeenLogged)) {
                window.dataLayer.push(event);
                localStorage.setItem(localStorageKey, "true");
            }
        }
    }
};
