import type { FC } from "react";
import Carousel, { type Image } from "react-gallery-carousel";
import Skeleton from "react-loading-skeleton";

import { useTranslation } from "src/translations";
import type { ImageData } from "src/types";
import { isNotNullish } from "src/utils";

type ProjectDetailGalleryProps = Readonly<{
    projectImages: ImageData[];
    loading: boolean;
}>;

export const ProjectDetailGallery: FC<ProjectDetailGalleryProps> = ({ projectImages, loading }) => {
    const { t } = useTranslation();

    const images: Image[] = projectImages
        .map(
            (img) =>
                img && {
                    alt: img.alternativeText || img.caption || "",
                    src: img.url || "",
                    thumbnail: img.previewUrl ?? img.url,
                },
        )
        .filter(isNotNullish);

    if (loading) return <Skeleton width="100%" height="500px" />;
    if (!images || images.length === 0) return t("ProjectDetail.Images.notFound");

    return (
        <Carousel
            images={images}
            hasDotButtons={false}
            hasMediaButton={false}
            hasLeftButton={false}
            hasRightButton={false}
            hasIndexBoard={false}
            widgetsHasShadow={false}
            canAutoPlay={false}
            thumbnailHeight="120px"
            thumbnailWidth="150px"
            style={{ maxHeight: "500px", borderRadius: "8px", overflow: "hidden", backgroundColor: "transparent" }}
        />
    );
};
