import type { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Heading, PublicLayout } from "src/components";
import { Routes } from "src/routes";
import { useTranslation } from "src/translations";

export const EmailVerifiedPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <PublicLayout>
            <Heading level={2} className="mb-8">
                {t("VerifyEmail.message")}
            </Heading>
            <Button onClick={() => navigate(Routes.signIn.path)} fullWidth>
                {t("VerifyEmail.login")}
            </Button>
        </PublicLayout>
    );
};
