import { forwardRef, type JSX, type PropsWithoutRef } from "react";
import type { FieldError } from "react-hook-form";

import { cn } from "src/utils";

type UncontrolledFormCheckboxProps = Readonly<{
    error?: FieldError;
}> &
    PropsWithoutRef<JSX.IntrinsicElements["input"]>;

export const UncontrolledFormCheckbox = forwardRef<HTMLInputElement, UncontrolledFormCheckboxProps>(
    ({ children, error, className, ...props }, ref) => (
        <div className="flex flex-col gap-y-1">
            <label className="flex items-start whitespace-nowrap">
                <input
                    type="checkbox"
                    ref={ref}
                    className={cn(
                        "form-checkbox size-[1.125rem] rounded-[0.3125rem] border border-tertiary bg-white !ring-0 transition-colors duration-200 ease-in-out checked:!bg-brand hover:bg-subtle",
                        error && "border-brand-error focus:border-brand-error",
                        className,
                    )}
                    {...props}
                />
                <div className="ml-2 inline-block whitespace-pre-wrap text-sm">{children}</div>
            </label>
            {error && <p className="mb-1 text-sm text-error">{error.message}</p>}
        </div>
    ),
);

UncontrolledFormCheckbox.displayName = "Checkbox";
