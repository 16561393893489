import type { FC } from "react";

import type { Article } from "src/types";
import { getUploadFileName } from "src/utils";

type PostCardImageProps = {
    image?: Article["image"];
};

export const ArticleCardImage: FC<PostCardImageProps> = ({ image }) => {
    const imageUrl = image?.formats?.small?.url ?? image?.url;

    return (
        imageUrl && (
            <div className="aspect-3/2 max-w-[372px] overflow-hidden">
                <img className="size-full rounded-none object-cover" src={imageUrl} alt={getUploadFileName(image)} />
            </div>
        )
    );
};
