import type { FC } from "react";

import { useTranslation } from "src/translations";
import type { Article } from "src/types";
import { ArticlePermissionsComponentTypeEnum1 } from "src/types";
import { cn, useLocalDate } from "src/utils";
import { Badge, type BadgeIntent } from "../Badge";
import ArticleTags from "./ArticleTags";

export type ArticleAttributesProps = {
    type?: ArticlePermissionsComponentTypeEnum1;
    tags?: Article["tags"];
    className?: string;
    updatedAt: Article["updatedAt"];
};

export const ArticleAttributes: FC<ArticleAttributesProps> = ({ updatedAt, tags, className, type }) => {
    const toLocalDate = useLocalDate();
    const { t } = useTranslation();

    const permissionType = type ? articleTypeMap[type] : undefined;

    return (
        <div className={cn("flex items-center gap-3", className)}>
            {permissionType && <Badge intent={permissionType.intent}>{t(permissionType.titleKey)}</Badge>}
            <ArticleTags tags={tags} />
            {updatedAt && <p>{toLocalDate(new Date(updatedAt))}</p>}
        </div>
    );
};

const articleTypeMap: Record<ArticlePermissionsComponentTypeEnum1, { titleKey: string; intent: BadgeIntent }> = {
    [ArticlePermissionsComponentTypeEnum1.Public]: {
        titleKey: "News.Type.Public",
        intent: "slate",
    },
    [ArticlePermissionsComponentTypeEnum1.OnlyInvestors]: {
        titleKey: "News.Type.Investors",
        intent: "subtleSuccess",
    },
    [ArticlePermissionsComponentTypeEnum1.Private]: {
        titleKey: "News.Type.Private",
        intent: "purple",
    },
};
