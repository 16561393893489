import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import type { FC, ReactNode } from "react";

import { useMessagesContext } from "src/messages";
import type { BannerPageEnum } from "src/types";
import { cn } from "src/utils";
import { Banner } from "../Banner";
import { Heading } from "../Heading";
import { Loading } from "../Loading";
import { Message } from "../Message";
import { NavigationMenuDesktop, TopBar } from "./Navigation";

type PageLayoutProps = Readonly<{
    children: ReactNode;
    title?: string;
    titleAlign?: "center" | "left";
    className?: string;
    loading?: boolean;
    rightCornerComponent?: ReactNode;
    banner?: BannerPageEnum;
}>;

export const PageLayout: FC<PageLayoutProps> = ({
    children,
    title,
    titleAlign = "left",
    loading,
    rightCornerComponent,
    banner,
    className,
}) => {
    const { isMessageVisible, message, countDownTill } = useMessagesContext();
    return (
        <>
            <NavigationMenuDesktop className="hidden xl:flex" />
            <main className={cn("relative flex max-w-full flex-col xl:ml-left-menu-width", className)}>
                {isMessageVisible && message && <Message countDownTill={countDownTill} text={<BlocksRenderer content={message.text} />} />}
                <TopBar className="sticky top-0 z-20 bg-white xl:hidden" />
                <div className="flex flex-1 flex-col gap-10 px-16 py-9 max-sm:gap-4 max-sm:px-4 max-sm:py-2">
                    <div className="flex place-items-center">
                        <Heading
                            level={1}
                            className={cn("inline-block font-manrope text-3xl font-bold sm:text-4xl", {
                                "text-center": titleAlign === "center",
                            })}
                        >
                            {title}
                        </Heading>
                        {rightCornerComponent && <div className="ml-auto">{rightCornerComponent}</div>}
                    </div>
                    {banner && <Banner page={banner} />}
                    {loading ? <Loading /> : children}
                </div>
            </main>
        </>
    );
};
