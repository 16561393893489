import type { FC } from "react";

import type { Article } from "src/types";
import { Badge } from "../Badge";

type Props = {
    tags?: Article["tags"];
};

const ArticleTags: FC<Props> = ({ tags }) => {
    if (!tags) return null;

    return tags.length > 0 ? (
        <div className="flex items-center gap-3">
            {tags && tags.length > 0 && tags.map((tag) => <Badge key={tag.id}>{tag?.name}</Badge>)}
        </div>
    ) : null;
};

export default ArticleTags;
