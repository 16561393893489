import type { FC } from "react";

import { useVoteWeightQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { type Voting, VotingStateEnum } from "src/types";
import { cn } from "src/utils";

type VotingCardInfoWeightProps = {
    votingItem: Voting;
    className?: string;
};

export const VotingCardInfoWeight: FC<VotingCardInfoWeightProps> = ({ votingItem, className }) => {
    const { t } = useTranslation();

    const { data, isLoading, isError } = useVoteWeightQuery({
        projectDocumentId: votingItem.project?.documentId!,
        voteType: votingItem.voteType,
        permission: votingItem.permission,
    });

    if (isLoading) return <div className="h-5 w-40 animate-pulse rounded-lg" />;

    if (!data?.weight || votingItem.state === VotingStateEnum.Past || isError) return null;

    return (
        <p className={cn("text-sm", className)}>
            {t("VotingPage.Card.Info.YourVotingWeight")} <span className="font-bold">{data.weight}%</span>
        </p>
    );
};
