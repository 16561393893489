import { Routes } from "src/routes";
import { typeCheckAppModule } from "src/utils";
import { NAME } from "./constants";
import { NewPasswordPage } from "./NewPasswordPage";

export const newPassword = typeCheckAppModule({
    NAME,
    Container: NewPasswordPage,
    route: Routes.newPassword,
    secure: false,
});
