import type { Logger, LoggerConfig } from "../types";
import { logToConsole } from "../utils";
import { BasicLogger } from "./BasicLogger";
import { type LoggingEventBuilderData } from "./LoggingEventBuilder";

/**
 * Supported message placeholders are identical to console.log function.
 */
export class ConsoleLogger extends BasicLogger implements Logger {
    public constructor(loggerName: string, config: LoggerConfig) {
        super(loggerName, config);
    }

    protected log(logData: LoggingEventBuilderData): void {
        const { logLevel, message, messageParams, messageSupplier, customProperties, customPropertiesSupplier, cause } = logData;
        const effectiveCustomProperties = {
            module: this.name,
            ...(customProperties ?? customPropertiesSupplier?.()),
        };
        logToConsole(logLevel, message ?? messageSupplier?.(), messageParams, effectiveCustomProperties, cause);
    }
}
