import { type FC, useState } from "react";

import { CloseIcon, MenuIcon } from "src/icons";
import { cn } from "src/utils";
import { Logo } from "../Logo";
import { LanguageSwitcher } from "./LanguageSwitcher";
import { menuItems } from "./menuItems";
import { NavigationMenuItem } from "./NavigationMenuItem";
import { UserSection } from "./UserSection";

export const NavigationMenuMobile: FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <>
            <button
                type="button"
                className="size-[24px] xl:hidden"
                aria-label="mobile menu"
                onClick={() => {
                    setIsMenuOpen(true);
                }}
            >
                <MenuIcon />
            </button>
            <nav
                className={cn(
                    "pointer-events-none fixed left-0 top-0 z-50 size-full flex-col border-r border-primary bg-subtle pb-4 font-inter font-medium opacity-0 transition-opacity xl:hidden",
                    {
                        "opacity-1 pointer-events-auto flex": isMenuOpen,
                    },
                )}
            >
                <div className="flex w-full items-center justify-between border-b p-5">
                    <Logo className="h-[24px] sm:h-[28px]" />
                    <div className="flex flex-1 items-center justify-end gap-4">
                        <button
                            type="button"
                            className="size-[24px]"
                            aria-label="mobile menu"
                            onClick={() => {
                                setIsMenuOpen(false);
                            }}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                </div>
                <div className="flex grow flex-col pt-4">
                    <ul className="flex grow flex-col gap-2">
                        {menuItems.map((menuItem) => (
                            <NavigationMenuItem className="last:mt-auto" key={menuItem.route.path} {...menuItem} />
                        ))}
                    </ul>
                </div>
                <LanguageSwitcher />
                <UserSection />
            </nav>
        </>
    );
};
