import { Routes } from "src/routes";
import { typeCheckAppModule } from "src/utils";
import { NAME } from "./constants";
import { ProjectDetailPage } from "./ProjectDetailPage";

export const projectDetail = typeCheckAppModule({
    NAME,
    Container: ProjectDetailPage,
    route: Routes.projectDetail,
});
