import type { FC } from "react";

import { ArticleCard, CmsError, PageLayout } from "src/components";
import { useArticlesQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { ArticleRequestTypeEnum, BannerPageEnum, UserVerificationTypeEnum } from "src/types";
import { isNullish } from "src/utils";
import { useUserVerification } from "src/verification";

export const InvestorClubPage: FC = () => {
    const { t } = useTranslation();

    const { isVerificationLoading, isVerificationDone } = useUserVerification();

    const isInvestor = isVerificationDone(UserVerificationTypeEnum.INVESTOR);

    const { data, isLoading, isError, error } = useArticlesQuery(
        { filters: { type: ArticleRequestTypeEnum.InvestorClub } },
        { skip: !isInvestor },
    );

    const articles = data?.data;

    return (
        <PageLayout title={t("InvestorClub.Title")} loading={isLoading || isVerificationLoading} banner={BannerPageEnum.InvestorClub}>
            {isError ? <CmsError error={error} fallbackMessage={t("Form.error")} /> : null}
            {isNullish(articles) ?? articles?.length === 0 ? (
                <p>{isInvestor ? t("Articles.NoData") : t("InvestorClub.NotAvailable")}</p>
            ) : (
                articles?.map((article) => <ArticleCard key={article.id} {...article} />)
            )}
        </PageLayout>
    );
};
