import type { CSSProperties, FC, ReactNode } from "react";

import backgroundImage from "src/assets/img/public_bg_img.jpg";
import { TopBar } from "./Navigation";

type PublicLayoutProps = Readonly<{
    children: ReactNode;
}>;

export const PublicLayout: FC<PublicLayoutProps> = ({ children }) => (
    <div className="grid h-screen w-full grid-cols-1 lg:grid-cols-2">
        <div className="flex w-full flex-col items-center">
            <TopBar withMobileNavigation={false} />
            <div className="flex size-full max-w-[444px] flex-col items-center justify-center p-4">{children}</div>
        </div>
        <div
            style={
                {
                    "--image-url": `url(${backgroundImage})`,
                } as CSSProperties
            }
            className="hidden w-full items-center justify-center overflow-hidden bg-[image:var(--image-url)] bg-cover bg-center lg:flex"
        />
    </div>
);
