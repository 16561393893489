import type { FC, ReactNode } from "react";

import { Routes } from "src/routes";
import { useTranslation } from "src/translations";
import { OrderStateEnum, type ProjectInfo } from "src/types";
import { Badge, orderStateBadgeMap } from "../Badge";
import { Heading } from "../Heading";
import { Link } from "../Link";

type OrderLayoutProps = Readonly<{
    title: string;
    projectInfo?: ProjectInfo;
    orderState?: OrderStateEnum;
    openInNewTab?: boolean;
    children: ReactNode;
}>;

export const OrderLayout: FC<OrderLayoutProps> = ({ title, projectInfo, orderState, children, openInNewTab = false }) => {
    const { t } = useTranslation();

    const project = projectInfo?.project;
    const thumbnailUrl = project?.images?.[0]?.url;
    const { intent, titleLocKey } = orderStateBadgeMap[orderState ?? OrderStateEnum.Open];

    if (!project) return null;

    return (
        <div className="mx-auto mb-10 grid w-full max-w-md px-4">
            <Heading level={1} className="my-8 text-center">
                {title}
            </Heading>
            <Link href={Routes.projectDetail.fillPathParams({ projectSlug: projectInfo?.slug ?? "" })} newTab={openInNewTab}>
                <div className="mb-10 flex h-20 w-full items-center overflow-hidden rounded-lg bg-gray-100">
                    <img src={thumbnailUrl} alt={projectInfo?.title} className="size-20 object-cover" />
                    <Heading level={4} className="ml-6">
                        {projectInfo?.title}
                    </Heading>
                    {orderState && (
                        <div className="px-4">
                            <Badge intent={intent}>{t(titleLocKey)}</Badge>
                        </div>
                    )}
                </div>
            </Link>
            {children}
        </div>
    );
};
