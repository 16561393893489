import { Range, Root, Thumb, Track } from "@radix-ui/react-slider";
import type { FC } from "react";

type SliderProps = Readonly<{
    defaultValue?: number;
    value: number;
    max: number;
    min?: number;
    step: number;
    onChange: (newValue: number) => void;
}>;

export const Slider: FC<SliderProps> = ({ defaultValue, value, max, min = 0, step, onChange }) => (
    <form className="w-full">
        <Root
            className="relative flex h-5 w-full touch-none select-none items-center"
            defaultValue={defaultValue ? [defaultValue] : undefined}
            value={[value]}
            max={max}
            min={min}
            step={step}
            onValueChange={(updatedValues) => onChange(updatedValues[0])}
        >
            <Track className="relative h-2 grow rounded-lg bg-tertiary">
                <Range className="absolute left-0 top-0 h-2 rounded-lg bg-brand" />
            </Track>
            <Thumb className="block size-6 rounded-full bg-brand-secondary" aria-label="Volume" />
        </Root>
    </form>
);
