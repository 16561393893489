import { Indicator, Item } from "@radix-ui/react-radio-group";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { CircleIcon } from "src/icons";
import { cn } from "src/utils";

export const RadioGroupItem = forwardRef<ElementRef<typeof Item>, ComponentPropsWithoutRef<typeof Item>>(
    ({ className, children, ...props }, ref) => (
        <label className="mb-0 flex items-center gap-2">
            <Item
                ref={ref}
                className={cn(
                    "aspect-square size-5 rounded-full border-2 border-subtle disabled:cursor-not-allowed disabled:bg-subtle data-[state=checked]:border-brand data-[state=checked]:bg-brand data-[state=checked]:fill-white data-[state=checked]:text-white disabled:data-[state=checked]:border-subtle disabled:data-[state=checked]:bg-subtle disabled:data-[state=checked]:fill-tertiary disabled:data-[state=checked]:text-tertiary",
                    className,
                )}
                {...props}
            >
                <Indicator className="flex items-center justify-center">
                    <CircleIcon className="size-2 fill-current text-current" />
                </Indicator>
            </Item>
            <span>{children}</span>
        </label>
    ),
);

RadioGroupItem.displayName = Item.displayName;
