import { isValid, parseISO } from "date-fns";
import i18n from "i18next";

export const getLocalizedRegionNameFromCountryCode = (countryCode: string) => {
    return new Intl.DisplayNames([i18n.language], { type: "region" }).of(countryCode);
};

export const validateDateString = (dateString: string) => {
    const date = parseISO(dateString);
    return isValid(date);
};

export const isDateInFuture = (dateString: string) => {
    const date = parseISO(dateString);
    return date > new Date();
};
