import type { MessageListResponse } from "src/types";
import { HttpMethod } from "./constants";
import { rootApi } from "./rootApi";
import { ENTITY_WITH_TRANSLATIONS_TAG, MESSAGE_TAG } from "./tags";
import { getCmsUrl } from "./utils";

export const messageApi = rootApi.injectEndpoints({
    endpoints: (build) => ({
        messages: build.query<MessageListResponse, void>({
            query: () => ({
                url: getCmsUrl(`/api/messages`),
                method: HttpMethod.GET,
                params: {
                    status: "published",
                    sort: "relevance",
                },
            }),
            providesTags: [MESSAGE_TAG, ENTITY_WITH_TRANSLATIONS_TAG],
        }),
    }),
});

export const { useMessagesQuery } = messageApi;
