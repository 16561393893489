import { zodResolver } from "@hookform/resolvers/zod";
import type { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import {
    Button,
    ButtonWithIcon,
    CmsError,
    ControlledFormCheckbox,
    DownloadLink,
    Heading,
    Input,
    Link,
    SecretInput,
    Separator,
} from "src/components";
import { getAppConfig } from "src/config";
import { GoogleIcon } from "src/icons";
import { Routes } from "src/routes";
import { getCmsUrl, isCmsErrorResponseWithMessage, removeAuthToken, useRegistrationMutation } from "src/serverApi";
import { Trans, useTranslation } from "src/translations";
import { logGtmEvent, useAppSelector } from "src/utils";
import { logger } from "./logger";
import { SignUpSuccess } from "./SignUpSuccess";

type FormFields = {
    email: string;
    password: string;
    terms: boolean;
    privacy: boolean;
};

export const SignUpForm: FC = () => {
    const { t } = useTranslation();

    const [registration, { isLoading, isSuccess, isError, error }] = useRegistrationMutation();
    const appConfig = useAppSelector(getAppConfig);

    const termsAndConditionsLink = appConfig?.termsAndConditions?.url;
    const termsAndConditionsText = appConfig?.termsAndConditions?.caption;
    const privacyPolicyLink = appConfig?.privacyPolicy?.url;
    const privacyPolicyText = appConfig?.privacyPolicy?.caption;

    const SignUpSchema = z.object({
        email: z.string().min(1, t("Required.email")).email(t("RegistrationForm.field.error.email")),
        password: z
            .string()
            .min(8, t("RegistrationForm.field.error.smallPassword"))
            .max(100, t("RegistrationForm.field.error.bigPassword"))
            .refine((value) => {
                const regexChecks = [/[a-z]/, /[A-Z]/, /\d/, /[^\w\d\s]/];
                const matchCount = regexChecks.reduce((count, regex) => (regex.test(value) ? count + 1 : count), 0);
                return matchCount >= 3;
            }, t("RegistrationForm.field.error.weakPassword")),
        terms: z.boolean(),
        privacy: z.boolean(),
    });

    const {
        handleSubmit,
        register,
        getFieldState,
        getValues,
        control,
        formState: { errors },
    } = useForm<FormFields>({
        resolver: zodResolver(SignUpSchema),
    });

    const onSubmit = async (data: FormFields) => {
        try {
            removeAuthToken();
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { privacy, terms, ...registrationData } = data;
            await registration({ username: registrationData.email, ...registrationData }).unwrap();
            logGtmEvent({ event: "user_registered", mail: data.email });
        } catch (err) {
            if (isCmsErrorResponseWithMessage(err)) {
                logger.error(err.data.error.message, err);
            } else {
                logger.error("Unknown error", err);
            }
        }
    };

    const handleGoogleLogin = async () => {
        try {
            logGtmEvent({ event: "user_google_login_triggered" });
            window.location.href = getCmsUrl("/api/connect/google");
        } catch (err) {
            logger.error("Google login error", err);
        }
    };

    if (isSuccess) {
        return <SignUpSuccess email={getValues("email")} />;
    }

    return (
        <div className="w-full space-y-6">
            <Heading level={2} className="text-center">
                {t("RegistrationForm.card.form.header")}
            </Heading>
            <Heading level={6} className="mb-8 text-center">
                {t("RegistrationForm.card.form.subheader")}
            </Heading>
            {isError ? <CmsError error={error} fallbackMessage={t("Form.error")} /> : null}
            <ButtonWithIcon onClick={handleGoogleLogin} isLoading={isLoading} icon={<GoogleIcon />} className="bg-black hover:bg-black">
                {t("Login.GoogleLoginButton")}
            </ButtonWithIcon>
            <form id="Registration" onSubmit={handleSubmit(onSubmit)} className="w-full space-y-[40px]">
                <div className="space-y-6">
                    <Separator />
                    <Input
                        label={t("RegistrationForm.label.email")}
                        max="70"
                        {...register("email")}
                        error={errors.email?.message}
                        fullWidth
                    />
                    <SecretInput
                        label={t("RegistrationForm.label.password")}
                        {...register("password")}
                        fullWidth
                        error={errors.password?.message}
                    />
                    <div className="flex flex-col gap-3">
                        {termsAndConditionsLink && (
                            <Controller
                                name="terms"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                        <label className="flex items-center gap-2">
                                            <ControlledFormCheckbox checked={field.value} onCheckedChange={field.onChange} />
                                            <span>
                                                <Trans
                                                    i18nKey="Registration.AgreeText"
                                                    components={{
                                                        downloadLink: <DownloadLink className="text-base" href={termsAndConditionsLink} />,
                                                    }}
                                                    values={{ linkText: termsAndConditionsText }}
                                                />
                                            </span>
                                        </label>
                                        {errors.terms && <span className="text-sm text-error">{t("Registration.AgreeTextError")}</span>}
                                    </>
                                )}
                            />
                        )}
                        {privacyPolicyLink && (
                            <Controller
                                name="privacy"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                        <label className="flex items-center gap-2">
                                            <ControlledFormCheckbox checked={field.value} onCheckedChange={field.onChange} />
                                            <span>
                                                <Trans
                                                    i18nKey="Registration.AgreeText"
                                                    components={{
                                                        downloadLink: <DownloadLink className="text-base" href={privacyPolicyLink} />,
                                                    }}
                                                    values={{ linkText: privacyPolicyText }}
                                                />
                                            </span>
                                        </label>
                                        {errors.privacy && <span className="text-sm text-error">{t("Registration.AgreeTextError")}</span>}
                                    </>
                                )}
                            />
                        )}
                    </div>
                </div>

                <div className="flex flex-col gap-5">
                    <Button
                        disabled={getFieldState("email").invalid || getFieldState("password").invalid}
                        isLoading={isLoading}
                        type="submit"
                        fullWidth
                    >
                        {t("RegistrationForm.button.submit")}
                    </Button>
                    <div className="flex flex-col items-center justify-center gap-2 md:flex-row">
                        <span className="text-sm">{t("Login.Label")}</span>
                        <Link href={Routes.signIn.path}>{t("Login.Link")}</Link>
                    </div>
                </div>
            </form>
        </div>
    );
};
