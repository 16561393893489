import { type FC, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { CmsError, Loading } from "src/components";
import { Routes } from "src/routes";
import { setAuthToken, usePublicGoogleJwtQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { logGtmEvent } from "src/utils";
import { useUpdateProfileUser } from "../../utils";

export const GoogleCallbackPage: FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { next, ...authParams } = Object.fromEntries(searchParams.entries()) ?? {};

    const { t } = useTranslation();

    const { data: googleJwt, isLoading, isError, error } = usePublicGoogleJwtQuery(authParams);

    const updateUserProfile = useUpdateProfileUser();

    useEffect(() => {
        if (!isLoading && googleJwt) {
            logGtmEvent({ event: "user_google_logged_in" });
            const { jwt } = googleJwt;
            if (jwt) {
                setAuthToken(jwt);
                updateUserProfile().then(() => {
                    if (next) {
                        navigate(next);
                    } else {
                        navigate(Routes.projects.path);
                    }
                });
            }
        }
    }, [isLoading, googleJwt, updateUserProfile, searchParams, navigate, next]);

    return isError ? <CmsError error={error} fallbackMessage={t("Form.error")} /> : <Loading isFullPage />;
};
