import { type FC, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { Accordion, AccordionItem, Heading, RichText } from "src/components";
import { ArrowDownSlimIcon } from "src/icons";
import type { ProjectDescriptionWithTitleComponent } from "src/types";
import { getValOrThrowError, isNotNullish } from "src/utils";
import { Faq } from "./Faq";

type ProjectDetailDescriptionProps = Readonly<{
    loading: boolean;
    content: ProjectDescriptionWithTitleComponent[];
}>;

export const ProjectDetailDescription: FC<ProjectDetailDescriptionProps> = ({ loading, content }) => {
    const [expandedSections, setExpandedSections] = useState<string[]>();

    const firstContentId = content?.[0]?.id?.toString();

    useEffect(() => {
        if (firstContentId) {
            setExpandedSections([firstContentId]);
        }
    }, [firstContentId]);

    return (
        <div className="flex flex-col gap-12">
            {loading ? (
                Array.from({ length: 4 }).map((_, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={i} className="flex flex-col gap-y-6">
                        <div className="grid w-full grid-cols-[1fr_auto] border-b pb-2">
                            <Skeleton width="40%" height={40} />
                            <Skeleton width={30} height={30} />
                        </div>
                        {i === 0 && <Skeleton count={10} height={20} />}
                    </div>
                ))
            ) : (
                <Accordion className="flex flex-col gap-12" value={expandedSections} onValueChange={setExpandedSections}>
                    {content?.filter(isNotNullish).map(({ id, title, description }) => (
                        <AccordionItem
                            key={id}
                            value={getValOrThrowError(id).toString()}
                            title={
                                <Heading level={2} className="text-left text-2xl font-medium text-brand xl:text-3xl">
                                    {title}
                                </Heading>
                            }
                            content={description && <article className="text-primary">{<RichText content={description} />}</article>}
                            triggerClassName="[&>div]:data-[state=open]:rotate-180 border-b p-y-4"
                            triggerIcon={
                                <div className="text-brand transition-transform duration-500">
                                    <ArrowDownSlimIcon className="size-12" />
                                </div>
                            }
                        />
                    ))}
                </Accordion>
            )}
            <Faq />
        </div>
    );
};
