import type { FC } from "react";

import { NoContent, Table, TableBody, TableHead, TableHeader, TableRow } from "src/components";
import { useTranslation } from "src/translations";
import type { OrderListResponse } from "src/types";
import { OrderItem } from "./OrderItem";

type OrdersTableProps = Readonly<{
    orders: OrderListResponse;
}>;

export const OrdersTable: FC<OrdersTableProps> = ({ orders }) => {
    const { t } = useTranslation();

    if (!orders?.data?.length) {
        return <NoContent transKey="Orders.NoOrders" />;
    }

    // TODO: tablet/breakpoint view

    return (
        <Table className="bg-white">
            <TableHeader className="border-b border-primary">
                <TableRow className="text-sm text-secondary [&>th]:px-3 [&>th]:pb-3">
                    <TableHead className="pl-0">{t("OrderListOrder")}</TableHead>
                    <TableHead>{t("OrderListProject")}</TableHead>
                    <TableHead className="text-center">{t("OrderListDate")}</TableHead>
                    <TableHead className="text-center">{t("OrderListUnits")}</TableHead>
                    <TableHead className="text-right">{t("OrderListCost")}</TableHead>
                    <TableHead>{t("OrderListState")}</TableHead>
                    <TableHead className="pr-0" />
                </TableRow>
            </TableHeader>
            <TableBody>{orders?.data.map((order) => <OrderItem key={order?.id} order={order} />)}</TableBody>
        </Table>
    );
};
