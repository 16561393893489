import { Routes } from "src/routes";
import { typeCheckAppModule } from "src/utils";
import { NAME } from "./constants";
import { ForgotPasswordPage } from "./ForgotPasswordPage";

export const forgotPassword = typeCheckAppModule({
    NAME,
    Container: ForgotPasswordPage,
    route: Routes.forgotPassword,
    secure: false,
});
