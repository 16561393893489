import { type FC, useState } from "react";

import { CmsError, CurrencySelector, NoContent, PageLayout } from "src/components";
import { useUserProjectPortfolioQuery, useUserQuery } from "src/serverApi";
import { getCurrencySymbol, useTranslation } from "src/translations";
import { BannerPageEnum, Currency } from "src/types";
import { DashboardNetWorth, DashboardProjects, DashboardStats } from "./components";

export const DashboardPage: FC = () => {
    const { t } = useTranslation();

    const { data: user } = useUserQuery();
    const [currency, setCurrency] = useState(user?.userProfile?.currency ?? Currency.EUR);
    const { data: portfolio, isLoading, isError, error } = useUserProjectPortfolioQuery(currency);

    const isEmpty = !portfolio || portfolio.projects.length === 0;
    const currencySymbol = getCurrencySymbol(currency);

    return (
        <PageLayout
            title={t("DashboardPage.Title")}
            loading={isLoading}
            banner={BannerPageEnum.Dashboard}
            rightCornerComponent={
                isEmpty ? undefined : (
                    <CurrencySelector value={currency} onChange={(value) => setCurrency(value as Currency)} contentPosition="popper" />
                )
            }
        >
            {isError ? <CmsError error={error} fallbackMessage={t("Form.error")} /> : null}
            {isEmpty ? (
                <NoContent transKey="DashboardPage.NoData" />
            ) : (
                <div className="flex flex-col gap-12 max-sm:gap-6">
                    <DashboardNetWorth netWorth={portfolio.netWorth} currencySymbol={currencySymbol} />
                    <DashboardStats
                        invested={portfolio.investedAmount}
                        currencySymbol={currencySymbol}
                        noProjects={portfolio.projectCount}
                        noCountries={portfolio.countriesCount}
                    />
                    <DashboardProjects projects={portfolio.projects} currencySymbol={currencySymbol} />
                </div>
            )}
        </PageLayout>
    );
};
