import type { LogLevel } from "./types";

export const LogLevels = {
    Trace: {
        value: 0,
        label: "Trace",
    },
    Debug: {
        value: 1,
        label: "Debug",
    },
    Info: {
        value: 2,
        label: "Info",
    },
    Warning: {
        value: 3,
        label: "Warning",
    },
    Error: {
        value: 4,
        label: "Error",
    },
    Silent: {
        value: 5,
        label: "Silent",
    },
} as const satisfies Record<string, LogLevel>;
