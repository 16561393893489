// type UseImageSrcSetProps = Pick<UploadFile, "formats" | "url" | "width">;

import { hasPropOfType, isNumber, isObject, isString } from "src/utils";

export const getImageSrcSet = (formats: unknown, url?: string, width?: number): string => {
    const sizes = [
        // Add original size
        `${url} ${width}w`,
    ];

    if (isImageFormats(formats)) {
        for (const f in formats) {
            if (f !== "thumbnail") {
                const v = formats[f];
                if (v) {
                    sizes.push(`${v.url} ${v.width}w`);
                }
            }
        }
    }

    return sizes.join(", ");
};

type ImageFormat = {
    url: string;
    width: number;
};

const isImageFormat = (data: unknown): data is ImageFormat =>
    isObject(data) && hasPropOfType(data, "url", isString) && hasPropOfType(data, "width", isNumber);

const isImageFormats = (data: unknown): data is Record<string, ImageFormat | undefined> =>
    isObject(data) && Object.values(data).every(isImageFormat);
