import { Indicator, Root } from "@radix-ui/react-progress";
import type { FC } from "react";

type ProgressProps = Readonly<{
    progress: number;
}>;

export const Progress: FC<ProgressProps> = ({ progress }) => (
    <Root className="h-2.5 overflow-hidden rounded-lg bg-tertiary" value={progress}>
        <Indicator className="h-full rounded-lg bg-brand" style={{ transform: `translateX(-${100 - progress}%)` }} />
    </Root>
);
