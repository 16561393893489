import { zodResolver } from "@hookform/resolvers/zod";
import type { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

import { Button, Heading, PopupWindow, SecretInput } from "src/components";
import { Routes } from "src/routes";
import { isCmsErrorResponseWithMessage, removeAuthToken, useResetPasswordMutation } from "src/serverApi";
import { useTranslation } from "src/translations";
import { isNullish } from "src/utils";
import { logger } from "./logger";

type FormFields = {
    password: string;
};

export const NewPasswordForm: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [resetPassword, { isLoading, isError }] = useResetPasswordMutation();
    const [searchParams] = useSearchParams();

    const NewPasswordSchema = z.object({
        password: z
            .string()
            .min(8, t("RegistrationForm.field.error.smallPassword"))
            .max(100, t("RegistrationForm.field.error.bigPassword"))
            .refine((value) => {
                const regexChecks = [/[a-z]/, /[A-Z]/, /\d/, /[^\w\d\s]/];
                const matchCount = regexChecks.reduce((count, regex) => (regex.test(value) ? count + 1 : count), 0);
                return matchCount >= 3;
            }, t("RegistrationForm.field.error.weakPassword")),
    });

    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
    } = useForm<FormFields>({
        resolver: zodResolver(NewPasswordSchema),
    });

    const onSubmit = async (formData: FormFields) => {
        const code = searchParams.get("code");
        if (isNullish(code)) {
            logger.error("No code provided when resetting password");
            return;
        }
        try {
            removeAuthToken();
            await resetPassword({ password: formData.password, passwordConfirmation: formData.password, code }).unwrap();
            await PopupWindow.fire({
                title: t("SetNewPassword.popUp.success"),
                icon: "success",
                confirmButtonText: t("SetNewPassword.popUp.login"),
            });
            navigate(Routes.signIn.path);
        } catch (err) {
            if (isCmsErrorResponseWithMessage(err)) {
                logger.error(err.data.error.message, err);
            } else {
                logger.error("Unknown error", err);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6">
            <Heading level={2} className="mb-8">
                {t("SetNewPassword.card.header")}{" "}
            </Heading>
            <div className="space-y-6">
                <SecretInput
                    label={t("SetNewPassword.placeholder.password")}
                    {...register("password", { required: true })}
                    error={errors.password?.message}
                />
            </div>
            <div>
                {isError ? <span className="text-error">{t("Form.error")}</span> : null}
                <Button type="submit" fullWidth isLoading={isLoading} disabled={!isValid}>
                    {t("SetNewPassword.button.submit")}
                </Button>
            </div>
        </form>
    );
};
