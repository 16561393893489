import { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Routes } from "src/routes";
import { UserVerificationTypeEnum } from "src/types";
import { useUserVerification } from "src/verification";

export const useVerificationRedirect = () => {
    const { verificationType } = Routes.verifications.useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { isVerificationDone, isVerificationLoading } = useUserVerification();

    const isSuccessPage = searchParams.get("success");
    const projectSlug = searchParams.get("slug");

    const hasKyc = isVerificationDone(UserVerificationTypeEnum.KYC);
    const hasAml = isVerificationDone(UserVerificationTypeEnum.AML);

    const redirect = useCallback(() => {
        if (!hasKyc) {
            if (verificationType !== UserVerificationTypeEnum.KYC) {
                navigate(Routes.verifications.fillPathParams({ verificationType: UserVerificationTypeEnum.KYC }));
            }
            return;
        }
        if (!hasAml) {
            if (verificationType !== UserVerificationTypeEnum.AML) {
                navigate(Routes.verifications.fillPathParams({ verificationType: UserVerificationTypeEnum.AML }));
            }
            return;
        }
        if (hasKyc && hasAml) {
            if (projectSlug) {
                navigate(Routes.projectOrder.fillPathParams({ projectSlug }));
            } else {
                navigate(Routes.projects.path);
            }
        }
    }, [hasAml, hasKyc, navigate, projectSlug, verificationType]);

    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout>;
        if (!isVerificationLoading) {
            const timeout = isSuccessPage ? 3000 : 0;
            if (timeout) {
                timeoutId = setTimeout(() => {
                    redirect();
                }, timeout);
            } else {
                redirect();
            }
        }

        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timeoutId);
    }, [isVerificationLoading, isSuccessPage, redirect]);
};
