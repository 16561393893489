import type { FC } from "react";

import { cn } from "src/utils";

type PayErrorProps = Readonly<{
    error: string;
    className?: string;
}>;

export const PayError: FC<PayErrorProps> = ({ error, className }) => (
    <div className={cn("bg-brand-error/20 p-2.5 text-center text-error", className)}>{error}</div>
);
