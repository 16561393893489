import { Routes } from "src/routes";
import { typeCheckAppModule } from "src/utils";
import { NAME } from "./constants";
import { OrderDetailPage } from "./OrderDetailPage";

export const orderDetail = typeCheckAppModule({
    NAME,
    Container: OrderDetailPage,
    route: Routes.orderDetail,
});
