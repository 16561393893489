import type { FC, ReactNode } from "react";

type ProjectDetailLayoutProps = Readonly<{
    gallery: ReactNode;
    timeline: ReactNode;
    description: ReactNode;
    stats: ReactNode;
    sidebar: ReactNode;
}>;

export const ProjectDetailLayout: FC<ProjectDetailLayoutProps> = ({ timeline, gallery, stats, description, sidebar }) => (
    <div className="isolate grid grid-cols-[minmax(0,_1fr)] gap-y-8 grid-areas-projectDetailMobile lg:grid-cols-[minmax(320px,_3fr)_1fr_minmax(420px,_3fr)] lg:grid-rows-[min-content_1fr] lg:gap-x-16 lg:grid-areas-projectDetailDesktop">
        <div className="grid-in-gallery">{gallery}</div>
        <div className="grid-in-timeline">{timeline}</div>
        <div className="grid-in-stats">{stats}</div>
        <div className="grid-in-description">{description}</div>
        <aside className="grid-in-sidebar">{sidebar}</aside>
    </div>
);
