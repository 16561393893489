import { type FC, useMemo } from "react";

import { cn } from "src/utils";

type VotingCardQuestionStatBarProps = {
    totalVoteWeight?: number;
    voteWeightForOption?: number;
    className: string;
};

export const VotingCardQuestionStatBar: FC<VotingCardQuestionStatBarProps> = ({ voteWeightForOption, totalVoteWeight, className }) => {
    const resultsInPercentage = useMemo(() => {
        if (totalVoteWeight === 0 || !voteWeightForOption || !totalVoteWeight) return 0;
        return Math.round((voteWeightForOption / totalVoteWeight) * 100);
    }, [voteWeightForOption, totalVoteWeight]);

    return (
        <div className={cn("flex items-center gap-x-6", className)}>
            <div className="h-2 w-full overflow-x-hidden rounded-lg">
                <div style={{ width: `${resultsInPercentage}%` }} className="h-2 rounded-l-lg bg-brand-primary" />
            </div>
            <p className="w-9 whitespace-nowrap text-right text-sm">{resultsInPercentage}%</p>
        </div>
    );
};
