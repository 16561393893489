import type { MessageHandler } from "@sumsub/websdk";
import SumsubWidget from "@sumsub/websdk-react";
import { type FC, useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Checking, Heading, Loading } from "src/components";
import { Routes } from "src/routes";
import { useKycTokenQuery, useUserQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { UserVerificationTypeEnum } from "src/types";
import { addParamsToCurrentUrl, logGtmEvent, useComponentDidMount } from "src/utils";

export const Kyc: FC = () => {
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const checking = searchParams.get("checking");

    const { data: user, isLoading: isUserLoading } = useUserQuery(undefined, { pollingInterval: checking ? 5000 : undefined });
    const { data: kycData, isLoading: isKycDataLoading } = useKycTokenQuery();

    const hasKyc = user?.verification.some((verification) => verification.type === UserVerificationTypeEnum.KYC);

    useComponentDidMount(() => {
        logGtmEvent({ event: "user_kyc_entered" });
    });

    useEffect(() => {
        if (hasKyc) {
            logGtmEvent({ event: "user_kyc_verified" }, false);
        }
    }, [hasKyc]);

    const sumsubConfig = useMemo(
        () => ({
            lang: i18n.language,
            email: user?.email,
            theme: "light",
        }),
        [i18n.language, user?.email],
    );

    const messageHandler: MessageHandler = async (type, payload) => {
        if (type === "idCheck.onStepInitiated") {
            // @ts-ignore
            const step = payload?.idDocSetType;
            const stepFormatted = step?.toLowerCase();
            logGtmEvent({ event: `user_kyc_step_initiated_${stepFormatted}`, dynamic: true });
        }
        if (type === "idCheck.onApplicantStatusChanged") {
            // This looks like a custom response from Sumsub
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const payloadData = payload as any;
            if (payloadData.reviewStatus === "completed" && payloadData.reviewResult?.reviewAnswer === "GREEN") {
                navigate(addParamsToCurrentUrl({ checking: true }, false));
            }
        }
    };

    if (isUserLoading) {
        return <Loading />;
    }

    if (isKycDataLoading) {
        return null;
    }

    return (
        <div className="grid size-full items-center justify-center">
            <div className="mt-12 grid size-full items-center justify-center gap-8">
                {hasKyc ? (
                    <>
                        <Heading level={2} className="mb-4 text-center">
                            {t("KYC.completed")}
                        </Heading>
                        <Heading level={5} className="text-center">
                            {t("KYC.redirect")}
                        </Heading>
                    </>
                ) : checking ? (
                    <>
                        <Heading level={2} className="mb-4 text-center">
                            {t("KYC.title")}
                        </Heading>
                        <Checking
                            title={t("KYC.checkingStatus")}
                            linkToStopChecking={Routes.verifications.fillPathParams({
                                verificationType: UserVerificationTypeEnum.KYC,
                            })}
                        />
                    </>
                ) : (
                    kycData?.token && (
                        <div className="md:w-[600px]">
                            <SumsubWidget
                                accessToken={kycData.token}
                                config={sumsubConfig}
                                expirationHandler={() => Promise.resolve(kycData.token!)}
                                options={{ adaptIframeHeight: true }}
                                onMessage={messageHandler}
                            />
                        </div>
                    )
                )}
            </div>
        </div>
    );
};
