import type { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { AccordionItem, ControlledFormCheckbox, Heading, Input, RadioGroup, RadioGroupItem } from "src/components";
import { CloseIcon } from "src/icons";
import { getAmlFormScheme } from "./AmlFormScheme";
import { ExpectedTradesVolume, QuestionnaireSection } from "./enums";

export const AdditionalInfo: FC = () => {
    const { t } = useTranslation();

    const schema = getAmlFormScheme(t);
    type FormData = z.infer<typeof schema>;

    const {
        register,
        control,
        watch,
        formState: { errors },
    } = useFormContext<FormData>();

    const isPoliticallyExposed = watch("politicallyExposed.isChecked") === "yes";

    return (
        <AccordionItem
            value={QuestionnaireSection.AdditionalInfo}
            className="my-2 overflow-hidden rounded border bg-white p-6"
            title={<Heading level={3}>{t("AMLQuestionnaire.Section2Heading")}</Heading>}
            triggerIcon={<CloseIcon className="size-8 transition-transform duration-300" />}
            content={
                <div className="flex flex-col gap-y-8 py-8">
                    <Input
                        type="text"
                        {...register("purposeOfContract")}
                        label={t("AMLQuestionnaire.Section2.Inputs.PurposeOfContract.Label")}
                        error={errors.purposeOfContract}
                        placeholder={t("AMLQuestionnaire.Section2.Inputs.PurposeOfContract.Placeholder")}
                        className="placeholder:text-sm"
                    />
                    <Controller
                        name="expectedTradesVolume"
                        control={control}
                        render={({ field }) => (
                            <RadioGroup
                                {...field}
                                ref={field.ref}
                                onValueChange={(value) => field.onChange(value)}
                                label={t("AMLQuestionnaire.Section2.Inputs.ExpectedTradesVolume.Label")}
                                error={errors.expectedTradesVolume}
                                orientation="vertical"
                                className="gap-3 text-sm"
                            >
                                <RadioGroupItem value={ExpectedTradesVolume.LessThan2k}>
                                    {t("AMLQuestionnaire.Section2.Inputs.ExpectedTradesVolume.LessThan2k")}
                                </RadioGroupItem>
                                <RadioGroupItem value={ExpectedTradesVolume.Between2kAnd10k}>
                                    {t("AMLQuestionnaire.Section2.Inputs.ExpectedTradesVolume.Between2kAnd10k")}
                                </RadioGroupItem>
                                <RadioGroupItem value={ExpectedTradesVolume.Between10kAnd50k}>
                                    {t("AMLQuestionnaire.Section2.Inputs.ExpectedTradesVolume.Between10kAnd50k")}
                                </RadioGroupItem>
                                <RadioGroupItem value={ExpectedTradesVolume.Between50kAnd200k}>
                                    {t("AMLQuestionnaire.Section2.Inputs.ExpectedTradesVolume.Between50kAnd200k")}
                                </RadioGroupItem>
                                <RadioGroupItem value={ExpectedTradesVolume.MoreThan200k}>
                                    {t("AMLQuestionnaire.Section2.Inputs.ExpectedTradesVolume.MoreThan200k")}
                                </RadioGroupItem>
                            </RadioGroup>
                        )}
                    />

                    <fieldset className="flex flex-col gap-y-3 p-0 text-sm">
                        <p>{t("AMLQuestionnaire.Section2.Inputs.OriginOfFunds.Label")}</p>
                        <Controller
                            name="originOfFunds.alimonyBenefitsPension"
                            control={control}
                            render={({ field }) => (
                                <label className="flex items-center gap-2">
                                    <ControlledFormCheckbox checked={field.value} onCheckedChange={field.onChange} />
                                    <span className="peer-disabled:cursor-not-allowed">
                                        {t("AMLQuestionnaire.Section2.Inputs.OriginOfFunds.Option.Alimony")}
                                    </span>
                                </label>
                            )}
                        />
                        <Controller
                            name="originOfFunds.salary"
                            control={control}
                            render={({ field }) => (
                                <label className="flex items-center gap-2">
                                    <ControlledFormCheckbox checked={field.value} onCheckedChange={field.onChange} />
                                    <span className="peer-disabled:cursor-not-allowed">
                                        {t("AMLQuestionnaire.Section2.Inputs.OriginOfFunds.Option.Salary")}
                                    </span>
                                </label>
                            )}
                        />
                        <Controller
                            name="originOfFunds.business"
                            control={control}
                            render={({ field }) => (
                                <label className="flex items-center gap-2">
                                    <ControlledFormCheckbox checked={field.value} onCheckedChange={field.onChange} />
                                    <span className="peer-disabled:cursor-not-allowed">
                                        {t("AMLQuestionnaire.Section2.Inputs.OriginOfFunds.Option.Business")}
                                    </span>
                                </label>
                            )}
                        />
                        <Controller
                            name="originOfFunds.capital"
                            control={control}
                            render={({ field }) => (
                                <label className="flex items-center gap-2">
                                    <ControlledFormCheckbox checked={field.value} onCheckedChange={field.onChange} />
                                    <span className="peer-disabled:cursor-not-allowed">
                                        {t("AMLQuestionnaire.Section2.Inputs.OriginOfFunds.Option.Capital")}
                                    </span>
                                </label>
                            )}
                        />
                        <Controller
                            name="originOfFunds.otherChecked"
                            control={control}
                            render={({ field }) => (
                                <div className="flex items-center gap-2">
                                    <ControlledFormCheckbox checked={field.value} onCheckedChange={field.onChange} />
                                    <Input
                                        type="text"
                                        {...register("originOfFunds.otherValue")}
                                        error={errors.originOfFunds?.otherValue}
                                        placeholder={t("Other")}
                                        disabled={!watch("originOfFunds.otherChecked")}
                                        className="mt-0 h-[33px] max-w-[300px] text-sm placeholder:text-sm"
                                    />
                                </div>
                            )}
                        />
                        {errors.originOfFunds?.root && <span className="text-sm text-error">{errors.originOfFunds.root.message}</span>}
                    </fieldset>

                    <hr />
                    <Controller
                        name="noCriminalActivity"
                        control={control}
                        render={({ field }) => (
                            <RadioGroup
                                {...field}
                                onValueChange={(value) => field.onChange(value)}
                                label={t("AMLQuestionnaire.Section2.Inputs.CriminalActivity.Label")}
                                error={errors.noCriminalActivity}
                                className="gap-3 text-sm"
                            >
                                <RadioGroupItem value="yes">{t("Yes")}</RadioGroupItem>
                                <RadioGroupItem value="no">{t("No")}</RadioGroupItem>
                            </RadioGroup>
                        )}
                    />
                    <Controller
                        name="countriesWithSanctions"
                        control={control}
                        render={({ field }) => (
                            <RadioGroup
                                {...field}
                                onValueChange={(value) => field.onChange(value)}
                                label={t("AMLQuestionnaire.Section2.Inputs.CountriesWithSanctions.Label")}
                                error={errors.countriesWithSanctions}
                                className="gap-3 text-sm"
                            >
                                <RadioGroupItem value="yes">{t("Yes")}</RadioGroupItem>
                                <RadioGroupItem value="no">{t("No")}</RadioGroupItem>
                            </RadioGroup>
                        )}
                    />
                    <Controller
                        name="politicallyExposed.isChecked"
                        control={control}
                        render={({ field }) => (
                            <RadioGroup
                                {...field}
                                onValueChange={(value) => field.onChange(value)}
                                label={t("AMLQuestionnaire.Section2.Inputs.PoliticallyExposed.Label")}
                                error={errors.politicallyExposed?.isChecked}
                                additionalInfo={t("AMLQuestionnaire.Section2.Inputs.PoliticallyExposed.AdditionalInfo")}
                                className="gap-3 text-sm"
                            >
                                <RadioGroupItem value="yes">{t("Yes")}</RadioGroupItem>
                                <RadioGroupItem value="no">{t("No")}</RadioGroupItem>
                            </RadioGroup>
                        )}
                    />
                    {isPoliticallyExposed && (
                        <fieldset className="flex flex-col gap-y-3 p-0 text-sm">
                            <p>{t("AMLQuestionnaire.Section2.Inputs.PEOriginOfFunds.Label")}</p>
                            <Controller
                                name="politicallyExposed.sourceOfWealthSalary"
                                control={control}
                                render={({ field }) => (
                                    <label className="flex items-center gap-2">
                                        <ControlledFormCheckbox checked={field.value} onCheckedChange={field.onChange} />
                                        <span className="peer-disabled:cursor-not-allowed">
                                            {t("AMLQuestionnaire.Section2.Inputs.PEOriginOfFunds.Option.Salary")}
                                        </span>
                                    </label>
                                )}
                            />
                            <Controller
                                name="politicallyExposed.sourceOfWealthCapital"
                                control={control}
                                render={({ field }) => (
                                    <label className="flex items-center gap-2">
                                        <ControlledFormCheckbox checked={field.value} onCheckedChange={field.onChange} />
                                        <span className="peer-disabled:cursor-not-allowed">
                                            {t("AMLQuestionnaire.Section2.Inputs.PEOriginOfFunds.Option.Capital")}
                                        </span>
                                    </label>
                                )}
                            />
                            <Controller
                                name="politicallyExposed.sourceOfWealthBusiness"
                                control={control}
                                render={({ field }) => (
                                    <label className="flex items-center gap-2">
                                        <ControlledFormCheckbox checked={field.value} onCheckedChange={field.onChange} />
                                        <span className="peer-disabled:cursor-not-allowed">
                                            {t("AMLQuestionnaire.Section2.Inputs.PEOriginOfFunds.Option.Business")}
                                        </span>
                                    </label>
                                )}
                            />
                            <Controller
                                name="politicallyExposed.sourceOfWealthInheritance"
                                control={control}
                                render={({ field }) => (
                                    <label className="flex items-center gap-2">
                                        <ControlledFormCheckbox checked={field.value} onCheckedChange={field.onChange} />
                                        <span className="peer-disabled:cursor-not-allowed">
                                            {t("AMLQuestionnaire.Section2.Inputs.PEOriginOfFunds.Option.Inheritance")}
                                        </span>
                                    </label>
                                )}
                            />
                            <Controller
                                name="politicallyExposed.sourceOfWealthOtherChecked"
                                control={control}
                                render={({ field }) => (
                                    <div className="flex items-center gap-2">
                                        <ControlledFormCheckbox checked={field.value} onCheckedChange={field.onChange} />
                                        <Input
                                            type="text"
                                            {...register("politicallyExposed.sourceOfWealthOtherValue")}
                                            error={errors.politicallyExposed?.sourceOfWealthOtherValue}
                                            placeholder={t("Other")}
                                            disabled={!watch("politicallyExposed.sourceOfWealthOtherChecked")}
                                            className="mt-0 h-[33px] max-w-[300px] placeholder:text-sm"
                                        />
                                    </div>
                                )}
                            />
                            {errors.politicallyExposed?.root && (
                                <span className="text-error">{errors.politicallyExposed?.root.message}</span>
                            )}
                        </fieldset>
                    )}
                </div>
            }
        />
    );
};
