import { RichText } from "components/RichText";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { RightArrowIcon } from "src/icons";
import { Routes } from "src/routes";
import type { Article } from "src/types";
import { cn, getValOrThrowError } from "src/utils";
import { Heading } from "../Heading";
import { Link } from "../Link";
import { ArticleAttributes } from "./ArticleAttributes";
import { ArticleCardImage } from "./ArticleCardImage";

export const ArticleCard: FC<Article> = (props) => {
    const { t } = useTranslation();

    const { title, tags, updatedAt, image, description, permissions } = getValOrThrowError(props, "Missing required attributes in article");

    return (
        <div className={cn("flex overflow-hidden rounded-[4px] border", "max-lg:flex-col", "max-sm:border-x-0")}>
            {image && <ArticleCardImage image={image} />}

            <div className={cn("flex flex-1 flex-col gap-4 p-8", "max-sm:gap-6 max-sm:px-4 max-sm:pb-6 max-sm:pt-4")}>
                <ArticleAttributes tags={tags} type={permissions?.type} updatedAt={updatedAt} />
                <div>
                    <Heading level={3} className="mb-1">
                        {title}
                    </Heading>
                    {description && <RichText content={description} />}
                </div>
                <Link
                    href={Routes.articleDetail.fillPathParams({ articleSlug: props?.slug! })}
                    className="items-center gap-2 fill-brand-primary text-base font-semibold text-brand-primary"
                >
                    {t("News.ReadArticle.CTA")} <RightArrowIcon className="size-5" />
                </Link>
            </div>
        </div>
    );
};
