import type { FC } from "react";

import { isNullish } from "src/utils";
import { useTranslation } from "./utils";

const EMPTY_VALUE_PLACEHOLDER = "";

export enum CurrencyFormatKey {
    default = "Currency.format.default",
    twoDecimalWithSign = "Currency.format.twoDecimalWithSign",
}

type CurrencyFormatterProps = Readonly<{
    value: number | undefined | null;
    currency: string;
    format?: CurrencyFormatKey;
}>;

export const getCurrencySymbol = (currency: string) => {
    switch (currency) {
        case "CZK":
            return "Kč";
        case "EUR":
            return "€";
        default:
            return currency;
    }
};

export const CurrencyFormatter: FC<CurrencyFormatterProps> = ({ value, currency, format = CurrencyFormatKey.default }) => {
    const { t } = useTranslation();
    if (isNullish(value)) {
        return EMPTY_VALUE_PLACEHOLDER;
    }
    return t(format, { value, currency });
};
