import { LogLevels } from "./constants";
import type { CustomProperties, LogLevel } from "./types";

export const getCommonCustomProperties = (): CustomProperties => ({
    // todo-quickstart - add any additional properties suitable for your project
    url: window.location.href,
    userAgent: window.navigator.userAgent,
    version: APP_INFO.VERSION,
    commit: APP_INFO.COMMIT_HASH,
});

export const logLevelToConsoleFn = (logLevel: LogLevel) => {
    switch (logLevel.value) {
        case LogLevels.Trace.value:
        case LogLevels.Debug.value:
            // eslint-disable-next-line no-console
            return console.debug;
        case LogLevels.Info.value:
            // eslint-disable-next-line no-console
            return console.info;
        case LogLevels.Warning.value:
            // eslint-disable-next-line no-console
            return console.warn;
        case LogLevels.Error.value:
            // eslint-disable-next-line no-console
            return console.error;
        default:
            throw new Error(`Unsupported log level: ${logLevel}`);
    }
};

export const getLoggerLevelPersistenceKey = (loggerName: string) => `logger-${loggerName}-level`;

export const toLogLevel = (levelOrName: LogLevel | number | string | null | undefined): LogLevel | undefined => {
    if (levelOrName === undefined || levelOrName === null) {
        return undefined;
    }
    if (isLogLevel(levelOrName)) {
        return levelOrName;
    }
    const searchValue = levelOrName.toString().toLowerCase();
    return Object.values(LogLevels).find(({ value, label }) => label.toLowerCase() === searchValue || value.toString() === searchValue);
};

export const isLogLevel = (value: unknown): value is LogLevel =>
    value !== undefined &&
    value !== null &&
    typeof value === "object" &&
    "value" in value &&
    "label" in value &&
    typeof value.value === "number" &&
    typeof value.label === "string";

export const getLogLevelLabels = () =>
    Object.values(LogLevels)
        .map(({ label }) => `"${label}"`)
        .join(" | ");

export const getConsoleMessageTemplate = (baseMessage: string | undefined, cause: unknown): string =>
    `${baseMessage}\nCustom properties: %O${cause ? "\nCause:" : ""}`;

export const getConsoleFnParams = (
    messageParams: ReadonlyArray<unknown> | undefined,
    customProperties: CustomProperties,
    cause?: unknown,
): ReadonlyArray<unknown> => [...(messageParams ?? []), customProperties, ...(cause ? [cause] : [])];

export const logToConsole = (
    logLevel: LogLevel,
    message: string | undefined,
    messageParams: ReadonlyArray<unknown> | undefined,
    customProperties: CustomProperties,
    cause?: unknown,
): void => {
    const consoleFn = logLevelToConsoleFn(logLevel);
    const effectiveMessage = getConsoleMessageTemplate(message, cause);
    const effectiveParams = getConsoleFnParams(messageParams, customProperties, cause);
    consoleFn(effectiveMessage, ...effectiveParams);
};
