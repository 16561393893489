import type { FC } from "react";
import { useForm } from "react-hook-form";

import { Button, Heading, Input, PopupWindow } from "src/components";
import { isCmsErrorResponseWithMessage, removeAuthToken, useForgotPasswordMutation } from "src/serverApi";
import { useTranslation } from "src/translations";
import { logger } from "./logger";

type FormFields = {
    email: string;
};

export const ForgotPasswordForm: FC = () => {
    const { t } = useTranslation();

    const [forgotPassword, { isLoading, isError }] = useForgotPasswordMutation();

    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
    } = useForm<FormFields>();

    const onSubmit = async (data: FormFields) => {
        try {
            removeAuthToken();
            await forgotPassword(data).unwrap();
            await PopupWindow.fire({
                title: t("ForgotPassword.success.popUp.title"),
                text: t("ForgotPassword.success.popUp.message"),
                icon: "success",
                confirmButtonText: t("ForgotPassword.success.popUp.confirm"),
            });
        } catch (err) {
            if (isCmsErrorResponseWithMessage(err)) {
                logger.error(err.data.error.message, err);
            } else {
                logger.error("Unknown error", err);
            }
        }
    };

    return (
        <div className="w-full space-y-6">
            <Heading level={2} className="mb-8">
                {t("ForgotPassword.card.title")}
            </Heading>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6">
                <div className="space-y-6">
                    <Input
                        type="text"
                        label={t("ForgotPassword.email.placeholder")}
                        {...register("email", { required: true })}
                        error={errors.email?.message}
                    />
                </div>
                <div>
                    {isError ? <span className="text-error">{t("Form.error")}</span> : null}
                    <Button type="submit" isLoading={isLoading} disabled={!isValid} fullWidth>
                        {t("ForgotPassword.button.send")}
                    </Button>
                </div>
            </form>
        </div>
    );
};
