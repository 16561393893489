import { Routes } from "src/routes";
import { typeCheckAppModule } from "src/utils";
import { NAME } from "./constants";
import { DashboardPage } from "./DashboardPage";

export const dashboard = typeCheckAppModule({
    NAME,
    Container: DashboardPage,
    route: Routes.dashboard,
});
