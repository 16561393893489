import type { FC } from "react";

import { SpinnerIcon } from "src/icons";
import { cn } from "src/utils";

type SpinnerProps = Readonly<{
    className?: string;
}>;

export const Spinner: FC<SpinnerProps> = ({ className }) => <SpinnerIcon className={cn("animate-spin text-white", className)} />;
