import { differenceInDays } from "date-fns";
import type { FC } from "react";

import { Stat } from "src/components";
import { getCurrencyConversionRate } from "src/config";
import { getUserCurrency, useProjectDetailStatsQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { type ProjectInfo, ProjectInfoStateEnum } from "src/types";
import { convertPriceToTargetCurrency, useAppSelector } from "src/utils";

type ProjectDetailStatsProps = Readonly<{
    loading: boolean;
    projectState: ProjectInfoStateEnum;
    projectInfo?: ProjectInfo;
}>;

export const ProjectDetailStats: FC<ProjectDetailStatsProps> = ({ loading, projectState, projectInfo }) => {
    const { t } = useTranslation();

    const userCurrency = useAppSelector(getUserCurrency);
    const conversionRate = useAppSelector(getCurrencyConversionRate);
    const project = projectInfo?.project;

    const { data: projectStats, isLoading: isLoadingProjectStats } = useProjectDetailStatsQuery(project?.documentId!, {
        skip: !project?.documentId,
    });

    const isLoading = loading || isLoadingProjectStats;

    return (
        <div className="grid md:grid-cols-2">
            {project?.calculation?.paReturn && (
                <Stat
                    key="expectedReturn"
                    value={project.calculation.paReturn}
                    label={t("PropertyDetail.Stats.ExpectedReturn.Label")}
                    infoBoxContent={t("PropertyDetail.Stats.ExpectedReturn.InfoBox")}
                    unit="%"
                    loading={isLoading}
                />
            )}
            {project?.calculation?.period && (
                <Stat
                    key="period"
                    value={project.calculation.period}
                    label={t("PropertyDetail.Stats.Period.Label")}
                    infoBoxContent={t("PropertyDetail.Stats.Period.InfoBox")}
                    unit={t("PropertyDetail.Stats.Period.Unit")}
                    loading={isLoading}
                />
            )}
            {projectState === ProjectInfoStateEnum.Prepared && project?.expectedOn && (
                <Stat
                    key="expectedDate"
                    value={Math.abs(differenceInDays(new Date(project.expectedOn), new Date()))}
                    label={t("PropertyDetail.Stats.ExpectedDate.Label")}
                    unit={t("PropertyDetail.Stats.ExpectedDate.Unit")}
                    loading={isLoading}
                />
            )}
            {projectState === ProjectInfoStateEnum.Open && project?.closedOn && (
                <Stat
                    key="closedDate"
                    value={differenceInDays(new Date(project.closedOn), new Date())}
                    label={t("PropertyDetail.Stats.CloseDate.Label")}
                    unit={t("PropertyDetail.Stats.CloseDate.Unit", {
                        count: differenceInDays(new Date(project.closedOn), new Date()),
                    })}
                    loading={isLoading}
                />
            )}
            {projectStats?.investorsCount !== 0 && (
                <Stat
                    key="investorCount"
                    value={projectStats?.investorsCount}
                    label={t("PropertyDetail.Stats.InvestorCount.Label")}
                    unit={t("PropertyDetail.Stats.InvestorCount.Unit")}
                    loading={isLoading}
                />
            )}
            {project?.tokenTotalPrice && project?.currency && (
                <Stat
                    key="totalPrice"
                    value={convertPriceToTargetCurrency(project.tokenTotalPrice, project.currency, userCurrency, conversionRate)}
                    label={t("PropertyDetail.Stats.TotalPrice.Label")}
                    unit={userCurrency}
                    loading={isLoading}
                />
            )}
            {projectStats?.averageInvestment !== 0 && (
                <Stat
                    key="averageInvestment"
                    value={projectStats?.averageInvestment}
                    label={t("PropertyDetail.Stats.AverageInvestment.Label")}
                    unit={userCurrency}
                    loading={isLoading}
                />
            )}
        </div>
    );
};
