import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { CmsError, PageLayout } from "src/components";
import { useVotingQuery } from "src/serverApi";
import { BannerPageEnum } from "src/types";
import { VotingCard } from "./components";

export const VotingPage: FC = () => {
    const { t } = useTranslation();

    const { data: voting, isLoading: isVotingLoading, isError: isVotingError, error: votingError } = useVotingQuery({});

    return (
        <PageLayout title={t("VotingPage.Title")} loading={isVotingLoading} banner={BannerPageEnum.Voting}>
            {isVotingError ? <CmsError error={votingError} fallbackMessage={t("Form.error")} /> : null}

            {voting?.data?.length === 0 ? (
                <p>{t("VotingPage.NoData")}</p>
            ) : (
                voting?.data?.map((votingItem) => <VotingCard key={votingItem.documentId} votingItem={votingItem} />)
            )}
        </PageLayout>
    );
};
