import type { FC } from "react";
import { BannerPageEnum } from "types/cms";

import { PageLayout } from "src/components";
import { getUser, useOrdersByUserIdQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { useAppSelector } from "src/utils";
import { OrdersTable } from "./components";

export const OrdersPage: FC = () => {
    const { t } = useTranslation();

    const user = useAppSelector(getUser);
    const { data: orders, isLoading } = useOrdersByUserIdQuery(user.id!);

    return (
        <PageLayout title={t("Orders.title")} loading={isLoading} banner={BannerPageEnum.Orders}>
            {orders && <OrdersTable orders={orders} />}
        </PageLayout>
    );
};
