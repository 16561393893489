import type { FC } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button, Checking, Heading, Loading, OrderLayout, TopBar, VerificationStepper } from "src/components";
import { Routes } from "src/routes";
import { useOrderQuery, useProjectInfoDetailBySlugQuery } from "src/serverApi";
import { CurrencyFormatter, useTranslation } from "src/translations";
import { OrderStateEnum, UserVerificationTypeEnum } from "src/types";
import { convertPriceInCentsToPrice, getValOrThrowError } from "src/utils";
import { useUserVerification } from "src/verification";
import { ConfirmoPay, PayoutPay } from "./components";

export const OrderPayPage: FC = () => {
    const orderDocumentId = getValOrThrowError(Routes.orderPay.useParams().orderDocumentId, "Missing Order Document Id");
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const checking = searchParams.get("checking");

    const { t } = useTranslation();
    const { data: order, isLoading } = useOrderQuery(orderDocumentId, {
        pollingInterval: checking ? 5000 : undefined,
    });
    const projectSlug = order?.data?.project?.projectInfo?.slug;
    const { data: projectInfo, isLoading: isLoadingProject } = useProjectInfoDetailBySlugQuery(projectSlug!, {
        skip: !projectSlug,
    });
    const { isVerificationDone } = useUserVerification();

    const isUserInvestor = isVerificationDone(UserVerificationTypeEnum.INVESTOR);

    if (isLoading || isLoadingProject) {
        return <Loading isFullPage />;
    }

    return (
        <>
            {!isUserInvestor && <VerificationStepper activeVerificationType={UserVerificationTypeEnum.INVESTOR} />}
            <TopBar />
            {order?.data?.state === OrderStateEnum.Paid ? (
                <div className="mt-8 grid justify-items-center gap-4">
                    <Heading level={1}>{t("BuyProperty.Pay.Completed")}</Heading>
                    <Heading level={5}>{t("BuyProperty.Pay.Description")}</Heading>
                    <Button
                        onClick={() => {
                            navigate(Routes.projects.path);
                        }}
                    >
                        {t("BuyProperty.Pay.BackToProjects")}
                    </Button>
                </div>
            ) : (
                <OrderLayout title={t("BuyProperty.HeadingPay")} projectInfo={projectInfo} openInNewTab>
                    <Heading level={6} className="mb-[3px] normal-case">
                        {t("Overview")}
                    </Heading>
                    <div className="!mb-5 grid grid-cols-2 gap-y-1.5 text-sm">
                        <div>{t("BuyPropertyShare.sharesToBuy")}</div>
                        <div className="text-right">{order?.data?.tokenCount ?? "-"}</div>
                        <div>{t("BuyPropertyShare.priceToPay")}</div>
                        <div className="text-right">
                            <CurrencyFormatter
                                currency={getValOrThrowError(order?.data?.currency)}
                                value={convertPriceInCentsToPrice(order?.data?.price)}
                            />
                        </div>
                    </div>
                    {checking ? (
                        <div className="mt-4">
                            <Checking
                                title={t("BuyPropertyShare.Checking")}
                                description={t("BuyPropertyShare.BankTransferInfo")}
                                linkToStopChecking={Routes.orderPay.fillPathParams({ orderDocumentId: orderDocumentId! })}
                            />
                        </div>
                    ) : (
                        <div className="grid gap-4">
                            <PayoutPay orderDocumentId={orderDocumentId} />
                            <ConfirmoPay orderDocumentId={orderDocumentId} />
                        </div>
                    )}
                </OrderLayout>
            )}
        </>
    );
};
