import type { Currency } from "src/types";
import { convertPriceToTargetCurrency, isNullish } from "src/utils";
import { DEFAULT_INVESTMENT_AMOUNT, ONE_HUNDRED_PERCENT, STEP_COUNT } from "./constants";

export const calculateAnnualRentalIncome = (investmentAmount: number, annualRentalIncomePercent: number) =>
    (annualRentalIncomePercent / ONE_HUNDRED_PERCENT) * investmentAmount;

export const calculateRemainderAfterXYears = (investmentAmount: number, incrementalPropertyValuePercent: number, period: number) =>
    investmentAmount * (1 + incrementalPropertyValuePercent / ONE_HUNDRED_PERCENT) ** period - investmentAmount;

export const calculateTotalIncome = (annualRentalIncome: number, remainderAfterXYears: number, period: number) =>
    annualRentalIncome * period + remainderAfterXYears;

/**
 * Calculate suitable step for the range input in 1,5,10 sequence
 * @example results: 1, 5, 10, 50, 100, 500, 1000
 */
export const calculateSuitableStep = (min?: number, max?: number) => {
    if (!min || !max) return 1;
    let step = 1;
    while (step < (max - min) / STEP_COUNT) {
        step *= step.toString().startsWith("1") ? 5 : 2;
    }
    return step;
};

export const convertProjectCalculation = (
    calculatorMin: number | undefined,
    calculatorMax: number | undefined,
    projectCurrency: Currency | undefined,
    targetCurrency: Currency,
    conversionRate: number,
) => {
    if (isNullish(calculatorMin) || isNullish(calculatorMax) || isNullish(projectCurrency)) return [0, 1];

    const convertPrice = (price: number) => convertPriceToTargetCurrency(price, projectCurrency, targetCurrency, conversionRate);
    return [roundToNiceNumber(convertPrice(calculatorMin)), roundToNiceNumber(convertPrice(calculatorMax))];
};

export const calculateRoundedMedian = (min?: number, max?: number) => {
    if (!min || !max) return DEFAULT_INVESTMENT_AMOUNT;
    const median = (min + max) / 2;
    return roundToNiceNumber(median);
};

const roundToNiceNumber = (value: number) => {
    if (value === 0) return 0;

    // Get number of digits before decimal point
    const magnitude = Math.floor(Math.log10(Math.abs(value)));

    // Determine rounding factor based on magnitude
    let roundingFactor;
    if (magnitude >= 4) {
        // 10000 and above
        roundingFactor = 10 ** (magnitude - 1);
    } else if (magnitude >= 3) {
        // 1000-9999
        roundingFactor = 10 ** (magnitude - 1);
    } else if (magnitude >= 2) {
        // 100-999
        roundingFactor = 10 ** (magnitude - 1);
    } else {
        roundingFactor = 10 ** magnitude;
    }

    return Math.round(value / roundingFactor) * roundingFactor;
};
