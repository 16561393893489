import { type TFunction } from "i18next";
import { z } from "zod";

import { isDateInFuture, validateDateString } from "./utils";

export const getAmlFormScheme = (t: TFunction) =>
    z.object({
        firstName: z.string().min(1, { message: t("AMLQuestionnaire.Section1.Inputs.FirstName.Error") }),
        lastName: z.string().min(1, { message: t("AMLQuestionnaire.Section1.Inputs.LastName.Error") }),
        residentialAddress: z.string().min(1, { message: t("AMLQuestionnaire.Section1.Inputs.Address.Error") }),
        countryAddress: z.string().min(1, { message: t("AMLQuestionnaire.Section1.Inputs.AddressCountry.Error") }),
        cityAddress: z.string().min(1, { message: t("AMLQuestionnaire.Section1.Inputs.AddressCity.Error") }),
        postalCode: z.string().min(1, { message: t("AMLQuestionnaire.Section1.Inputs.AddressPostalCode.Error") }),
        dateOfBirth: z.string().refine((value) => validateDateString(value), {
            message: t("AMLQuestionnaire.Section1.Inputs.DateOfBirth.Error"),
        }),
        socialSecurityNumber: z.string().optional(),
        idOrPassportNumber: z.string().min(1, { message: t("AMLQuestionnaire.Section1.Inputs.IDPassportNumber.Error") }),
        countryOfOrigin: z.string().min(1, { message: t("AMLQuestionnaire.Section1.Inputs.CountryOfOrigin.Error") }),

        citizenship: z.string().min(1, { message: t("AMLQuestionnaire.Section1.Inputs.Citizenship.Error") }),
        documentValidUntil: z
            .string()
            .refine((value) => validateDateString(value), {
                message: t("AMLQuestionnaire.Section1.Inputs.DocumentValidUntil.Error"),
            })
            .refine((value) => isDateInFuture(value), {
                message: t("AMLQuestionnaire.Section1.Inputs.DocumentValidUntil.ErrorExpired"),
            }),
        documentIssuedBy: z.string().min(1, { message: t("AMLQuestionnaire.Section1.Inputs.DocumentIssuedBy.Error") }),
        purposeOfContract: z.string().min(1, { message: t("AMLQuestionnaire.Section2.Inputs.PurposeOfContract.Error") }),
        expectedTradesVolume: z.string({
            required_error: t("AMLQuestionnaire.Section2.Inputs.ExpectedTradesVolume.Error"),
        }),
        originOfFunds: z
            .object({
                alimonyBenefitsPension: z.boolean().optional(),
                salary: z.boolean().optional(),
                business: z.boolean().optional(),
                capital: z.boolean().optional(),
                otherChecked: z.boolean().optional(),
                otherValue: z.string().optional(),
            })
            .refine((data) => data.alimonyBenefitsPension || data.business || data.capital || data.salary || data.otherChecked, {
                message: t("AMLQuestionnaire.Section2.Inputs.OriginOfFunds.Error"),
            })
            .refine(
                (data) => {
                    if (data.otherChecked) {
                        return typeof data.otherValue === "string" && data.otherValue.trim() !== "";
                    }
                    return true;
                },
                {
                    message: t("AMLQuestionnaire.Section2.Inputs.OriginOfFunds.Option.Other.Error"),
                },
            ),
        noCriminalActivity: z.string({ required_error: t("AMLQuestionnaire.Section2.Inputs.CriminalActivity.Error") }),
        countriesWithSanctions: z.string({
            required_error: t("AMLQuestionnaire.Section2.Inputs.CountriesWithSanctions.Error"),
        }),
        politicallyExposed: z
            .object({
                isChecked: z.string({ required_error: t("AMLQuestionnaire.Section2.Inputs.PoliticallyExposed.Error") }),
                sourceOfWealthSalary: z.boolean().optional(),
                sourceOfWealthCapital: z.boolean().optional(),
                sourceOfWealthBusiness: z.boolean().optional(),
                sourceOfWealthInheritance: z.boolean().optional(),
                sourceOfWealthOtherChecked: z.boolean().optional(),
                sourceOfWealthOtherValue: z.string().optional(),
            })
            .refine(
                (data) => {
                    if (data.isChecked === "yes") {
                        return (
                            data.sourceOfWealthSalary ||
                            data.sourceOfWealthCapital ||
                            data.sourceOfWealthBusiness ||
                            data.sourceOfWealthInheritance ||
                            data.sourceOfWealthOtherChecked
                        );
                    }
                    return true;
                },
                {
                    message: t("AMLQuestionnaire.Section2.Inputs.PEOriginOfFunds.Error"),
                },
            )
            .refine(
                (data) => {
                    if (data.sourceOfWealthOtherChecked) {
                        return typeof data.sourceOfWealthOtherValue === "string" && data.sourceOfWealthOtherValue.trim() !== "";
                    }
                    return true;
                },
                {
                    message: t("AMLQuestionnaire.Section2.Inputs.PEOriginOfFunds.Option.Other.Error"),
                },
            ),
        finalCheck: z.boolean().refine((val) => val, {
            message: t("AMLQuestionnaire.FinalCheck.Error"),
        }),
    });
