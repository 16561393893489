import { type FC } from "react";

import { Badge, IconWithPopover, orderStateBadgeMap } from "src/components";
import { InfoIcon } from "src/icons";
import { useTranslation } from "src/translations";
import { OrderStateEnum } from "src/types";

export type OrderStatusProps = Readonly<{
    state: OrderStateEnum;
}>;

export const OrderStatus: FC<OrderStatusProps> = ({ state }) => {
    const { t } = useTranslation();
    const { intent, titleLocKey, additionalInfoLocKey } = orderStateBadgeMap[state];

    return (
        <Badge intent={intent}>
            {t(titleLocKey)}
            {additionalInfoLocKey && <IconWithPopover icon={<InfoIcon className="size-4" />}>{t(additionalInfoLocKey)}</IconWithPopover>}
        </Badge>
    );
};
