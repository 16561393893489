// direct import, so serverApi can use logging module without cyclic dependencies (anywhere outside utils.ts)
// import { getBeBaseUrl } from "../serverApi/utils";
import { LogLevels } from "./constants";
import { ConsoleLogger, RemoteLogger } from "./loggers";
// import { GCPLogger } from "./loggers";
import type { ILoggerFactory, Logger, LogLevel } from "./types";
import { getLoggerLevelPersistenceKey, getLogLevelLabels, toLogLevel } from "./utils";

export class LoggerFactory implements ILoggerFactory {
    private readonly loggerMap: Map<string, Logger> = new Map<string, Logger>();

    private defaultLogLevel: LogLevel = LogLevels.Warning;

    private remoteLoggingEnabled = true;

    public getLogger(loggerName: string): Logger {
        const existingLogger = this.loggerMap.get(loggerName);
        if (existingLogger) {
            return existingLogger;
        }

        const logLevel = toLogLevel(localStorage.getItem(getLoggerLevelPersistenceKey(loggerName))) ?? LogLevels.Warning;
        // todo-quickstart - use logger implementation suitable for your project
        const newLogger = new ConsoleLogger(loggerName, { logLevel, defaultLogLevel: this.defaultLogLevel });
        // const newLogger = new GCPLogger(loggerName, { logLevel, defaultLogLevel: this.defaultLogLevel }, `${getBeBaseUrl()}frontend-logs`, this.remoteLoggingEnabled);
        this.loggerMap.set(loggerName, newLogger);
        return newLogger;
    }

    public resetLevel(loggerName: string): void {
        this.loggerMap.get(loggerName)?.resetLevel();
    }

    public resetLevelAll(): void {
        this.loggerMap.forEach((logger) => logger.resetLevel());
    }

    public setLevel(loggerName: string, logLevelInput: string | number | LogLevel): void {
        const logger = this.loggerMap.get(loggerName);
        const logLevel = toLogLevel(logLevelInput);
        if (logger && logLevel) {
            logger.setLevel(logLevel);
        } else if (!logLevel) {
            // eslint-disable-next-line no-console
            console.warn("Log level %s is not recognized. Please enter valid log level (%s).", logLevelInput, getLogLevelLabels());
        } else {
            // eslint-disable-next-line no-console
            console.warn(
                "Logger name %s is not recognized. Please enter valid logger name (%s).",
                loggerName,
                [...this.loggerMap.keys()].map((name) => `"${name}"`).join(" | "),
            );
        }
    }

    public setLevelToAll(logLevelInput: string | number | LogLevel): void {
        const logLevel = toLogLevel(logLevelInput);
        if (logLevel) {
            this.loggerMap.forEach((logger) => logger.setLevel(logLevel));
        } else {
            // eslint-disable-next-line no-console
            console.warn("Log level %s is not recognized. Please enter valid log level (%s).", logLevelInput, getLogLevelLabels());
        }
    }

    public setDefaultLevelToAll(logLevelInput: string | number | LogLevel): void {
        const logLevel = toLogLevel(logLevelInput);
        if (logLevel) {
            this.defaultLogLevel = logLevel;
            this.loggerMap.forEach((logger) => logger.setDefaultLevel(logLevel));
        } else {
            throw new Error(`Cannot set default log level to unrecognized log level: ${logLevelInput}`);
        }
    }

    public setRemoteLoggingEnabled(enabled: boolean): void {
        this.remoteLoggingEnabled = enabled;
        this.loggerMap.forEach((logger) => {
            if (logger instanceof RemoteLogger) {
                logger.setRemoteLoggingEnabled(enabled);
            }
        });
    }
}

export const loggerFactory = new LoggerFactory();

if (window) {
    // expose loggerFactory for users, so they may set log levels from console
    window.moroLogger = loggerFactory;
}
