import { Routes } from "src/routes";
import { typeCheckAppModule } from "src/utils";
import { NAME } from "./constants";
import { GoogleCallbackPage } from "./GoogleCallbackPage";

export const googleCallback = typeCheckAppModule({
    NAME,
    Container: GoogleCallbackPage,
    route: Routes.googleCallback,
    secure: false,
});
