import type { AppConfiguration, AppSelector } from "src/types";
import { getValOrThrowError } from "src/utils";
import { configApi } from "./serverApi";

const getConfigQueryState = configApi.endpoints.AppConfig.select();

export const getAppConfig: AppSelector<AppConfiguration | undefined> = (state) => getConfigQueryState(state).data;

export const getCurrencyConversionRate: AppSelector<AppConfiguration["conversionRateEurToCzk"]> = (state) =>
    getValOrThrowError(getAppConfig(state)?.conversionRateEurToCzk, "App config not loaded yet");
