import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { isRejectedWithValue } from "@reduxjs/toolkit";

import { isQueryFnError } from "src/errorHandling";
import { loggerFactory } from "src/logging";

const logger = loggerFactory.getLogger("rtkQueryErrorLogger");

export const rtkQueryErrorLogger: Middleware = (_store: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        const { payload, error, meta } = action;

        if (isQueryFnError(action.payload)) {
            const { message, originalError, queryArgs } = action.payload;
            logger
                .atDebug()
                .setCause(originalError)
                .setCustomProperties({
                    ...action.meta,
                    queryArgs,
                })
                .setMessage(message)
                .log();
        } else {
            logger.atDebug().setCause(error).setCustomProperties(meta).setMessage(`${payload}`).log();
        }
    }

    return next(action);
};
