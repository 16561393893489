import { type FC } from "react";

import { cn } from "src/utils";
import { MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY } from "./constants";

type BannerProps = Readonly<{
    desktopSrc: string;
    tabletSrc: string;
    mobileSrc: string;
    altText: string;
    linkUrl?: string;
    className?: string;
}>;

export const BannerBase: FC<BannerProps> = ({ linkUrl, ...restProps }) =>
    linkUrl ? (
        <a href={linkUrl} target="_blank" rel="noopener noreferrer">
            <BannerPicture {...restProps} linkUrl={linkUrl} />
        </a>
    ) : (
        <BannerPicture {...restProps} />
    );

const BannerPicture: FC<BannerProps> = ({ desktopSrc, tabletSrc, mobileSrc, linkUrl, altText }) => (
    <picture className="flex justify-center">
        <source media={MOBILE_MEDIA_QUERY} srcSet={mobileSrc} />
        <source media={TABLET_MEDIA_QUERY} srcSet={tabletSrc} />
        <img className={cn("rounded-lg", linkUrl && "cursor-pointer")} src={desktopSrc} alt={altText} />
    </picture>
);
