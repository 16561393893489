import { zodResolver } from "@hookform/resolvers/zod";
import type { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { Button, CmsError, CurrencySelector, DownloadLink, PopupWindow, UncontrolledFormCheckbox } from "src/components";
import { getAppConfig } from "src/config";
import { MinusIcon, PlusIcon } from "src/icons";
import { Routes } from "src/routes";
import { getUserCurrency, useAddVerificationMutation, useCreateOrderMutation, useUserQuery } from "src/serverApi";
import { CurrencyFormatter, useTranslation } from "src/translations";
import type { OrderRequestCurrencyEnum, ProjectInfo } from "src/types";
import { Currency, OrderRequestStateEnum, UserVerificationTypeEnum } from "src/types";
import {
    cleanArray,
    cn,
    convertPriceToTargetCurrency,
    getUploadFileName,
    getValOrThrowError,
    isNotNullish,
    logGtmEvent,
    useAppSelector,
} from "src/utils";
import { useUserVerification } from "src/verification";
import { logger } from "./logger";

type OrderFormProps = Readonly<{
    project: ProjectInfo["project"];
    availableShare: number;
    tokenPrice: number;
}>;

export const OrderForm: FC<OrderFormProps> = ({ project, availableShare, tokenPrice }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const userCurrency = useAppSelector(getUserCurrency);
    const appConfig = useAppSelector(getAppConfig);
    const projectData = getValOrThrowError(project, "Project data is required");
    const { nextVerification, isVerificationDone } = useUserVerification();
    const [createOrder, { isLoading, isError, error }] = useCreateOrderMutation();
    const [addVerification] = useAddVerificationMutation();
    const { data: user, isLoading: isLoadingUser } = useUserQuery();

    const maximumSharesToBuy = Math.min(projectData?.maxPurchaseQty ?? 0, availableShare);
    const minimumSharesToBuy = projectData.minPurchaseQty ?? 0;

    const termsAndConditions = appConfig?.termsAndConditions;
    const whitePaper = appConfig?.whitePaper;
    const informationProspect = projectData.projectInfo?.informationProspect;

    const schema = z.object({
        numOfShares: z
            .number({ invalid_type_error: t("BuyProperty.Error.NaN") })
            .int({ message: t("BuyProperty.Error.MustBeWholeNumber") })
            .min(minimumSharesToBuy, t("BuyProperty.Error.SelectMoreShares", { min: minimumSharesToBuy }))
            .max(maximumSharesToBuy, t("BuyProperty.Error.SelectLessShares", { max: maximumSharesToBuy })),
        termsAccepted: z.boolean().refine((value) => value, {
            message: `${t("BuyProperty.Error.YouHaveToAcceptDocument")} ${getUploadFileName(termsAndConditions)}`,
        }),
        whitePaperAccepted: z.boolean().refine((value) => value, {
            message: `${t("BuyProperty.Error.YouHaveToAcceptDocument")} ${getUploadFileName(whitePaper)}`,
        }),
        prospectAccepted: z.boolean().refine((value) => !informationProspect || value, {
            message: `${t("BuyProperty.Error.YouHaveToAcceptDocument")} ${getUploadFileName(informationProspect)}`,
        }),
        currency: z.string(),
    });

    type FormData = z.infer<typeof schema>;

    const {
        watch,
        register,
        control,
        handleSubmit,
        setValue,
        setError,
        trigger,
        formState: { errors },
    } = useForm<FormData>({
        resolver: zodResolver(schema),
        defaultValues: {
            numOfShares: minimumSharesToBuy,
            currency: userCurrency,
            prospectAccepted: false,
            termsAccepted: false,
            whitePaperAccepted: false,
        },
        mode: "onChange",
    });

    const isAllCheckboxChecked = watch(
        cleanArray(["termsAccepted", "whitePaperAccepted", informationProspect ? "prospectAccepted" : undefined]),
    ).every(Boolean);
    const selectedNumberOfTokens = Number.isNaN(watch("numOfShares")) ? 0 : watch("numOfShares");
    const selectedCurrency = watch("currency") as Currency;
    const conversionRateEurToCzk = getValOrThrowError(appConfig?.conversionRateEurToCzk);

    const tokenPriceInOrderCurrency = convertPriceToTargetCurrency(
        tokenPrice,
        getValOrThrowError(projectData?.currency),
        selectedCurrency,
        conversionRateEurToCzk,
    );
    const totalPrice = selectedNumberOfTokens * tokenPriceInOrderCurrency;

    const handleIncrement = (amount = 1) => {
        const numOfShares = selectedNumberOfTokens + amount;
        if (numOfShares <= maximumSharesToBuy) {
            setValue("numOfShares", numOfShares);
            trigger("numOfShares");
        } else if (numOfShares > maximumSharesToBuy) {
            setError("numOfShares", {
                message: t("BuyProperty.Error.SelectLessShares", { max: maximumSharesToBuy }),
            });
        }
    };

    const handleDecrement = () => {
        if (selectedNumberOfTokens > minimumSharesToBuy) {
            setValue("numOfShares", selectedNumberOfTokens - 1);
            trigger("numOfShares");
        }
    };

    const handleSelectAll = () => {
        setValue("termsAccepted", !isAllCheckboxChecked, {
            shouldValidate: true,
        });
        setValue("whitePaperAccepted", !isAllCheckboxChecked, {
            shouldValidate: true,
        });
        if (informationProspect) {
            setValue("prospectAccepted", !isAllCheckboxChecked, {
                shouldValidate: true,
            });
        }
    };

    const onSubmit = async (formData: FormData) => {
        if (
            nextVerification.type === UserVerificationTypeEnum.ORDER ||
            isVerificationDone(UserVerificationTypeEnum.ORDER) ||
            isVerificationDone(UserVerificationTypeEnum.INVESTOR)
        ) {
            const userId = getValOrThrowError(user?.id, "User ID is required");
            const projectDocumentId = getValOrThrowError(project?.documentId, "Project document ID is required");
            try {
                const createdOrder = await createOrder({
                    data: {
                        state: OrderRequestStateEnum.Open,
                        project: projectDocumentId,
                        user: userId,
                        tokenCount: formData.numOfShares,
                        price: totalPrice * 100,
                        currency: formData.currency as OrderRequestCurrencyEnum,
                    },
                }).unwrap();
                await addVerification({ verificationType: UserVerificationTypeEnum.ORDER, userId }).unwrap();
                if (isNotNullish(createdOrder?.data?.id)) {
                    navigate(Routes.orderPay.fillPathParams({ orderDocumentId: createdOrder?.data?.documentId!.toString() }));
                }
            } catch (e) {
                logger.error("Error while creating order", e);
            }
        } else {
            PopupWindow.fire({
                title: t("BuyProperty.UserVerificationRequired.Title"),
                text: t("BuyProperty.UserVerificationRequired.Text"),
                confirmButtonText: t("BuyProperty.UserVerificationRequired.ButtonText"),
            }).then(() => navigate(`${Routes.verifications.path}?slug=${projectData.projectInfo?.slug}`));
        }
    };

    const isAmountChangeDisabled = (addAmount: number) => {
        const numOfShares = selectedNumberOfTokens + addAmount;
        return numOfShares > maximumSharesToBuy || numOfShares < minimumSharesToBuy;
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <p className="!mb-1 text-sm">{t("BuyProperty.SharesToBuy")}</p>
            <div className="flex h-[3.125rem] rounded-t-lg !border transition duration-200 ease-in-out focus-within:border-brand-primary hover:shadow-e200">
                <button
                    type="button"
                    onClick={handleDecrement}
                    className="group cursor-pointer px-[0.8125rem]"
                    aria-label="Decrement"
                    disabled={isAmountChangeDisabled(-1)}
                >
                    <MinusIcon className="size-6 group-disabled:fill-brand-disabled" />
                </button>
                <input
                    type="number"
                    style={{ MozAppearance: "textfield" }}
                    {...register("numOfShares", {
                        valueAsNumber: true,
                    })}
                    onKeyDown={(e) => {
                        if (e.key === "," || e.key === ".") {
                            e.preventDefault();
                        }
                    }}
                    value={selectedNumberOfTokens}
                    className={cn(
                        "custom-number-input form-input w-full border-x px-2 py-2.5 text-center text-lg !ring-0 transition-colors duration-200 ease-in-out focus:border-brand-primary",
                        "[&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none",
                        errors.numOfShares && "border-error focus:border-error",
                    )}
                />
                <button
                    type="button"
                    onClick={() => handleIncrement()}
                    className="group cursor-pointer px-[0.8125rem]"
                    aria-label="Increment"
                    disabled={isAmountChangeDisabled(1)}
                >
                    <PlusIcon className="size-6 group-disabled:fill-brand-disabled" />
                </button>
            </div>

            <div
                className={cn(
                    "!mb-3 flex h-[3.125rem] justify-between rounded-b-lg border-x border-b transition duration-200 ease-in-out hover:shadow-e200",
                )}
            >
                <button
                    type="button"
                    onClick={() => handleIncrement(10)}
                    className="h-full w-1/4 disabled:text-disabled"
                    disabled={isAmountChangeDisabled(10)}
                >
                    <span>+10</span>
                </button>
                <button
                    type="button"
                    onClick={() => handleIncrement(50)}
                    className="h-full w-1/4 border-x disabled:text-disabled"
                    disabled={isAmountChangeDisabled(50)}
                >
                    <span>+50</span>
                </button>
                <button
                    type="button"
                    onClick={() => handleIncrement(100)}
                    className="h-full w-1/4 border-r disabled:text-disabled"
                    disabled={isAmountChangeDisabled(100)}
                >
                    <span>+100</span>
                </button>
                <button
                    type="button"
                    onClick={() => handleIncrement(1000)}
                    className="h-full w-1/4 disabled:text-disabled"
                    disabled={isAmountChangeDisabled(1000)}
                >
                    <span>+1000</span>
                </button>
            </div>
            {errors.numOfShares && <p className="text-sm text-error">{errors.numOfShares.message}</p>}
            <div className="mb-4 flex items-center justify-between space-x-2">
                <p className="w-full text-sm font-semibold">{t("BuyPropertyCurrency.label")}</p>
                <Controller
                    name="currency"
                    control={control}
                    render={({ field }) => <CurrencySelector {...field} error={errors.currency} className="w-full" />}
                />
            </div>
            <div className="!mb-5 grid grid-cols-2 gap-y-3 text-sm">
                <p className="font-semibold">
                    {t("BuyPropertyShare.totalPriceCurrency", {
                        currency: selectedCurrency,
                    })}
                </p>
                <p className="text-right font-semibold">
                    <CurrencyFormatter value={totalPrice} currency={selectedCurrency} />
                </p>
            </div>
            <div className="mb-10 flex flex-col gap-y-1">
                <UncontrolledFormCheckbox onChange={handleSelectAll} checked={isAllCheckboxChecked}>
                    {t("BuyProperty.IAgreeWithAllDocuments")}
                </UncontrolledFormCheckbox>
                <UncontrolledFormCheckbox error={errors.termsAccepted} {...register("termsAccepted")}>
                    {t("BuyProperty.IAgreeWithDocument")}{" "}
                    <DownloadLink
                        className="text-sm"
                        href={termsAndConditions?.url ?? "#"}
                        onClick={() => {
                            const documentType = "terms_and_conditions";
                            logGtmEvent({ event: "document_view", documentType });
                        }}
                    >
                        {getUploadFileName(termsAndConditions)}
                    </DownloadLink>
                </UncontrolledFormCheckbox>
                <UncontrolledFormCheckbox error={errors.whitePaperAccepted} {...register("whitePaperAccepted")}>
                    {t("BuyProperty.IAgreeWithDocument")}{" "}
                    <DownloadLink
                        className="text-sm"
                        href={whitePaper?.url ?? "#"}
                        onClick={() => {
                            const documentType = "whitepaper";
                            logGtmEvent({ event: "document_view", documentType });
                        }}
                    >
                        {getUploadFileName(whitePaper)}
                    </DownloadLink>
                </UncontrolledFormCheckbox>
                {informationProspect && (
                    <UncontrolledFormCheckbox error={errors.prospectAccepted} {...register("prospectAccepted")}>
                        {t("BuyProperty.IAgreeWithDocument")}{" "}
                        <DownloadLink
                            className="text-sm"
                            href={informationProspect?.url ?? "#"}
                            onClick={() => {
                                const documentType = "information_prospect";
                                logGtmEvent({ event: "document_view", documentType });
                            }}
                        >
                            {getUploadFileName(informationProspect)}
                        </DownloadLink>
                    </UncontrolledFormCheckbox>
                )}
            </div>
            {isError && <CmsError className="mb-4" error={error} fallbackMessage={t("Form.error")} />}
            <Button type="submit" fullWidth size="large" isLoading={isLoading} disabled={isLoadingUser}>
                {t("BuyProperty.ConfirmButton")}
            </Button>
        </form>
    );
};
