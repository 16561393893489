import type { FC } from "react";

import { PageLayout } from "src/components";
import { Routes } from "src/routes";
import { useProjectInfoDetailBySlugQuery } from "src/serverApi";
import { ProjectInfoStateEnum } from "src/types";
import { getValOrThrowError } from "src/utils";
import { CTA, ProjectBadges } from "../components";
import {
    ProjectDetailDescription,
    ProjectDetailGallery,
    ProjectDetailHeader,
    ProjectDetailLayout,
    ProjectDetailSidebar,
    ProjectDetailStats,
    ProjectDetailTimeline,
} from "./components";
import { logger } from "./logger";
import { useScrollToTop } from "./utils";

export const ProjectDetailPage: FC = () => {
    useScrollToTop();
    const params = Routes.projectDetail.useParams();
    const projectSlug = getValOrThrowError(params.projectSlug, "Project slug is required");
    const { data: projectInfo, isLoading, isFetching } = useProjectInfoDetailBySlugQuery(projectSlug);
    const project = projectInfo?.project;
    const projectState = project?.state ?? ProjectInfoStateEnum.Unknown;

    const loading = isLoading || isFetching;

    return (
        <PageLayout
            title={projectInfo?.title}
            rightCornerComponent={<ProjectBadges projectState={projectState} countryCode={project?.countryCode} />}
        >
            <div className="mx-auto grid max-w-[1250px] grid-flow-row gap-y-12 md:gap-y-16 xl:relative">
                <CTA
                    className="fixed bottom-6 left-1/2 z-40 w-[80vw] max-w-72 -translate-x-1/2 lg:hidden"
                    isFloating
                    logger={logger}
                    loading={loading}
                    projectSlug={projectSlug}
                    projectDocumentId={project?.documentId!}
                />
                <ProjectDetailHeader
                    loading={loading}
                    projectState={projectState}
                    completedOn={project?.completedOn}
                    layout={project?.layout}
                />
                <ProjectDetailLayout
                    gallery={<ProjectDetailGallery loading={loading} projectImages={project?.images ?? []} />}
                    timeline={<ProjectDetailTimeline loading={loading} {...project} />}
                    stats={<ProjectDetailStats loading={loading} projectState={projectState} projectInfo={projectInfo} />}
                    description={<ProjectDetailDescription loading={loading} content={projectInfo?.description ?? []} />}
                    sidebar={<ProjectDetailSidebar loading={loading} projectInfo={projectInfo} />}
                />
            </div>
        </PageLayout>
    );
};
