import type { FC } from "react";

import { Button, CmsError, PopupWindow } from "src/components";
import type { Logger } from "src/logging";
import {
    getUser,
    isCmsErrorResponseWithMessage,
    useCreateFundedRequestMutation,
    useHasUserFundedRequestForProjectDocumentIdQuery,
} from "src/serverApi";
import { useTranslation } from "src/translations";
import type { DocumentId } from "src/types";
import { useAppSelector } from "src/utils";

export type FundedRequestButtonProps = Readonly<{
    loading: boolean;
    projectDocumentId: DocumentId;
    logger: Logger;
}>;

export const FundedRequestButton: FC<FundedRequestButtonProps> = ({ loading, projectDocumentId, logger }) => {
    const { t } = useTranslation();

    const user = useAppSelector(getUser);

    const {
        data: hasAlreadySendRequest,
        isLoading: hasAlreadySendRequestLoading,
        isError,
        error,
    } = useHasUserFundedRequestForProjectDocumentIdQuery({
        userId: user.id!,
        projectDocumentId,
    });

    const [createFundedRequest, { isLoading: isCreateFundedRequestLoading }] = useCreateFundedRequestMutation();

    const handleOnClick = async () => {
        if (user.documentId) {
            try {
                await createFundedRequest({ data: { user: user.documentId, project: projectDocumentId } }).unwrap();
                await PopupWindow.fire({
                    title: t("PropertyDetail.FundedInfo.SuccessTitle"),
                    text: t("PropertyDetail.FundedInfo.SuccessText"),
                    icon: "success",
                });
            } catch (err) {
                if (isCmsErrorResponseWithMessage(err)) {
                    logger.error(err.data.error.message, err);
                } else {
                    logger.error("Unknown error", err);
                }
                await PopupWindow.fire({
                    title: t("PropertyDetail.FundedInfo.ErrorTitle"),
                    text: t("PropertyDetail.FundedInfo.ErrorText"),
                    icon: "error",
                });
            }
        }
    };

    if (isError) return <CmsError error={error} fallbackMessage={t("Form.error")} />;
    return (
        <Button
            disabled={hasAlreadySendRequest}
            isLoading={loading || hasAlreadySendRequestLoading || isCreateFundedRequestLoading}
            fullWidth
            onClick={handleOnClick}
        >
            {hasAlreadySendRequest ? t("PropertyDetail.FundedInfo.ButtonAlreadyReact") : t("PropertyDetail.FundedInfo.Button")}
        </Button>
    );
};
