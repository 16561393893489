import { Routes } from "src/routes";
import { typeCheckAppModule } from "src/utils";
import { NAME } from "./constants";
import { SignUpPage } from "./SignUpPage";

export const signUp = typeCheckAppModule({
    NAME,
    Container: SignUpPage,
    route: Routes.signUp,
    secure: false,
});
