import { LogLevels } from "../constants";
import type { CustomProperties, ILoggingEventBuilder, Logger, LoggerConfig, LogLevel, MutableLoggerConfig } from "../types";
import { getLoggerLevelPersistenceKey } from "../utils";
import { type LogBuilderDataFn, LoggingEventBuilder, type LoggingEventBuilderData } from "./LoggingEventBuilder";

/**
 * Provides default implementation of shared functionality for all loggers:
 * - Handling of errors which occurred during log process.
 * - Log builder handling.
 * - Log level handling.
 */
export abstract class BasicLogger implements Logger {
    protected readonly name: string;

    protected config: MutableLoggerConfig;

    protected readonly logBuilderDataFn: LogBuilderDataFn;

    protected constructor(loggerName: string, config: LoggerConfig) {
        this.config = { ...config };
        this.name = loggerName;
        this.logBuilderDataFn = this.safeLog.bind(this);
    }

    public trace(message: string, ...messageParams: unknown[]): void {
        this.safeLog({ logLevel: LogLevels.Trace, message, messageParams });
    }

    public debug(message: string, ...messageParams: unknown[]): void {
        this.safeLog({ logLevel: LogLevels.Debug, message, messageParams });
    }

    public info(message: string, ...messageParams: unknown[]): void {
        this.safeLog({ logLevel: LogLevels.Info, message, messageParams });
    }

    public warn(message: string, ...messageParams: unknown[]): void {
        this.safeLog({ logLevel: LogLevels.Warning, message, messageParams });
    }

    public error(message: string, ...messageParams: unknown[]): void {
        this.safeLog({ logLevel: LogLevels.Error, message, messageParams });
    }

    public traceStructured(customProperties: CustomProperties, message: string, ...messageParams: unknown[]): void {
        this.safeLog({ logLevel: LogLevels.Trace, customProperties, message, messageParams });
    }

    public debugStructured(customProperties: CustomProperties, message: string, ...messageParams: unknown[]): void {
        this.safeLog({ logLevel: LogLevels.Debug, customProperties, message, messageParams });
    }

    public infoStructured(customProperties: CustomProperties, message: string, ...messageParams: unknown[]): void {
        this.safeLog({ logLevel: LogLevels.Info, customProperties, message, messageParams });
    }

    public warnStructured(customProperties: CustomProperties, message: string, ...messageParams: unknown[]): void {
        this.safeLog({ logLevel: LogLevels.Warning, customProperties, message, messageParams });
    }

    public errorStructured(customProperties: CustomProperties, message: string, ...messageParams: unknown[]): void {
        this.safeLog({ logLevel: LogLevels.Error, customProperties, message, messageParams });
    }

    public errorCatch(error: unknown, message: string, ...messageParams: unknown[]): void {
        this.safeLog({ logLevel: LogLevels.Error, cause: error, message, messageParams });
    }

    public errorCatchStructured(error: unknown, customProperties: CustomProperties, message: string, ...messageParams: unknown[]): void {
        this.safeLog({ logLevel: LogLevels.Error, cause: error, customProperties, message, messageParams });
    }

    public atTrace(): ILoggingEventBuilder {
        return new LoggingEventBuilder(this.logBuilderDataFn, LogLevels.Trace);
    }

    public atDebug(): ILoggingEventBuilder {
        return new LoggingEventBuilder(this.logBuilderDataFn, LogLevels.Debug);
    }

    public atInfo(): ILoggingEventBuilder {
        return new LoggingEventBuilder(this.logBuilderDataFn, LogLevels.Info);
    }

    public atWarn(): ILoggingEventBuilder {
        return new LoggingEventBuilder(this.logBuilderDataFn, LogLevels.Warning);
    }

    public atError(): ILoggingEventBuilder {
        return new LoggingEventBuilder(this.logBuilderDataFn, LogLevels.Error);
    }

    public setLevel(logLevel: LogLevel): void {
        this.config.logLevel = logLevel;
        localStorage.setItem(getLoggerLevelPersistenceKey(this.name), this.config.logLevel.value.toString());
    }

    public setDefaultLevel(logLevel: LogLevel): void {
        this.config.defaultLogLevel = logLevel;
    }

    public resetLevel(): void {
        this.setLevel(this.config.defaultLogLevel);
    }

    private safeLog(logData: LoggingEventBuilderData): void {
        try {
            if (logData.logLevel.value >= this.config.logLevel.value) {
                this.log(logData);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error("Logger failure. Log data: %O", logData, error);
        }
    }

    protected abstract log(logData: LoggingEventBuilderData): void;
}
