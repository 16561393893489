import { forwardRef } from "react";

import { getCurrencySymbol } from "src/translations";
import { Currency } from "src/types";
import { Select, SelectItem, type SelectProps } from "./Select";

type CurrencySelectorProps = Readonly<Omit<SelectProps, "children">>;

export const CurrencySelector = forwardRef<HTMLDivElement, CurrencySelectorProps>((props, ref) => (
    <Select {...props} ref={ref}>
        {Object.keys(Currency).map((currency) => (
            <SelectItem key={currency} value={currency}>
                {currency} <span className="text-tertiary">({getCurrencySymbol(currency)})</span>
            </SelectItem>
        ))}
    </Select>
));
