// TODO: locale does not seem to be used, get it from current i18n lang? @see toLocalDate
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const formatAmount = (number: number, locale = "cs-CZ") =>
    new Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(number);

export const useScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
};
