import { combineReducers } from "redux";

import { notificationsModule } from "src/notifications";
import { rootApi } from "src/serverApi";

/**
 * Gets root reducer of application.
 *
 * Module reducers are added manually. Hardcoded names are used, otherwise
 * code suggestion for state does not work well.
 *
 * Any unhandled error from reducers will be processed by top level error handling and ErrorPage will be displayed.
 */
export const getRootReducer = () =>
    combineReducers({
        [rootApi.reducerPath]: rootApi.reducer,
        core: combineReducers({
            notifications: notificationsModule.reducer,
        }),
    });
