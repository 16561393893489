export enum QuestionnaireSection {
    PersonalInfo = "personal.info",
    AdditionalInfo = "additional.info",
}

export enum ExpectedTradesVolume {
    LessThan2k = "< 2k EUR",
    Between2kAnd10k = "2k-10k EUR",
    Between10kAnd50k = "10k-50k EUR",
    Between50kAnd200k = "50k-200k EUR",
    MoreThan200k = "> 200k EUR",
}
