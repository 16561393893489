import "sweetalert2/dist/sweetalert2.min.css";

import Swal2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const PopupWindow = withReactContent(
    Swal2.mixin({
        customClass: {
            popup: "!bg-primary !font-inter !p-6",
            title: "!text-2xl !leading-[2.125rem] !font-bold !text-primary",
            container: "!text-base !text-secondary !rounded-lg",
            confirmButton:
                "!transition-colors !rounded-full !font-medium !duration-200 !ease-in-out !focus:outline !focus:outline-4 !focus:outline-brand-link/20 !text-base !leading-[1.6rem] !px-4 !py-2.5 !text-white !bg-brand !hover:bg-brand-secondary",
            cancelButton:
                "!transition-colors !rounded-full !font-medium !duration-200 !ease-in-out !focus:outline !focus:outline-4 !focus:outline-brand-link/20 !text-base !leading-[1.6rem] !px-4 !py-2.5 !text-white !bg-red-700 !hover:bg-red-500",
        },
    }),
);
