import type { FC } from "react";

import { PublicLayout } from "src/components";
import { NewPasswordForm } from "./NewPasswordForm";

export const NewPasswordPage: FC = () => {
    return (
        <PublicLayout>
            <NewPasswordForm />
        </PublicLayout>
    );
};
