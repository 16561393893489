import { useCallback } from "react";

import { useUserQuery, useVerificationOptionsQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { UserVerificationTypeEnum } from "src/types";

export const useUserVerification = () => {
    const { t } = useTranslation();
    const { data: user, isLoading: isLoadingUser } = useUserQuery();
    const { data: verificationOptions, isLoading: isLoadingVerificationOptions } = useVerificationOptionsQuery({
        sort: "relevance",
    });

    const isVerificationDone = useCallback(
        (verification?: UserVerificationTypeEnum) => {
            if (isLoadingVerificationOptions) {
                return false;
            }
            const verificationExist = verificationOptions?.data?.some(({ type }) => type === verification) ?? false;
            if (!verificationExist) {
                return true;
            }
            return user?.verification.some((v) => v.type === verification) ?? false;
        },
        [isLoadingVerificationOptions, user?.verification, verificationOptions?.data],
    );
    const currentVerification = user?.verification.toSorted((a, b) => b.relevance - a.relevance)[0];
    const nextVerification = verificationOptions?.data?.find(({ relevance }) => relevance === (currentVerification?.relevance ?? 0) + 1);

    const verificationOptionsWithTranslation = (verificationOptions?.data || []).map((verificationOption) => ({
        ...verificationOption,
        name: t(`Verification.name.${verificationOption.type}`),
        description: t(`Verification.description.${verificationOption.type}`),
    }));

    return {
        isVerificationLoading: isLoadingUser || isLoadingVerificationOptions,
        verificationOptions: verificationOptionsWithTranslation,
        currentVerification: {
            type: currentVerification?.type ?? UserVerificationTypeEnum.SIGNUP,
            relevance: currentVerification?.relevance ?? 0,
        },
        nextVerification: {
            type: nextVerification?.type ?? UserVerificationTypeEnum.INVESTOR,
            relevance: nextVerification?.relevance ?? 0,
        },
        isVerificationDone,
    };
};
