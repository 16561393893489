import type { FC, SVGProps } from "react";

import { Routes } from "src/app/routes";
import { BarChartIcon, BriefcaseIcon, FileListIcon, HomeIcon, NewspaperIcon, PieChartIcon, QuestionIcon, SettingsIcon } from "src/icons";
import type { LocKey } from "src/translations";
import type { ParameterlessRoute } from "src/types";

export type MenuItem = Readonly<{
    route: ParameterlessRoute;
    Icon: FC<SVGProps<SVGSVGElement>>;
    titleKey: LocKey;
}>;

export const menuItems: ReadonlyArray<MenuItem> = [
    {
        Icon: PieChartIcon,
        titleKey: "InvestorNavigation.Dashboard",
        route: Routes.dashboard,
    },
    {
        Icon: FileListIcon,
        titleKey: "InvestorNavigation.Orders",
        route: Routes.orders,
    },
    {
        Icon: HomeIcon,
        titleKey: "InvestorNavigation.Projects",
        route: Routes.projects,
    },
    {
        Icon: BarChartIcon,
        titleKey: "InvestorNavigation.Voting",
        route: Routes.voting,
    },
    {
        Icon: NewspaperIcon,
        titleKey: "InvestorNavigation.News",
        route: Routes.news,
    },
    {
        Icon: BriefcaseIcon,
        titleKey: "InvestorNavigation.InvestorClub",
        route: Routes.investorClub,
    },
    {
        Icon: QuestionIcon,
        titleKey: "InvestorNavigation.HowTo",
        route: Routes.howTo,
    },
    {
        Icon: SettingsIcon,
        titleKey: "InvestorNavigation.Settings",
        route: Routes.settings,
    },
] as const;
