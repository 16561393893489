import { Routes } from "src/routes";
import { typeCheckAppModule } from "src/utils";
import { NAME } from "./constants";
import { OrderPage } from "./OrderPage";

export const projectOrder = typeCheckAppModule({
    NAME,
    Container: OrderPage,
    route: Routes.projectOrder,
});
