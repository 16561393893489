import type { FC } from "react";

import { Article, CmsError, PageLayout } from "src/components";
import { Routes } from "src/routes";
import { useArticleBySlugQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { getValOrThrowError } from "src/utils";

export const ArticleDetailPage: FC = () => {
    const { t } = useTranslation();

    const articleSlug = getValOrThrowError(Routes.articleDetail.useParams().articleSlug, "Article slug is required");

    const { data, isLoading, isError, error } = useArticleBySlugQuery(articleSlug);

    return (
        <PageLayout loading={isLoading}>
            {isError ? <CmsError error={error} fallbackMessage={t("Form.error")} /> : null}
            {data && <Article {...data} />}
        </PageLayout>
    );
};
