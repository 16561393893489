import { differenceInDays } from "date-fns";
import type { FC } from "react";
import Skeleton from "react-loading-skeleton";
import type { ReadonlyDeep } from "type-fest";

import { useGetAvailableTokensCountByProjectDocumentIdQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { type ProjectInfo, ProjectInfoStateEnum } from "src/types";
import { isNullish, useLocalDate } from "src/utils";
import { calculateSharePercentage } from "../../utils";
import { PERCENT_INVESTED_TO_SHOW_PROGRESS_BAR } from "../constants";

type ProjectCardMetricsProps = ReadonlyDeep<
    ProjectInfo["project"] & {
        projectState: ProjectInfoStateEnum;
    }
>;

export const ProjectCardMetrics: FC<ProjectCardMetricsProps> = ({
    documentId,
    projectState,
    expectedOn,
    closedOn,
    completedOn,
    calculation,
    tokenTotalCount,
}) => {
    const { t } = useTranslation();
    const { data: availableTokensCount, isLoading } = useGetAvailableTokensCountByProjectDocumentIdQuery(documentId!, {
        skip: !documentId,
    });
    const toLocalDate = useLocalDate();

    if (isLoading)
        return (
            <div className="flex max-lg:flex-col">
                <div className="flex w-full items-center gap-3">
                    <Skeleton className="h-20" containerClassName="flex-1" />
                    <Skeleton className="h-20" containerClassName="flex-1" />
                    <Skeleton className="h-20" containerClassName="flex-1" />
                </div>
            </div>
        );

    const { paReturn, period } = calculation || {};
    const investedPercent = tokenTotalCount ? calculateSharePercentage(availableTokensCount ?? 0, tokenTotalCount) : 0;

    return (
        <>
            {investedPercent >= PERCENT_INVESTED_TO_SHOW_PROGRESS_BAR && (
                <div className="flex flex-col gap-3 font-inter">
                    {projectState === ProjectInfoStateEnum.Open ? (
                        <>
                            <span className="font-bold">
                                {investedPercent.toFixed(2)} % {t("ProjectListPageCard.Invested")}
                            </span>
                            <div className="h-2 overflow-x-hidden rounded-main bg-subtle">
                                <div style={{ width: `${investedPercent}%` }} className="h-2 rounded-l-main bg-brand-primary" />
                            </div>
                        </>
                    ) : (
                        <>
                            {expectedOn && projectState === ProjectInfoStateEnum.Prepared && (
                                <span>
                                    {t("ProjectListPageCard.ExpectedOn")}{" "}
                                    <span className="font-bold">{toLocalDate(new Date(expectedOn))}</span>
                                </span>
                            )}
                            {completedOn && projectState === ProjectInfoStateEnum.Completed && (
                                <span>
                                    {t("ProjectListPageCard.CompletedOn")}{" "}
                                    <span className="font-bold">{toLocalDate(new Date(completedOn))}</span>
                                </span>
                            )}
                            {closedOn && projectState === ProjectInfoStateEnum.Closed && (
                                <span>
                                    {t("ProjectListPageCard.EndedOn")} <span className="font-bold">{toLocalDate(new Date(closedOn))}</span>
                                </span>
                            )}
                            <div className="h-2 overflow-x-hidden rounded-main bg-subtle" />
                        </>
                    )}
                </div>
            )}
            {![expectedOn, paReturn, period, investedPercent].every(isNullish) && (
                <div className="grid auto-cols-fr grid-flow-col grid-rows-1 items-start max-sm:-mx-2 [&>div:first-child]:rounded-l-lg [&>div:last-child]:rounded-r-lg [&>div:last-child]:border-r [&>div]:border [&>div]:border-r-0">
                    {projectState === ProjectInfoStateEnum.Prepared && expectedOn && (
                        <Stat
                            key="openDate"
                            value={`${Math.abs(differenceInDays(new Date(expectedOn), new Date()))} ${t("PropertyDetail.Stats.ExpectedDate.Unit")}`}
                            label={t("PropertyDetail.Stats.ExpectedDate.Label")}
                        />
                    )}
                    {paReturn && <Stat label={t("ProjectListPageCard.Return")} value={`${paReturn} %`} />}
                    {investedPercent !== null && (
                        <Stat label={t("ProjectListPageCard.Invested")} value={`${investedPercent.toFixed(2)} %`} />
                    )}
                    {period && (
                        <Stat label={t("ProjectListPageCard.Period")} value={`${period} ${t("PropertyDetail.Stats.Period.Unit")}`} />
                    )}
                </div>
            )}
        </>
    );
};

const Stat: FC<{ label: string; value: string }> = ({ label, value }) => (
    <div className="flex h-full flex-1 flex-col items-center p-2 text-center">
        <h4 className="whitespace-nowrap font-manrope text-2xl font-black text-brand-primary max-sm:text-base max-sm:font-medium">
            {value}
        </h4>
        <span className="font-inter max-sm:text-[0.65rem] max-sm:font-medium max-sm:leading-[snug]">{label}</span>
    </div>
);
