import * as RadixAccordion from "@radix-ui/react-accordion";
import { type FC, type ReactNode } from "react";

import { cn } from "src/utils";

type AccordionProps = Readonly<{
    className?: string;
    value?: string[];
    defaultValue?: string[];
    onValueChange?: (value: string[]) => void;
    children: ReactNode;
}>;

export const Accordion: FC<AccordionProps> = ({ children, className, value, defaultValue, onValueChange }) => (
    <RadixAccordion.Root
        type="multiple"
        className={cn("w-full", className)}
        value={value}
        defaultValue={defaultValue}
        onValueChange={onValueChange}
    >
        {children}
    </RadixAccordion.Root>
);

Accordion.displayName = RadixAccordion.Root.displayName;
