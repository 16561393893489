import type { Action } from "redux";

import { listenerMiddleware } from "src/listenerMiddleware";
import type { AppListenerEffect } from "src/types";
import { isNotNullish, isNumber } from "src/utils";
import {
    hideNotification,
    showErrorNotification,
    showInfoNotification,
    showNotification,
    type ShowNotificationActions,
    showSuccessNotification,
    showWarningNotification,
} from "./actions";
import { DEFAULT_AUTO_CLOSE_MS } from "./constants";
import { isNotificationShown } from "./selectors";

export const registerListeners = () => {
    listenerMiddleware.startListening({
        matcher: showNotificationMatcher,
        effect: autoHideEffect,
    });
};

export const autoHideEffect: AppListenerEffect<ShowNotificationActions> = async (action, listenerApi) => {
    const { autoCloseSeconds, autoCloseDisabled } = action.payload;
    const { dispatch, cancelActiveListeners, delay, getState } = listenerApi;

    // Only one notification can be visible. If another notification replaces previous one, then previous "timer" is also replaced.
    cancelActiveListeners();

    if (autoCloseDisabled) {
        return;
    }
    if (isNumber(autoCloseSeconds) && autoCloseSeconds <= 0) {
        throw new Error("Auto close seconds must be positive number.");
    }
    const closeDelay = isNotNullish(autoCloseSeconds) ? autoCloseSeconds * 1000 : DEFAULT_AUTO_CLOSE_MS;
    await delay(closeDelay);
    const isShown = isNotificationShown(getState());
    if (isShown) {
        dispatch(hideNotification());
    }
};

const showNotificationMatcher = (action: Action): action is ShowNotificationActions =>
    showNotification.match(action) ||
    showSuccessNotification.match(action) ||
    showInfoNotification.match(action) ||
    showWarningNotification.match(action) ||
    showErrorNotification.match(action);
