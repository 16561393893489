import { Fragment } from "react";

import { typeCheckAppModule } from "src/utils";
import { NAME } from "./constants";
import { registerListeners } from "./listeners";
import { reducer } from "./reducer";

export {
    hideNotification,
    showErrorNotification,
    showInfoNotification,
    showNotification,
    showSuccessNotification,
    showWarningNotification,
} from "./actions";
export { DEFAULT_AUTO_CLOSE_MS } from "./constants";
export { NotificationType } from "./types";

export const notificationsModule = typeCheckAppModule({ NAME, reducer, Container: Fragment });

registerListeners();
