import { Root } from "@radix-ui/react-radio-group";
import type { ReactNode } from "react";
import { forwardRef } from "react";
import type { FieldError } from "react-hook-form";

import { InfoIcon } from "src/icons";
import { cn } from "src/utils";
import { IconWithPopover } from "../IconWithPopover";
import { getErrorMessage } from "../utils";

type RadioGroupProps = Readonly<{
    children: ReactNode;
    value?: string;
    onValueChange?: (value: string) => void;
    label?: string;
    disabled?: boolean;
    error?: string | FieldError;
    additionalInfo?: string;
    orientation?: "horizontal" | "vertical";
    className?: string;
}>;

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
    ({ children, value, onValueChange, label, disabled, error, additionalInfo, orientation = "horizontal", className }, ref) => (
        <div ref={ref} className="flex w-full flex-col space-y-2">
            <div className="flex items-center gap-x-1">
                {label && <label className={cn("!mb-0 text-sm", disabled ? "text-disabled" : "text-primary")}>{label}</label>}
                {additionalInfo && <IconWithPopover icon={<InfoIcon className="size-4 fill-primary" />}>{additionalInfo}</IconWithPopover>}
            </div>
            <Root
                value={value}
                onValueChange={onValueChange}
                orientation={orientation}
                className={cn("flex", orientation === "horizontal" ? "flex-row" : "flex-col", className)}
            >
                {children}
            </Root>
            {error && <span className={cn("text-sm text-error")}>{getErrorMessage(error)}</span>}
        </div>
    ),
);

RadioGroup.displayName = "RadioGroup";
