import { cva, type VariantProps } from "class-variance-authority";
import type { FC, HTMLAttributes } from "react";
import { Link as RouterLink } from "react-router-dom";

import { cn } from "src/utils";

const linkStyles = cva(
    "inline-flex fill-brand-link text-link transition-colors duration-200 ease-in-out hover:fill-brand hover:text-link-hover",
    {
        variants: {
            size: {
                default: "text-sm",
                large: "text-lg",
                compact: "text-xs",
            },
        },
        defaultVariants: {
            size: "default",
        },
    },
);

type LinkProps = Readonly<{
    href: string;
    isExternal?: boolean;
    disabled?: boolean;
    newTab?: boolean;
}> &
    HTMLAttributes<HTMLAnchorElement> &
    VariantProps<typeof linkStyles>;

export const Link: FC<LinkProps> = ({ className, size, children, href, isExternal, disabled, newTab, ...props }) =>
    isExternal ? (
        <a
            {...(newTab ? { target: "_blank", rel: "noopener noreferrer" } : {})} // Conditionally add target and rel attributes
            href={disabled ? undefined : href}
            className={cn(linkStyles({ size }), disabled && "pointer-events-none fill-brand-disabled text-brand-disabled", className)}
            {...props}
        >
            {children}
        </a>
    ) : (
        <RouterLink
            to={href}
            className={cn(linkStyles({ size }), disabled && "pointer-events-none fill-brand-disabled text-brand-disabled", className)}
            {...props}
        >
            {children}
        </RouterLink>
    );
