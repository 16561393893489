import type { FC } from "react";

import { type LocKey, useTranslation } from "src/translations";
import { cn } from "src/utils";

type NoContentProps = Readonly<{
    transKey: LocKey;
    className?: string;
}>;

export const NoContent: FC<NoContentProps> = ({ transKey, className }) => {
    const { t } = useTranslation();
    return <p className={cn("py-4 text-center text-sm text-secondary", className)}>{t(transKey)}</p>;
};
