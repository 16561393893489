import type { FC } from "react";

import { useTranslation } from "src/translations";
import type { ConfirmParams } from "../types";

export type ConfirmDialogProps = Readonly<{
    opened: boolean;
    onClose: () => void;
    onConfirm: () => void;
    params: ConfirmParams | null;
}>;

// todo-quickstart - This is just example component, replace this with your own modal component if needed.
export const ConfirmDialog: FC<ConfirmDialogProps> = ({ opened, onClose, onConfirm, params }) => {
    const { t } = useTranslation();
    return opened ? (
        <div className="absolute top-24 flex w-full justify-center">
            <div className="w-1/3 rounded-sm border-solid border-black p-3">
                {params?.title && <h3>{params.title}</h3>}
                {params?.content && <p>{params.content}</p>}
                <div className="flex justify-between">
                    <button type="button" onClick={onClose}>
                        {t("core.confirm.close")}
                    </button>
                    <button type="button" onClick={onConfirm}>
                        {t("core.confirm.confirm")}
                    </button>
                </div>
            </div>
        </div>
    ) : null;
};
