import { createReducer } from "@reduxjs/toolkit";
import type { Reducer } from "redux";

import {
    hideNotification,
    showErrorNotification,
    showInfoNotification,
    showNotification,
    showSuccessNotification,
    showWarningNotification,
} from "./actions";
import type { NotificationsState } from "./types";
import { NotificationType } from "./types";

export const initialState: NotificationsState = {
    show: false,
    title: null,
    titleKey: null,
    text: null,
    textKey: null,
    type: null,
    isCloseable: true,
};

export const reducer: Reducer<NotificationsState> = createReducer(initialState, (builder) => {
    builder.addCase(showNotification, (_draft, action) => ({
        ...initialState,
        show: true,
        ...action.payload,
    }));
    builder.addCase(showSuccessNotification, (_draft, action) => ({
        ...initialState,
        show: true,
        type: NotificationType.SUCCESS,
        titleKey: "core.notifications.success.title",
        ...action.payload,
    }));
    builder.addCase(showInfoNotification, (_draft, action) => ({
        ...initialState,
        show: true,
        type: NotificationType.INFO,
        titleKey: "core.notifications.info.title",
        ...action.payload,
    }));
    builder.addCase(showWarningNotification, (_draft, action) => ({
        ...initialState,
        show: true,
        type: NotificationType.WARNING,
        titleKey: "core.notifications.warning.title",
        ...action.payload,
    }));
    builder.addCase(showErrorNotification, (_draft, action) => ({
        ...initialState,
        show: true,
        type: NotificationType.ERROR,
        titleKey: "core.notifications.error.title",
        ...action.payload,
    }));
    builder.addCase(hideNotification, () => initialState);
});
