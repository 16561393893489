import type { FC } from "react";

import { cn } from "src/utils";
import { LanguageSelector } from "../../LanguageSelector";
import { Logo } from "../Logo";
import { NavigationMenuMobile } from "./NavigationMenuMobile";

type TopBarProps = Readonly<{
    className?: string;
    withMobileNavigation?: boolean;
}>;

export const TopBar: FC<TopBarProps> = ({ className, withMobileNavigation = true }) => (
    <div className={cn("flex w-full items-center justify-between border-b px-5 py-3", className)}>
        <Logo className="h-[24px] sm:h-[28px]" />
        <div className="flex flex-1 items-center justify-end gap-4">
            <LanguageSelector className="justify-self-end" position="right" />
            {withMobileNavigation && <NavigationMenuMobile />}
        </div>
    </div>
);
