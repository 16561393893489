import type { TFunction } from "i18next";
import type { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { TextWithIcon } from "src/components";
import { BuiltIcon, FloorIcon, LayoutIcon, MaterialIcon, RatingIcon, RulerIcon } from "src/icons";
import { useTranslation } from "src/translations";
import { type Project, ProjectInfoStateEnum, ProjectLayoutComponentMaterialEnum } from "src/types";
import { isNotNullish, useFormatter, useLocalDate } from "src/utils";

type ProjectDetailHeaderProps = Readonly<{
    loading: boolean;
    projectState: ProjectInfoStateEnum;
    completedOn: Project["completedOn"];
    layout: Project["layout"];
}>;

export const ProjectDetailHeader: FC<ProjectDetailHeaderProps> = ({ loading, layout, completedOn }) => {
    const { t } = useTranslation();
    const toLocalDate = useLocalDate();
    const { formatNumberWithSuffix } = useFormatter();

    const typeLabel = mapProjectLayoutMaterialLabel(t, layout?.material);

    return (
        <div className="grid w-full gap-6">
            <div className="hidden flex-wrap items-center gap-6 fill-primary text-primary lg:flex">
                {loading &&
                    Array(6).map((_) => (
                        <div>
                            <Skeleton width={24} height={24} borderRadius={24} />
                            <Skeleton width={140} height={16} />
                        </div>
                    ))}
                {!loading && (
                    <>
                        <TextWithIcon
                            icon={<LayoutIcon className="size-6" />}
                            text={`${layout?.bedrooms} ${t("ProjectDetail.Room.NumOfBedrooms")}, ${layout?.bathrooms} ${t("ProjectDetail.Room.NumOfBathrooms")}`}
                        />

                        <TextWithIcon icon={<RulerIcon className="size-6" />} text={`${layout?.size} m2`} />

                        {completedOn && (
                            <TextWithIcon
                                icon={<BuiltIcon className="size-6" />}
                                text={`${t("ProjectDetail.CompletedOn")} ${toLocalDate(new Date(completedOn))}`}
                            />
                        )}

                        {isNotNullish(layout?.floor) && (
                            <TextWithIcon
                                icon={<FloorIcon className="size-6" />}
                                text={
                                    layout.floor === 0
                                        ? t("ProjectDetail.GroundFloor")
                                        : `${formatNumberWithSuffix(layout.floor)} ${t("ProjectDetail.Floor")}`
                                }
                            />
                        )}

                        {typeLabel && <TextWithIcon icon={<MaterialIcon className="size-6" />} text={typeLabel} />}

                        <TextWithIcon
                            icon={<RatingIcon className="size-6" />}
                            text={`${layout?.energyClass} ${t("ProjectDetail.Rating")}`}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

const mapProjectLayoutMaterialLabel = (t: TFunction, type?: ProjectLayoutComponentMaterialEnum) => {
    switch (type) {
        case ProjectLayoutComponentMaterialEnum.Brick:
            return t("ProjectDetail.MaterialType.Brick");
        case ProjectLayoutComponentMaterialEnum.Panel:
            return t("ProjectDetail.MaterialType.Panel");
        case ProjectLayoutComponentMaterialEnum.Wood:
            return t("ProjectDetail.MaterialType.Wood");
        default:
            return "";
    }
};
