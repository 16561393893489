import { Routes } from "src/routes";
import { typeCheckAppModule } from "src/utils";
import { NAME } from "./constants";
import { VerificationPage } from "./VerificationPage";

export const verification = typeCheckAppModule({
    NAME,
    Container: VerificationPage,
    route: Routes.verifications,
});
