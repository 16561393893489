import { useEffect } from "react";

import { UserProfileLanguageEnum1 } from "src/types";
import { useAppSelector } from "src/utils";
import { getOptionalUser, useUpdateUserProfileMutation } from "../serverApi";
import { useTranslation } from "./utils";

export const useSaveLanguage = () => {
    const { i18n } = useTranslation();

    const [updateProfile] = useUpdateUserProfileMutation();
    const optionalUser = useAppSelector(getOptionalUser);

    useEffect(() => {
        i18n.on("languageChanged", (language) => {
            if (optionalUser && optionalUser.userProfile?.documentId && language) {
                updateProfile({
                    profileDocumentId: optionalUser.userProfile.documentId,
                    data: { language: language as UserProfileLanguageEnum1 },
                });
            }
        });
        return () => {
            i18n.off("languageChanged");
        };
    }, [i18n, optionalUser, updateProfile]);
};
