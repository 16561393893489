import {
    OrderCurrencyEnum,
    OrderCurrencyEnum1,
    OrderCurrencyEnum2,
    OrderCurrencyEnum3,
    ProjectCurrencyEnum,
    ProjectInfoCurrencyEnum,
    UserProfileCurrencyEnum,
    UserProfileCurrencyEnum1,
} from "./generated";

// HACK: This is a workaround to Strapi's duplicate enum values. Do not repeat this pattern in your code.
// If you need additional enums, add them to the array below.
const currencyEnums = [
    ProjectCurrencyEnum,
    OrderCurrencyEnum,
    OrderCurrencyEnum1,
    OrderCurrencyEnum2,
    OrderCurrencyEnum3,
    UserProfileCurrencyEnum,
    UserProfileCurrencyEnum1,
    ProjectInfoCurrencyEnum,
] as const;

export type Currency = (typeof currencyEnums)[number][keyof (typeof currencyEnums)[number]];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Currency = currencyEnums.reduce((acc, currEnum) => ({ ...acc, ...currEnum }), {}) as {
    [K in keyof (typeof currencyEnums)[number]]: Currency;
};

// Usage
// let currency: Currency;
//
// currency = ProjectCurrencyEnum.CZK; // OK
// currency = OrderCurrencyEnum.EUR; // OK
// currency = Currency.CZK; // OK
// currency = "EUR"; // NOK
