import { Currency, type Project } from "src/types";

/**
 * Format parts types as specified in https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/formatToParts#description
 */
export enum FormatPartsType {
    currency = "currency",
    decimal = "decimal",
    fraction = "fraction",
    group = "group",
    infinity = "infinity",
    integer = "integer",
    literal = "literal",
    minusSign = "minusSign",
    nan = "nan",
    plusSign = "plusSign",
    percentSign = "percentSign",
    unit = "unit",
}

export type NumberSeparators = Readonly<{ decimalSeparator: string; thousandSeparator: string }>;

const separatorCache: Record<string, NumberSeparators> = {};

const getFormatPartValueByType = (parts: ReadonlyArray<Intl.NumberFormatPart>, type: FormatPartsType): string => {
    const match = parts.find((part) => part.type === type);
    if (match) {
        return match.value;
    }
    throw new Error(`Format parts does not contain type ${type}`);
};

export const getNumberSeparators = (locale: string): NumberSeparators => {
    if (!separatorCache[locale]) {
        const parts = new Intl.NumberFormat(locale).formatToParts(1000.666); // This could be any number as long as its formatted result contains both group separator and decimal separator.
        separatorCache[locale] = {
            decimalSeparator: getFormatPartValueByType(parts, FormatPartsType.decimal),
            thousandSeparator: getFormatPartValueByType(parts, FormatPartsType.group),
        };
    }
    return separatorCache[locale];
};

export const convertPriceToTargetCurrency = (
    priceInCents: number,
    priceCurrency: Currency,
    targetCurrency: Currency,
    conversionRateEurToCzk: number,
): number => {
    // We are multiplying price in BE by 100 to avoid floating point arithmetic issues.
    const price = convertPriceInCentsToPrice(priceInCents);
    if (priceCurrency === targetCurrency) {
        return price;
    }

    if (priceCurrency === Currency.EUR) {
        return price * conversionRateEurToCzk;
    }

    if (priceCurrency === Currency.CZK) {
        return price / conversionRateEurToCzk;
    }

    throw new Error(`Unsupported conversion from ${priceCurrency} to ${targetCurrency}`);
};

export const getCurrentNominalPrice = (tokenCurrentNominalPrice: Project["tokenCurrentNominalPrice"]): number | undefined => {
    if (!tokenCurrentNominalPrice || tokenCurrentNominalPrice.length === 0) {
        return undefined;
    }
    const sortedTokenCurrentNominalPrice = [...tokenCurrentNominalPrice].sort(
        (a, b) => new Date(a.appliedAt!).getTime() - new Date(b.appliedAt!).getTime(),
    );
    const today = new Date();
    return sortedTokenCurrentNominalPrice.reduce((acc, current) => {
        const appliedAt = new Date(current.appliedAt!);
        if (appliedAt > today) {
            return acc;
        }
        return current;
    }, sortedTokenCurrentNominalPrice[0]).price;
};

export const convertPriceInCentsToPrice = (priceInCents?: number): number => (priceInCents ? priceInCents / 100 : 0);
