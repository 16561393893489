import type { FC } from "react";

import { useTranslation } from "src/translations";
import { Heading } from "./Heading";
import { Link } from "./Link";
import { Loading } from "./Loading";

type CheckingProps = Readonly<{
    title: string;
    description?: string;
    linkToStopChecking: string;
}>;

export const Checking: FC<CheckingProps> = ({ title, linkToStopChecking, description }) => {
    const { t } = useTranslation();

    return (
        <div className="grid gap-8">
            <div>
                <Heading level={3} className="text-center">
                    {title}
                </Heading>
                {description && <p className="mt-1 text-center text-sm">{description}</p>}
            </div>
            <div className="grid gap-4">
                <Loading />
                <Link className="block text-center" href={linkToStopChecking}>
                    {t("Checking.Stop")}
                </Link>
            </div>
        </div>
    );
};
