import { intlFormat } from "date-fns";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

// TODO: this could be done in i18n.ts interpolation -> format function

const NUMERIC_DATE_FORMAT: Intl.DateTimeFormatOptions = {
    day: "numeric",
    year: "numeric",
    month: "numeric",
} as const;

/**
 * Usage:
 * const toLocalDate = useLocalDate();
 *
 * @returns a function that accepts the date as string, number, undefined, or Date format and returns the local datestring
 * @param {string | number | undefined | Date} unixTime - The date to be converted to a local datestring
 */
export function useLocalDate(): (unixTime: string | number | undefined | Date) => string {
    const { i18n } = useTranslation();
    const locale = i18n.language;

    return useCallback(
        (unixTime: string | number | undefined | Date) => {
            if (!unixTime) return "";
            const date = typeof unixTime === "string" ? parseInt(unixTime, 10) : unixTime;

            return intlFormat(date ?? 0, NUMERIC_DATE_FORMAT, { locale });
        },
        [locale],
    );
}
