import type { RequireExactlyOne } from "type-fest";

import type { LocKey } from "src/translations";

export type NotificationsState = Readonly<{
    show: boolean;
    title: string | null;
    titleKey: LocKey | null;
    text: string | null;
    textKey: LocKey | null;
    type: NotificationType | null;
    isCloseable: boolean;
}>;

export enum NotificationType {
    SUCCESS = "success",
    INFO = "info",
    WARNING = "warning",
    ERROR = "error",
}

export type OptionalNotificationConfig = Readonly<{
    /**
     * If set to true, then notification displays close button, so user could close notification manually.
     * If not defined, then close button is displayed.
     */
    isCloseable?: boolean;
    /**
     * Number of seconds after which notification is automatically closed. Must be positive number or undefined.
     * If not defined, then DEFAULT_AUTO_CLOSE_MS is used. If autoCloseDisabled is true, then autoCloseSeconds has no effect.
     */
    autoCloseSeconds?: number;
    /**
     * If set to true, then notification is not closed automatically. It must be closed either manually by user or programmatically by hideNotification action.
     * If not defined, then auto close is enabled.
     */
    autoCloseDisabled?: boolean;
}>;

export type ShowActionPayload = Readonly<
    RequireExactlyOne<
        RequireExactlyOne<
            {
                type: NotificationType;
                title: string;
                titleKey: LocKey;
                text: string;
                textKey: LocKey;
            } & OptionalNotificationConfig,
            "title" | "titleKey"
        >,
        "text" | "textKey"
    >
>;

export type PredefinedShowActionPayload = Readonly<
    RequireExactlyOne<
        {
            text: string;
            textKey: LocKey;
        } & OptionalNotificationConfig,
        "text" | "textKey"
    >
>;
