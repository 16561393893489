import type { FC } from "react";
import { Link } from "react-router-dom";

import { Button, Heading } from "src/components";
import { Routes } from "src/routes";
import { useTranslation } from "src/translations";
import { type ProjectInfo, ProjectInfoStateEnum } from "src/types";
import { logGtmEvent } from "src/utils";
import { CTA, ProjectBadges } from "../../components";
import { logger } from "../logger";
import { getImageSrcSet } from "../utils";
import { ProjectCardMetrics } from "./ProjectCardMetrics";

type ProjectCardProps = {
    projectInfo: ProjectInfo;
};

export const ProjectCard: FC<ProjectCardProps> = ({ projectInfo }) => {
    const { t } = useTranslation();

    const { project } = projectInfo;

    if (!project) return null;

    const { documentId, images, onlyForCurrentInvestor, countryCode, state } = project;
    const image = images?.[0];
    const projectState = state ?? ProjectInfoStateEnum.Unknown;

    return (
        <article className="flex max-lg:flex-col">
            {image && (
                <div className="relative aspect-3/2 w-full overflow-hidden rounded-t-lg lg:max-w-[550px] lg:rounded-l-lg lg:rounded-r-none">
                    <img
                        className="absolute inset-0 size-full object-cover"
                        src={image.url}
                        srcSet={getImageSrcSet(image.formats, image.url, image.width)}
                        sizes="(min-width:1024px) 550px, (min-width:800px) 900px, (min-width:640px) 80vw, 100vw"
                        alt={image.alternativeText || image.caption || "Project image"}
                    />
                </div>
            )}

            <div className="flex-1 space-y-6 rounded-b-lg border p-4 py-6 md:space-y-8 md:rounded-r-lg md:rounded-bl-none md:p-8">
                <Heading level={3} className="text-[30px] font-light">
                    {projectInfo?.title}
                </Heading>
                <ProjectBadges projectState={projectState} countryCode={countryCode} />
                <ProjectCardMetrics projectState={projectState} {...project} />
                <div className="mt-auto flex items-center justify-end gap-7 max-xl:flex-col max-xl:gap-4">
                    <CTA
                        className="w-full max-w-[280px] max-sm:max-w-full"
                        projectSlug={projectInfo?.slug!}
                        onlyForCurrentInvestor={onlyForCurrentInvestor}
                        projectDocumentId={documentId!}
                        logger={logger}
                        loading={false}
                    />
                    <Link
                        className="w-full max-w-[280px] max-sm:max-w-full"
                        onClick={() => {
                            logGtmEvent({ event: "property_interest", propertyId: documentId! });
                        }}
                        to={Routes.projectDetail.fillPathParams({ projectSlug: projectInfo?.slug! })}
                    >
                        <Button kind="outline" fullWidth>
                            {t("ProjectListPageCard.Detail")}
                        </Button>
                    </Link>
                </div>
            </div>
        </article>
    );
};
