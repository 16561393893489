import type { FC, HTMLAttributes } from "react";

import { getAppConfig } from "src/config";
import { Routes } from "src/routes";
import { getCmsUrl } from "src/serverApi";
import { useAppSelector } from "src/utils";
import { Link } from "../Link";

type LogoProps = HTMLAttributes<HTMLImageElement>;

export const Logo: FC<LogoProps> = (props) => {
    const config = useAppSelector(getAppConfig);
    return (
        config && (
            <Link href={Routes.projects.path}>
                <img src={getCmsUrl(config.image?.url)} alt={config.image?.alternativeText} title={config.title} {...props} />
            </Link>
        )
    );
};
