import type { FC } from "react";

import { isCmsErrorResponseWithMessage } from "src/serverApi";
import { cn } from "src/utils";

type CmsErrorProps = Readonly<{
    error: unknown;
    fallbackMessage: string;
    className?: string;
}>;

export const CmsError: FC<CmsErrorProps> = ({ error, fallbackMessage, className }) => (
    <div className={cn("bg-brand-error/20 p-2.5 text-center text-error", className)}>
        {isCmsErrorResponseWithMessage(error) ? error.data.error.message : fallbackMessage}
    </div>
);
