import { Indicator, Root } from "@radix-ui/react-checkbox";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { CheckIcon } from "src/icons";
import type { WithClassName } from "src/types";
import { cn } from "src/utils";

type ControlledFormCheckboxProps = WithClassName<ComponentPropsWithoutRef<typeof Root>>;

export const ControlledFormCheckbox = forwardRef<ElementRef<typeof Root>, ControlledFormCheckboxProps>(({ className, ...props }, ref) => (
    <Root
        ref={ref}
        className={cn(
            "peer size-[1.125rem] shrink-0 rounded-[0.3125rem] border border-tertiary bg-white transition-colors duration-200 ease-in-out hover:!bg-subtle focus-visible:outline-none disabled:cursor-not-allowed data-[state=checked]:!border-brand data-[state=checked]:!bg-brand data-[state=checked]:!fill-white",
            className,
        )}
        {...props}
    >
        <Indicator className="flex items-center justify-center text-current">
            <CheckIcon className="size-3" />
        </Indicator>
    </Root>
));
ControlledFormCheckbox.displayName = Root.displayName;
