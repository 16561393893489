import type { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { UserVerificationTypeEnum } from "src/types";
import { useUserVerification } from "src/verification";
import { VerificationStepperItem } from "./VerificationStepperItem";

type StepperProps = Readonly<{
    activeVerificationType: UserVerificationTypeEnum;
}>;

export const VerificationStepper: FC<StepperProps> = ({ activeVerificationType }) => {
    const { isVerificationLoading, verificationOptions, isVerificationDone } = useUserVerification();

    if (isVerificationLoading) return <Skeleton width="100%" height="32px" />;

    return (
        <ul id="stepper" className="flex w-full list-none p-0 text-center text-xs">
            {verificationOptions.map((verificationOption) => (
                <VerificationStepperItem
                    key={verificationOption.id}
                    active={verificationOption?.type === activeVerificationType}
                    done={isVerificationDone(verificationOption?.type)}
                    maxSteps={verificationOptions.length}
                    step={verificationOption?.relevance ?? 0}
                    text={verificationOption?.name ?? ""}
                />
            ))}
        </ul>
    );
};
