import type { FC } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

import { LogoutIcon } from "src/icons";
import { Routes } from "src/routes";
import { removeAuthToken, rootApi, useUserQuery } from "src/serverApi";
import { useAppDispatch } from "src/utils";
import { Avatar } from "../../Avatar";

export const UserSection: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { data: user, isLoading } = useUserQuery();

    let displayName = user?.username || user?.email;
    if (user?.userProfile?.firstName && user?.userProfile?.lastName) {
        displayName = `${user.userProfile.firstName.trim()} ${user.userProfile.lastName.trim()}`;
    }

    const onLogoutClick = () => {
        removeAuthToken();
        dispatch(rootApi.util.resetApiState());
        navigate(Routes.signIn.path);
    };

    return (
        <div className="flex cursor-default items-center justify-between gap-2.5 p-6 sm:justify-start">
            {isLoading ? (
                <>
                    <Skeleton inline height={24} width={24} circle />
                    <Skeleton containerClassName="grow" height={24} />
                </>
            ) : (
                <>
                    <Avatar userName={displayName} />
                    <span className="grow truncate font-inter font-medium">{displayName}</span>
                </>
            )}
            <button className="ml-auto" type="button" aria-label="Logout" onClick={onLogoutClick}>
                <LogoutIcon className="size-6 fill-secondary transition-colors duration-300 hover:fill-brand" />
            </button>
        </div>
    );
};
