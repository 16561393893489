import type { FC, ReactElement } from "react";

import { RequestError } from "src/errorHandling";
import { useSaveLanguage } from "src/translations";
import { useAppConfigQuery } from "../serverApi";

export type ConfigLoaderProps = Readonly<{
    children: ReactElement;
}>;

export const ConfigLoader: FC<ConfigLoaderProps> = ({ children }) => {
    useSaveLanguage();
    const { isLoading, isError, error } = useAppConfigQuery();

    if (isError) {
        throw new RequestError("Cannot load application config", error);
    }

    return isLoading ? null : children;
};
