import { Item, ItemText } from "@radix-ui/react-select";
import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";

import { cn } from "src/utils";

export const SelectItem = forwardRef<ElementRef<typeof Item>, ComponentPropsWithoutRef<typeof Item>>(
    ({ className, children, ...props }, ref) => (
        <Item
            ref={ref}
            className={cn("relative flex w-full cursor-default select-none items-center py-1.5 pl-8 pr-2", className)}
            {...props}
        >
            <ItemText>{children}</ItemText>
        </Item>
    ),
);
SelectItem.displayName = Item.displayName;
