import type { FC } from "react";

import { PublicLayout } from "src/components";
import { ForgotPasswordForm } from "./ForgotPasswordForm";

export const ForgotPasswordPage: FC = () => {
    return (
        <PublicLayout>
            <ForgotPasswordForm />
        </PublicLayout>
    );
};
