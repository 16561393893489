import { cva, type VariantProps } from "class-variance-authority";
import { forwardRef, type HTMLAttributes } from "react";

import { cn } from "src/utils";
import { Spinner } from "../Spinner";

const buttonStyles = cva(
    "flex justify-center transition-colors duration-200 ease-in-out focus:outline focus:outline-4 focus:outline-brand-link/20",
    {
        variants: {
            size: {
                default: "px-3 py-2.5 text-base leading-[1.6rem]",
                large: "px-5 py-[0.4375rem] text-lg leading-[1.85625rem]",
                compact: "px-3 py-1.5 text-sm leading-[1.26875rem]",
            },
            kind: {
                primary:
                    "border border-brand bg-brand text-white hover:border-brand-secondary hover:bg-brand-secondary disabled:border-disabled disabled:bg-disabled",
                danger: "border border-red-700 bg-red-700 text-white hover:border-red-500 hover:bg-red-500 disabled:bg-secondary disabled:text-disabled",
                outline:
                    "border-2 border-brand text-brand hover:border-brand-secondary hover:bg-brand-secondary hover:text-white focus:outline-sky-100 disabled:border-disabled disabled:text-disabled",
            },
        },
        defaultVariants: {
            size: "default",
            kind: "primary",
        },
    },
);

type ButtonProps = Readonly<{
    fullWidth?: boolean;
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
    isLoading?: boolean;
}> &
    HTMLAttributes<HTMLButtonElement> &
    VariantProps<typeof buttonStyles>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ kind, size, children, className, fullWidth, disabled, type = "button", isLoading = false, ...props }, ref) => (
        <button
            ref={ref}
            className={cn(buttonStyles({ kind, size }), fullWidth ? "w-full" : "inline-flex", className)}
            {...props}
            disabled={isLoading || disabled}
            // Union types are not considered as static values
            // eslint-disable-next-line react/button-has-type
            type={type}
        >
            {isLoading ? <Spinner /> : children}
        </button>
    ),
);
