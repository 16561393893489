import { Routes } from "src/routes";
import { typeCheckAppModule } from "src/utils";
import { NAME } from "./constants";
import { ProjectsPage } from "./ProjectsPage";

export const projects = typeCheckAppModule({
    NAME,
    Container: ProjectsPage,
    route: Routes.projects,
});
