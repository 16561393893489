import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Heading } from "src/components";
import { logGtmEvent, useComponentDidMount } from "src/utils";

type SignUpSuccessProps = Readonly<{
    email: string;
}>;

export const SignUpSuccess: FC<SignUpSuccessProps> = ({ email }) => {
    const { t } = useTranslation();

    useComponentDidMount(() => {
        logGtmEvent({ event: "user_email_verified" }, false);
    });

    return (
        <div className="w-full space-y-6">
            <Heading level={2}>{t("RegistrationSuccess.card.header")}</Heading>
            <div className="space-y-6">
                <p>{t("RegistrationSuccess.message.email", { email })}</p>
                <p>{t("RegistrationSuccess.message.verification")}</p>
            </div>
        </div>
    );
};
