import type { FC } from "react";

import { Link, TableCell, TableRow } from "src/components";
import { RightArrowIcon } from "src/icons";
import { Routes } from "src/routes";
import { CurrencyFormatter } from "src/translations";
import type { Order } from "src/types";
import { convertPriceInCentsToPrice, getValOrThrowError, useLocalDate } from "src/utils";
import { OrderMenu } from "./OrderMenu";
import { OrderStatus } from "./OrderStatus";

type OrderItemProps = Readonly<{
    order: Order;
}>;

export const OrderItem: FC<OrderItemProps> = ({ order }) => {
    const toLocalDate = useLocalDate();

    const { project, createdAt, price, state, tokenCount, documentId, currency, id } = order;

    const createdAtDate = new Date(getValOrThrowError(createdAt, "Missing createdAt date for order"));

    // TODO: tablet/mobile view

    return (
        <TableRow className="font-inter text-base text-primary [&>td]:px-3 [&>td]:py-1.5 [&>td]:first:pt-3">
            <TableCell className="whitespace-nowrap pl-0">
                # {`${createdAtDate.getFullYear()}${createdAtDate.getMonth()}${createdAtDate.getDay()}${project?.id}${id}`}
            </TableCell>
            <TableCell>
                {project?.projectInfo?.slug && (
                    <Link href={Routes.projectDetail.fillPathParams({ projectSlug: project.projectInfo?.slug })}>
                        <div className="flex items-center fill-brand text-brand">
                            <span className="font-medium">{project.projectInfo?.title}</span>
                            <RightArrowIcon className="ml-2 size-5 shrink-0" />
                        </div>
                    </Link>
                )}
            </TableCell>
            <TableCell className="text-center">{toLocalDate(createdAtDate)}</TableCell>
            <TableCell className="text-center">{tokenCount}</TableCell>
            <TableCell className="text-right">
                <CurrencyFormatter value={convertPriceInCentsToPrice(price)} currency={getValOrThrowError(currency)} />
            </TableCell>
            <TableCell>
                <OrderStatus state={state!} />
            </TableCell>
            <TableCell className="pr-0 text-right">
                <OrderMenu documentId={getValOrThrowError(documentId, "Missing document id for order")} state={state!} />
            </TableCell>
        </TableRow>
    );
};
