import type { FC } from "react";

import { useTranslation } from "src/translations";
import { convertPriceInCentsToPrice } from "src/utils";
import { formatCurrency } from "../utils";

type DashboardNetWorthProps = Readonly<{
    netWorth: number;
    currencySymbol: string;
}>;

export const DashboardNetWorth: FC<DashboardNetWorthProps> = ({ netWorth, currencySymbol }) => {
    const { t } = useTranslation();

    return (
        <div className="space-y-5 font-inter">
            <span>{t("DashboardPage.NetWorth.Title")}</span>
            <div>
                <span className="text-6xl">{formatCurrency(convertPriceInCentsToPrice(netWorth))}</span>
                <span className="text-xl">{currencySymbol}</span>
            </div>
        </div>
    );
};
