import type { HTMLAttributes, TdHTMLAttributes, ThHTMLAttributes } from "react";
import { forwardRef } from "react";

import type { WithClassName } from "src/types";
import { cn } from "src/utils";

const Table = forwardRef<HTMLTableElement, WithClassName<HTMLAttributes<HTMLTableElement>>>(({ className, ...props }, ref) => (
    <div className="w-full overflow-auto">
        <table ref={ref} className={cn("w-full caption-bottom text-sm", className)} {...props} />
    </div>
));
Table.displayName = "Table";

const TableHeader = forwardRef<HTMLTableSectionElement, WithClassName<HTMLAttributes<HTMLTableSectionElement>>>(
    ({ className, ...props }, ref) => <thead ref={ref} className={cn("", className)} {...props} />,
);
TableHeader.displayName = "TableHeader";

const TableBody = forwardRef<HTMLTableSectionElement, WithClassName<HTMLAttributes<HTMLTableSectionElement>>>(
    ({ className, ...props }, ref) => <tbody ref={ref} className={cn("[&_tr:last-child]:border-0", className)} {...props} />,
);
TableBody.displayName = "TableBody";

const TableFooter = forwardRef<HTMLTableSectionElement, WithClassName<HTMLAttributes<HTMLTableSectionElement>>>(
    ({ className, ...props }, ref) => <tfoot ref={ref} className={cn("", className)} {...props} />,
);
TableFooter.displayName = "TableFooter";

const TableRow = forwardRef<HTMLTableRowElement, WithClassName<HTMLAttributes<HTMLTableRowElement>>>(({ className, ...props }, ref) => (
    <tr ref={ref} className={cn("", className)} {...props} />
));
TableRow.displayName = "TableRow";

const TableHead = forwardRef<HTMLTableCellElement, WithClassName<ThHTMLAttributes<HTMLTableCellElement>>>(
    ({ className, ...props }, ref) => (
        <th ref={ref} className={cn("text-left align-middle [&:has([role=checkbox])]:pr-0", className)} {...props} />
    ),
);
TableHead.displayName = "TableHead";

const TableCell = forwardRef<HTMLTableCellElement, WithClassName<TdHTMLAttributes<HTMLTableCellElement>>>(
    ({ className, ...props }, ref) => <td ref={ref} className={cn("align-middle [&:has([role=checkbox])]:pr-0", className)} {...props} />,
);
TableCell.displayName = "TableCell";

const TableCaption = forwardRef<HTMLTableCaptionElement, WithClassName<HTMLAttributes<HTMLTableCaptionElement>>>(
    ({ className, ...props }, ref) => <caption ref={ref} className={cn("", className)} {...props} />,
);
TableCaption.displayName = "TableCaption";

export { Table, TableBody, TableCaption, TableCell, TableFooter, TableHead, TableHeader, TableRow };
