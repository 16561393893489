import { Routes } from "src/routes";
import { typeCheckAppModule } from "src/utils";
import { NAME } from "./constants";
import { OrderPayPage } from "./OrderPayPage";

export const orderPay = typeCheckAppModule({
    NAME,
    Container: OrderPayPage,
    route: Routes.orderPay,
});
