import { type EffectCallback, useEffect, useRef, useState } from "react";
import { type TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import type { AppDispatch, AppState, UniversalSelector } from "src/types";

/**
 * Alias for {@link useDispatch} which is typed according to store setup.
 * This allows to dispatch enhanced actions based on used middleware.
 *
 * @see https://react-redux.js.org/using-react-redux/usage-with-typescript#define-typed-hooks
 */
export const useAppDispatch = () => useDispatch<AppDispatch>();

/**
 * Alias for {@link useSelector} which is typed with {@link AppState}.
 * It saves the need to type state every time.
 *
 * @see https://react-redux.js.org/using-react-redux/usage-with-typescript#define-typed-hooks
 */
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

/**
 * Gets value from redux by specified selector. In comparison to {@link useAppSelector} supports parameters for selector
 * without need to write wrapping inline selector. Do not use this hook for selectors which does not need additional parameters.
 * @param selector Selector to use.
 * @param params Additional parameters passed to selector. State is passed automatically as first parameter.
 * @see useAppSelector
 * @template R Return type of selector.
 * @template P Type of additional parameters.
 * @example
 * const entity = useAppSelector((state) => getEntityById(state, id));
 * vs
 * const entity = useAppParamSelector(getEntityById, id);
 */
export const useAppParamSelector = <R, P extends unknown[]>(selector: UniversalSelector<R, P>, ...params: P): R =>
    useAppSelector((state) => selector(state, ...params));

/**
 * Same as {@link useAppParamSelector} but allows to pass configuration to used useSelector. This is handy for cases when you need to adjust equality function or stability check.
 * @param config Config passed to useSelector.
 * @param selector Selector to use.
 * @param params Additional parameters passed to selector. State is passed automatically as first parameter.
 * @see useAppSelector
 * @see useAppParamSelector
 * @template R Return type of selector.
 * @template P Type of additional parameters.
 */
export const useConfigurableAppParamSelector = <R, P extends unknown[]>(
    config: Parameters<typeof useAppSelector>[1],
    selector: UniversalSelector<R, P>,
    ...params: P
): R => useAppSelector((state) => selector(state, ...params), config);

/**
 * Hook, which simulates component did mount behavior. Works exactly same as useEffect with empty dependency array.
 *
 * This hook is defined for semantic purpose - to be evident that dependencies are empty on purpose and not by mistake.
 * This way eslint ignore of react-hooks/exhaustive-deps is kept here in one place and does not pollute component code.
 *
 * @param effect - Effect callback, which should be run only once.
 */
// eslint-disable-next-line react-hooks/exhaustive-deps
export const useComponentDidMount = (effect: EffectCallback) => useEffect(effect, []);

/**
 * Returns previously stored value.
 * @param value current value, stored for next cycle
 * @param defaultValue value returned in first render, null by default
 * @example:
 * usePrevious(1) -> null
 * usePrevious(200) -> 1
 * usePrevious("hello") -> 200
 * usePrevious(null) -> "hello"
 */
export const usePrevious = <ValueType>(value: ValueType, defaultValue?: ValueType) => {
    const ref = useRef<ValueType | null>(defaultValue ?? null);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

/**
 * Provides delayed loading state to prevent flickering of loading indicators.
 * - Loading indicator is not displayed at all if loading is fast enough (less than maximalIgnoredLoadingTime).
 * - When loading is displayed, it is displayed for at least minimalVisibleLoadingTime.
 *
 * Precision of timing is not guaranteed, but it is good enough for user experience.
 *
 * @example
 * const { data, isFetching, isError } = dataGridApi.endpoint.useQuery();
 * const isDelayedLoading = useDelayedLoading(isFetching);
 *
 * if(isDelayedLoading){
 *     return <Loading />;
 * }
 *
 * @param isLoading Loading state indicator from source (usually isFetching from RTK Query hook).
 * @param maximalIgnoredLoadingTime Default value is 200ms.
 * @param minimalVisibleLoadingTime Default value is 750ms.
 */
export const useDelayedLoading = (isLoading: boolean, maximalIgnoredLoadingTime = 200, minimalVisibleLoadingTime = 750) => {
    const [delayedLoading, setDelayedLoading] = useState<boolean>(false);
    const [loadingVisibleFrom, setLoadingVisibleFrom] = useState(0);
    useEffect(() => {
        let timeoutId: number | undefined;
        if (isLoading && !delayedLoading) {
            timeoutId = window.setTimeout(() => {
                setDelayedLoading(true);
                setLoadingVisibleFrom(Date.now());
                timeoutId = undefined;
            }, maximalIgnoredLoadingTime);
        } else if (!isLoading && delayedLoading) {
            const remainingTime = minimalVisibleLoadingTime - (Date.now() - loadingVisibleFrom);
            // 10ms is threshold  for setTimeout, there is no point in setting shorter timeout
            if (remainingTime > 10) {
                timeoutId = window.setTimeout(() => {
                    setDelayedLoading(false);
                    setLoadingVisibleFrom(0);
                    timeoutId = undefined;
                }, remainingTime);
            } else {
                setDelayedLoading(false);
                setLoadingVisibleFrom(0);
            }
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [isLoading, delayedLoading, loadingVisibleFrom, maximalIgnoredLoadingTime, minimalVisibleLoadingTime]);

    return delayedLoading;
};
