import type { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { Trans, useTranslation } from "src/translations";
import { type ProjectCalculationComponent } from "src/types";
import { formatAmount } from "../../../utils";
import { calculateAnnualRentalIncome, calculateRemainderAfterXYears, calculateTotalIncome } from "./utils";

type ReturnOfInvestmentResultsProps = Readonly<
    Pick<ProjectCalculationComponent, "annualRentalIncome" | "incrementalPropertyValue" | "period"> & {
        investmentAmount: number;
        currencySymbol: string;
        loading: boolean;
    }
>;

export const ReturnOfInvestmentResults: FC<ReturnOfInvestmentResultsProps> = ({
    investmentAmount,
    annualRentalIncome = 1,
    incrementalPropertyValue = 1,
    period = 1,
    currencySymbol,
    loading,
}) => {
    const { t } = useTranslation();

    const annualRentalIncomeValue = calculateAnnualRentalIncome(investmentAmount, annualRentalIncome);
    const remainderAfterXYears = calculateRemainderAfterXYears(investmentAmount, incrementalPropertyValue, period);
    const totalIncome = calculateTotalIncome(annualRentalIncomeValue, remainderAfterXYears, period);

    return (
        <div className="flex flex-col items-start justify-start gap-2 self-stretch rounded bg-neutral-100 p-4">
            <div className="flex flex-col items-start justify-start gap-1 self-stretch">
                <div className="items-start justify-start gap-1 self-stretch md:inline-flex">
                    <div className="shrink grow basis-0 text-base font-normal text-black">
                        {t("PropertyDetail.Calculator.AnnualRentIncomeAmount.Label")}
                    </div>
                    <div className="text-base font-bold text-black">
                        {loading ? (
                            <Skeleton width={100} />
                        ) : (
                            t("PropertyDetail.Calculator.AnnualRentIncomeAmount.Value", {
                                currency: currencySymbol,
                                value: formatAmount(annualRentalIncomeValue),
                            })
                        )}
                    </div>
                </div>
                <div className="items-start justify-between self-stretch md:inline-flex">
                    <div className="shrink grow basis-0 text-base font-normal text-black">
                        {t("PropertyDetail.Calculator.RemainderAfterXYears.Label", { years: period })}
                    </div>
                    <div className="text-base font-bold text-black">
                        {loading ? (
                            <Skeleton width={100} />
                        ) : (
                            t("PropertyDetail.Calculator.RemainderAfterXYears.Value", {
                                currency: currencySymbol,
                                value: formatAmount(remainderAfterXYears),
                            })
                        )}
                    </div>
                </div>
            </div>
            <div className="items-center justify-between self-stretch border-b border-neutral-100 md:inline-flex">
                <div className="shrink grow basis-0 whitespace-pre text-base font-medium text-brand">
                    <Trans i18nKey="PropertyDetail.Calculator.TotalNetIncome.Label" />
                </div>
                <div className="text-[26px] font-medium leading-relaxed text-brand md:text-right">
                    {loading ? (
                        <Skeleton width={100} />
                    ) : (
                        t("PropertyDetail.Calculator.TotalNetIncome.Value", {
                            currency: currencySymbol,
                            value: formatAmount(totalIncome),
                        })
                    )}
                </div>
            </div>
        </div>
    );
};
