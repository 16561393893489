import type { FC } from "react";

import { DownloadLink, Heading, OrderLayout, PageLayout } from "src/components";
import { getAppConfig } from "src/config";
import { Routes } from "src/routes";
import { useOrderQuery, useProjectInfoDetailBySlugQuery } from "src/serverApi";
import { CurrencyFormatter, useTranslation } from "src/translations";
import { Currency, OrderStateEnum } from "src/types";
import {
    cleanArray,
    convertPriceInCentsToPrice,
    convertPriceToTargetCurrency,
    getCurrentNominalPrice,
    getValOrThrowError,
    useAppSelector,
} from "src/utils";

export const OrderDetailPage: FC = () => {
    const orderDocumentId = getValOrThrowError(Routes.orderDetail.useParams().orderDocumentId, "Missing order document id");
    const { t } = useTranslation();

    const appConfig = getValOrThrowError(useAppSelector(getAppConfig));
    const { data: orderData, isLoading: isLoadingOrder } = useOrderQuery(orderDocumentId);
    const projectSlug = orderData?.data?.project?.projectInfo?.slug;
    const { data: projectInfo, isLoading: isLoadingProject } = useProjectInfoDetailBySlugQuery(projectSlug!, {
        skip: !projectSlug,
    });

    const order = orderData?.data;
    const project = projectInfo?.project;

    const tokenFee = project?.tokenFee ?? 0;
    const tokenPriceWithoutFee = getCurrentNominalPrice(project?.tokenCurrentNominalPrice);
    const tokenPrice = tokenPriceWithoutFee ? tokenPriceWithoutFee + tokenFee : null;
    const convertedTokenPrice = convertPriceToTargetCurrency(
        tokenPrice ?? 0,
        project?.currency ?? Currency.EUR,
        order?.currency ?? Currency.EUR,
        appConfig.conversionRateEurToCzk,
    );

    const showDocuments = order?.state && [OrderStateEnum.Open, OrderStateEnum.Paid, OrderStateEnum.Unpaid].includes(order.state);
    const documents = cleanArray([appConfig.termsAndConditions, appConfig.whitePaper, projectInfo?.informationProspect]);

    return (
        <PageLayout loading={isLoadingOrder || isLoadingProject}>
            <OrderLayout
                title={t("ViewOrder.Heading.OrderNumber", { id: orderData?.data?.id })}
                projectInfo={projectInfo}
                orderState={order?.state}
            >
                <Heading level={6} className="normal-case">
                    {t("Overview")}
                </Heading>
                <div className="!mb-5 grid grid-cols-2 gap-y-1.5 text-sm">
                    <div>{t("ViewOrder.Overview.SharesBought")}</div>
                    <div className="text-right">{order?.tokenCount}</div>
                    <div>{t("ViewOrder.Overview.PricePerShare")}</div>
                    <div className="text-right">
                        <CurrencyFormatter value={convertedTokenPrice} currency={order?.currency ?? ""} />
                    </div>
                    <div className="font-medium">{t("ViewOrder.Overview.TotalPricePaid")}</div>
                    <div className="text-right font-medium">
                        <CurrencyFormatter value={convertPriceInCentsToPrice(order?.price)} currency={order?.currency ?? ""} />
                    </div>
                </div>

                {showDocuments && (
                    <>
                        <Heading level={6} className="normal-case">
                            {t("ViewOrder.Overview.Documents")}
                        </Heading>
                        <div className="flex flex-col gap-1">
                            {documents.map(
                                ({ id, url, caption }) =>
                                    url && (
                                        <DownloadLink key={id} href={url} className="text-sm">
                                            {caption}
                                        </DownloadLink>
                                    ),
                            )}
                        </div>
                    </>
                )}
            </OrderLayout>
        </PageLayout>
    );
};
