import type { FC } from "react";

import { Link, TableCell, TableRow } from "src/components";
import { ArrowRightIcon } from "src/icons";
import { Routes } from "src/routes";
import { convertPriceInCentsToPrice } from "src/utils";
import { formatCurrency } from "../../utils";
import type { DashboardProjectsItemProps } from "./types";

export const DashboardProjectsDesktopItem: FC<DashboardProjectsItemProps> = ({ project, currencySymbol }) => {
    const { projectInfo, ownershipPercentage, tokenCount, currentValue, images } = project;
    const thumbnailUrl = images?.[0]?.url;

    return (
        <TableRow className="h-12 border-subtle font-inter text-base first:border-b [&>td]:py-3 [&>td]:text-center">
            <TableCell className="flex w-[400px] items-center gap-4">
                <Link
                    href={Routes.projectDetail.fillPathParams({ projectSlug: projectInfo?.slug! })}
                    className="flex h-12 gap-4 font-inter font-semibold text-brand hover:text-brand hover:no-underline focus:text-brand"
                >
                    {thumbnailUrl && <img className="size-12 rounded" src={thumbnailUrl} alt={projectInfo?.title} />}
                    <div className="flex min-w-0 items-center gap-2">
                        <p className="truncate">{projectInfo?.title}</p>
                        <ArrowRightIcon className="size-4 fill-brand-primary" />
                    </div>
                </Link>
            </TableCell>
            <TableCell className="w-1/3">{`${ownershipPercentage.toFixed(2)}%`}</TableCell>
            <TableCell className="w-1/3">{tokenCount}</TableCell>
            <TableCell className="w-1/3 font-semibold">{`${formatCurrency(convertPriceInCentsToPrice(currentValue))} ${currencySymbol}`}</TableCell>
        </TableRow>
    );
};
