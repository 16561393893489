import type { FC } from "react";

import type { Voting } from "src/types";
import { VotingCardInfo } from "./VotingCardInfo";
import { VotingCardQuestion } from "./VotingCardQuestion";

type VotingCardProps = {
    votingItem?: Voting;
};

export const VotingCard: FC<VotingCardProps> = ({ votingItem }) => {
    if (!votingItem) {
        return null;
    }

    return (
        <div className="flex gap-x-8 gap-y-6 rounded border-b bg-gray-100 p-4 pb-6 last:border-0 max-lg:flex-col">
            <VotingCardInfo votingItem={votingItem} />
            <VotingCardQuestion votingItem={votingItem} />
        </div>
    );
};
