import i18n from "i18next";
import languageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { loggerFactory } from "src/logging";
import { isUnitTesting } from "src/utils";
import { DEFAULT_LANGUAGE, Languages } from "./constants";
import cs from "./cs.yaml";
import en from "./en.yaml";

const resources = {
    en: { translation: en },
    cs: { translation: cs },
};

export const initTranslations = () => {
    i18n.use(initReactI18next) // passes i18n down to react-
        .use(languageDetector)
        .init({
            resources,
            fallbackLng: DEFAULT_LANGUAGE,
            detection: {
                convertDetectedLanguage: (lng: string) => {
                    switch (lng) {
                        case "cs-CZ":
                        case "cs":
                            return Languages.cs;
                        default:
                            return Languages.en;
                    }
                },
            },
            interpolation: {
                escapeValue: false, // react already safes from xss
            },
            parseMissingKeyHandler: (key) => {
                if (isUnitTesting) {
                    return key;
                }

                loggerFactory.getLogger("core/translations").warn("Missing key: %s", key);
                return `(${key})`;
            },
        });

    // TODO: add more formatters from src/utils/hooks/useFormatter.ts
    i18n.services.formatter?.add("region", (value, lng) => {
        const formatter = new Intl.DisplayNames([lng ?? DEFAULT_LANGUAGE], { type: "region" });
        return formatter.of(value.toUpperCase()) ?? value;
    });
};

export const setLanguage = (language: Languages) => {
    i18n.changeLanguage(language);
};
