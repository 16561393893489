import type { FC, HTMLAttributes } from "react";

import { UserIcon } from "src/icons";

type AvatarProps = Readonly<
    HTMLAttributes<HTMLImageElement> & {
        avatarSrc?: string;
        userName?: string;
    }
>;

export const Avatar: FC<AvatarProps> = ({ avatarSrc, userName }) => {
    const userInitials = parseUserInitials(userName);
    return (
        <figure>
            {avatarSrc ? (
                <img src={avatarSrc} alt={userName} className="size-6 rounded-full" />
            ) : (
                <figcaption className="flex size-6 items-center justify-center rounded-full bg-brand">
                    {userInitials ? <span className="text-xs text-white">{userInitials}</span> : <UserIcon className="size-4 fill-light" />}
                </figcaption>
            )}
        </figure>
    );
};

const parseUserInitials = (fullUserName?: string) => {
    const userNames = fullUserName?.split(" ");
    if (!userNames || userNames.length === 1) return undefined;
    return `${userNames.at(0)?.at(0)}${userNames.at(-1)?.at(0)}`;
};
