import type { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { DownloadLink, Heading } from "src/components";
import { DownloadIcon } from "src/icons";
import { useTranslation } from "src/translations";
import type { ProjectInfo } from "src/types";
import { getUploadFileName, logGtmEvent } from "src/utils";

type DocumentsProps = Readonly<
    Pick<ProjectInfo, "documents" | "informationProspect"> & {
        isLoading: boolean;
        projectId: number;
    }
>;

export const Documents: FC<DocumentsProps> = ({ isLoading, documents, informationProspect, projectId }) => {
    const { t } = useTranslation();

    // TODO: publicly accessible documents, app-specific documents

    return (
        <div className="rounded-lg border border-primary bg-primary px-8 py-6">
            <Heading level={4} className="mb-6">
                {t("ProjectDetail.Documents.Title")}
            </Heading>
            <div className="flex flex-col gap-4">
                {isLoading ? (
                    <Skeleton count={3} height="2rem" />
                ) : (
                    <>
                        {informationProspect && informationProspect?.url && (
                            <DocumentLink
                                url={informationProspect.url}
                                label={getUploadFileName(informationProspect)}
                                documentType={`information_prospect_${projectId}`}
                            />
                        )}
                        {documents?.map(
                            ({ id, ...propertyDocument }) =>
                                propertyDocument?.url && (
                                    <DocumentLink
                                        key={id}
                                        url={propertyDocument.url}
                                        label={getUploadFileName(propertyDocument)}
                                        documentType={`property_document`}
                                    />
                                ),
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

const DocumentLink: FC<{ url: string; label: string; documentType: string }> = ({ url, label, documentType }) => (
    <DownloadLink className="flex items-center" href={url} onClick={() => logGtmEvent({ event: "document_view", documentType })}>
        <DownloadIcon className="mr-3 size-6 shrink-0" />
        <span className="break-all text-left">{label}</span>
    </DownloadLink>
);
