import type { FC, ReactNode } from "react";

import { CheckIcon, DoubleCheckIcon, PayIcon, RequestIcon, UserIcon } from "src/icons";
import { UserVerificationTypeEnum } from "src/types";
import { cn } from "src/utils";

type StepItemProps = Readonly<{
    isActive: boolean;
    type: UserVerificationTypeEnum;
    name: string;
    description: string;
    timeToComplete: number;
    className: string;
}>;

export const StepItem: FC<StepItemProps> = ({ isActive, type, name, description, timeToComplete, className }) => (
    <div
        className={cn(
            "relative flex items-center gap-4 after:absolute after:left-[27px] after:top-1/2 after:h-20 after:w-px after:bg-[#E9ECEF] after:last:hidden",
            className,
        )}
    >
        <div className={cn("z-10 rounded border border-primary p-3.5", isActive ? "bg-[#f3f4f9]" : "bg-white")}>
            {verificationIconMap[type]}
        </div>
        <div className="flex w-full flex-col">
            <div className="flex justify-between">
                <p className="text-lg font-semibold">{name}</p>
                <p className="text-base">{timeToComplete} min</p>
            </div>
            <p className="text-base">{description}</p>
        </div>
    </div>
);

const verificationIconMap: Record<UserVerificationTypeEnum, ReactNode> = {
    [UserVerificationTypeEnum.SIGNUP]: <UserIcon className="size-6" />,
    [UserVerificationTypeEnum.KYC]: <CheckIcon className="size-6" />,
    [UserVerificationTypeEnum.AML]: <DoubleCheckIcon className="size-6" />,
    [UserVerificationTypeEnum.ORDER]: <RequestIcon className="size-6" />,
    [UserVerificationTypeEnum.INVESTOR]: <PayIcon className="size-6" />,
} as const;
