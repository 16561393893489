import type { FC } from "react";

import { useTranslation } from "src/translations";
import { type Voting, VotingStateEnum } from "src/types";
import { cn, useLocalDate } from "src/utils";

type StatusDateMap = Record<VotingStateEnum, { label: string; date: string | null }>;

type VotingCardInfoDateProps = {
    votingItem: Voting;
    className?: string;
};

export const VotingCardInfoDate: FC<VotingCardInfoDateProps> = ({ votingItem, className }) => {
    const { t } = useTranslation();
    const toLocalDate = useLocalDate();

    const votingDisplayDateMap: StatusDateMap = {
        [VotingStateEnum.Current]: {
            label: t("VotingPage.Card.Info.DateCloses"),
            date: votingItem.endAt ? toLocalDate(new Date(votingItem.endAt)) : null,
        },
        [VotingStateEnum.Future]: {
            label: t("VotingPage.Card.Info.DateStarts"),
            date: votingItem.startAt ? toLocalDate(new Date(votingItem.startAt)) : null,
        },
        [VotingStateEnum.Past]: {
            label: t("VotingPage.Card.Info.DateEnded"),
            date: votingItem.endAt ? toLocalDate(new Date(votingItem.endAt)) : null,
        },
    };

    const votingDateData = votingDisplayDateMap[votingItem.state!];

    return (
        votingDateData.date && (
            <p className={cn("text-sm text-secondary", className)}>
                {votingDateData.label} {votingDateData.date}
            </p>
        )
    );
};
