import type { FC } from "react";

import { ErrorIcon } from "src/icons";
import { useTranslation } from "src/translations";
import type { ErrorSource } from "src/types";
import { Button } from "../Button";

export type ErrorPageProps = Readonly<{
    error?: unknown;
    source?: ErrorSource;
}>;

/**
 * ErrorPage is displayed by ErrorBoundary component when unhandled error occurs in application.
 * This component should be as simple as possible to minimize probability of error occurring within this component, because such
 * error cannot be caught and handled.
 *
 * Type of error is unknown, because it is typed this way in catch(). You may narrow it inside ErrorPage if you want to display different errors in different ways.
 */
export const ErrorPage: FC<ErrorPageProps> = ({ source, error }) => {
    const { t } = useTranslation();

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-50">
            <div className="mx-auto w-full max-w-[90%] md:max-w-[500px]">
                <div className="overflow-hidden rounded-lg border border-gray-200 bg-white shadow-lg">
                    <div className="border-b border-gray-200 p-6">
                        <div className="flex items-center justify-center gap-2 text-brand-error">
                            <ErrorIcon className="size-6 fill-brand-error" />
                            <h2 className="text-center text-xl font-semibold">{t("ErrorPage.title")}</h2>
                        </div>
                    </div>

                    <div className="space-y-6 p-6">
                        <p className="text-center">{t("ErrorPage.text")}</p>

                        <div className="space-y-2">
                            <h3 className="text-sm font-medium">{t("ErrorPage.technicalDetails")}</h3>
                            <div className="space-y-1 rounded-md bg-gray-50 p-4">
                                <p className="text-sm">
                                    <span className="font-medium">{t("ErrorPage.source")}</span>
                                    {source}
                                </p>
                                <p className="text-sm">
                                    <span className="font-medium">{t("ErrorPage.message")}</span>
                                    {error instanceof Error ? error.message : `${error}`}
                                </p>
                            </div>
                        </div>

                        <div className="flex justify-center gap-3">
                            <Button onClick={() => window.location.reload()}>{t("ErrorPage.button")}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
