import type { FC } from "react";

import { useGetAvailableTokensCountByProjectDocumentIdQuery } from "src/serverApi";
import { FundedRequestButton, type FundedRequestButtonProps } from "./FundedRequestButton";
import { InvestButton, type InvestButtonProps } from "./InvestButton";

type CTAProps = Readonly<InvestButtonProps & FundedRequestButtonProps & { className: string }>;

export const CTA: FC<CTAProps> = ({ projectDocumentId, logger, loading, className, ...restInvestProps }) => {
    const { data: availableTokenCount, isLoading: areTokensLoading } = useGetAvailableTokensCountByProjectDocumentIdQuery(
        projectDocumentId!,
        { skip: !projectDocumentId },
    );

    return (
        <div className={className}>
            {availableTokenCount ? (
                <InvestButton {...restInvestProps} />
            ) : (
                projectDocumentId && (
                    <FundedRequestButton projectDocumentId={projectDocumentId} loading={loading || areTokensLoading} logger={logger} />
                )
            )}
        </div>
    );
};
