import { type FC } from "react";

import { Button } from "src/components";
import { Routes } from "src/routes";
import { useConfirmoPaymentMutation } from "src/serverApi";
import { useTranslation } from "src/translations";
import type { DocumentId } from "src/types";
import { logger } from "../logger";
import { PayError } from "./PayError";

type ConfirmoPayProps = Readonly<{
    orderDocumentId: DocumentId;
}>;

export const ConfirmoPay: FC<ConfirmoPayProps> = ({ orderDocumentId }) => {
    const { t } = useTranslation();

    const [pay, { isLoading, isError }] = useConfirmoPaymentMutation();

    const handlePay = async () => {
        try {
            const { uri } = await pay({ orderDocumentId, returnUri: `${window.location.origin}${Routes.orders.path}` }).unwrap();
            window.location.href = uri;
        } catch (error) {
            logger.error("ConfirmoPay", error);
        }
    };

    return (
        <>
            {isError && <PayError error={t("BuyProperty.Pay.ConfirmoPayError")} />}
            <Button isLoading={isLoading} onClick={handlePay}>
                {t("BuyProperty.Pay.ConfirmoPay")}
            </Button>
        </>
    );
};
