import type { FC, InputHTMLAttributes } from "react";
import { forwardRef, useReducer } from "react";

import { EyeCloseIcon, EyeIcon } from "src/icons";
import { cn } from "src/utils";
import { Input } from "./Input";

type SecretInputProps = Readonly<{
    name: string;
    label?: string;
    fullWidth?: boolean;
    error?: string;
}> &
    InputHTMLAttributes<HTMLInputElement>;

export const SecretInput: FC<SecretInputProps> = forwardRef<HTMLInputElement, SecretInputProps>(
    ({ fullWidth, error, label, ...props }, ref) => {
        const [showPassword, toggleShowPassword] = useReducer((prev) => !prev, false);

        return (
            <div className="relative">
                <Input label={label} fullWidth={fullWidth} type={showPassword ? "text" : "password"} error={error} ref={ref} {...props} />
                <button
                    type="button"
                    className={cn("absolute right-3", label ? "top-[2.2rem] translate-y-1" : "top-[1.3rem] -translate-y-1/2")}
                    onClick={toggleShowPassword}
                >
                    {showPassword ? <EyeCloseIcon /> : <EyeIcon />}
                </button>
            </div>
        );
    },
);

SecretInput.displayName = "SecretInput";
