import type { ListenerMiddlewareInstance } from "@reduxjs/toolkit";
import { createAction } from "@reduxjs/toolkit";

import type { AppDispatch, AppState, EmptyActionCreator } from "src/types";
import { listenerMiddleware as untypedListenerMiddleware } from "./listenerMiddleware";

// Cannot be typed by generics during creation, because there is cyclic dependency middleware => AppState => store => middleware.
export const listenerMiddleware = untypedListenerMiddleware as ListenerMiddlewareInstance<AppState, AppDispatch>;

/**
 * This action is dispatched only once after redux store has been set up. It enables to use redux toolkit listeners for one time initialization after application
 * has been started. Listener will be triggered only when registered before this action is dispatched. This is ensured when module with listener has also reducer.
 * However, module without reducer must be imported into setup/store.ts. Otherwise, listener won't be triggered.
 *
 * When this action is dispatched, application config is not yet loaded.
 *
 * You may not dispatch this action, use it only for matching.
 */
export const appInitAction: EmptyActionCreator = createAction("APP_INIT");

export type AppInitAction = ReturnType<typeof appInitAction>;
