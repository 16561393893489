import { type FC, useMemo } from "react";

import { GlobeIcon, HomeSmileIcon, type IconType, SafeIcon } from "src/icons";
import { type LocKey, useTranslation } from "src/translations";
import { convertPriceInCentsToPrice } from "src/utils";
import { formatCurrency } from "../utils";

type DashboardStatsProps = Readonly<{
    invested: number;
    currencySymbol: string;
    noProjects: number;
    noCountries: number;
}>;

export const DashboardStats: FC<DashboardStatsProps> = ({ invested, currencySymbol, noProjects, noCountries }) => {
    const { t } = useTranslation();

    const statsList: StatList = useMemo(
        () => [
            {
                Icon: SafeIcon,
                locKey: "DashboardPage.Stats.Invested",
                value: formatCurrency(convertPriceInCentsToPrice(invested)),
                unit: currencySymbol,
            },
            { Icon: HomeSmileIcon, locKey: "DashboardPage.Stats.NumberOfProjects", value: String(noProjects) },
            { Icon: GlobeIcon, locKey: "DashboardPage.Stats.NumberOfCountries", value: String(noCountries) },
        ],
        [invested, currencySymbol, noProjects, noCountries],
    );

    return (
        <div className="flex flex-wrap max-xl:[&>*]:mb-4">
            {statsList.map(({ Icon, locKey, value, unit }) => (
                <div className="flex flex-1 basis-[220px] gap-4 max-xl:basis-1/2 max-md:basis-full" key={locKey}>
                    <div className="rounded border border-subtle p-3">
                        <Icon className="size-6" />
                    </div>
                    <div className="flex flex-col font-inter">
                        <span className="text-sm">{t(locKey)}</span>
                        <div>
                            <span className="text-2xl">{value} </span>
                            <span className="text-sm">{unit}</span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

type StatList = ReadonlyArray<{
    Icon: IconType;
    locKey: LocKey;
    value: string;
    unit?: string;
}>;
