import type { FC } from "react";

import { cn } from "src/utils";

type StepperItemProps = Readonly<{
    step: number;
    maxSteps: number;
    text: string;
    active: boolean;
    done: boolean;
}>;

export const VerificationStepperItem: FC<StepperItemProps> = ({ active, text, maxSteps, step, done }) => (
    <li
        className={cn(
            "relative hidden h-[32px] flex-1 items-center justify-center whitespace-nowrap bg-subtle leading-none text-primary md:flex md:before:border-l-[16px]",
            "md:before:absolute md:before:left-0 md:before:top-0 md:before:border-y-[16px] md:before:border-y-transparent md:before:border-l-subtle md:before:content-['']",
            "md:after:absolute md:after:left-full md:after:top-0 md:after:z-20 md:after:border-y-[16px] md:after:border-l-[16px] md:after:border-subtle md:after:border-y-transparent md:after:content-['']",
            "md:[&:first-child]:before:content-none md:[&:last-child]:after:content-none",
            { "text-gray-500": done },
            { "flex bg-brand-secondary md:after:border-brand-secondary": active },
        )}
    >
        <span className="hidden md:block">{`${step} ${text}`}</span>
        <span className="md:hidden">{`${step}/${maxSteps} ${text}`}</span>
    </li>
);
