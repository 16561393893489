import type { FC } from "react";

import { Table, TableBody, TableHead, TableHeader, TableRow } from "src/components";
import { useTranslation } from "src/translations";
import { DashboardProjectsDesktopItem } from "./DashboardProjectsDesktopItem";
import { DashboardProjectsMobileItem } from "./DashboardProjectsMobileItem";
import type { DashboardProjectsProps } from "./types";

export const DashboardProjects: FC<DashboardProjectsProps> = ({ projects, currencySymbol }) => {
    const { t } = useTranslation();

    return (
        <>
            <Table className="hidden font-inter lg:block">
                <TableHeader className="border-b border-subtle [&>th]:pb-3">
                    <TableRow className="text-base">
                        <TableHead className="text-left">{t("DashboardPage.ProjectList.Project")}</TableHead>
                        <TableHead className="text-center">{t("DashboardPage.ProjectList.Ownership")}</TableHead>
                        <TableHead className="text-center">{t("DashboardPage.ProjectList.Units")}</TableHead>
                        <TableHead className="text-center">{t("DashboardPage.ProjectList.Value")}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {projects.map((project) => (
                        <DashboardProjectsDesktopItem key={project.projectInfo?.slug} project={project} currencySymbol={currencySymbol} />
                    ))}
                </TableBody>
            </Table>

            <div className="flex flex-col gap-4 lg:hidden">
                {projects.map((project) => (
                    <DashboardProjectsMobileItem key={project.projectInfo?.title} project={project} currencySymbol={currencySymbol} />
                ))}
            </div>
        </>
    );
};
