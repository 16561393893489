// TODO: move to core/tranlsations? or use functions with i18n.language as argument?
import { useTranslation } from "react-i18next";

export const useFormatter = () => {
    const { i18n } = useTranslation();

    const formatCurrency = (value: number, currency?: string) => {
        // Most locales separate thousands by comma, French does it the way we want - by spaces
        const formatOptions: Intl.NumberFormatOptions = currency ? { style: "currency", currency, maximumFractionDigits: 0 } : {};
        const formatter = new Intl.NumberFormat("fr-FR", formatOptions);

        return `${formatter.format(value)}`;
    };

    const formatNumberWithSuffix = (number: number): string => {
        if (i18n.language !== "en") {
            return `${number}.`;
        }

        let suffix: string;

        if (number >= 10 && number <= 20) {
            suffix = "th";
        } else {
            const lastDigit = number % 10;
            switch (lastDigit) {
                case 1:
                    suffix = "st";
                    break;
                case 2:
                    suffix = "nd";
                    break;
                case 3:
                    suffix = "rd";
                    break;
                default:
                    suffix = "th";
                    break;
            }
        }
        return number + suffix;
    };

    const getLocalizedRegionNameFromCountryCode = (countryCode: string) => {
        return new Intl.DisplayNames([i18n.language], { type: "region" }).of(countryCode);
    };

    return { formatCurrency, formatNumberWithSuffix, getLocalizedRegionNameFromCountryCode };
};
