import { type FC, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Heading, Loading, OrderLayout, PopupWindow, TopBar, VerificationStepper } from "src/components";
import { getCurrencyConversionRate } from "src/config";
import { Routes } from "src/routes";
import { getUserCurrency, useGetAvailableTokensCountByProjectDocumentIdQuery, useProjectInfoDetailBySlugQuery } from "src/serverApi";
import { CurrencyFormatter, useTranslation } from "src/translations";
import { ProjectInfoStateEnum, ProjectStateEnum, UserVerificationTypeEnum } from "src/types";
import { convertPriceToTargetCurrency, getCurrentNominalPrice, getValOrThrowError, isNotNullish, useAppSelector } from "src/utils";
import { useUserVerification } from "src/verification";
import { OrderForm } from "./OrderForm";

export const OrderPage: FC = () => {
    const queryParams = Routes.projectOrder.useParams();
    const projectSlug = getValOrThrowError(queryParams.projectSlug, "Missing project slug");
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isVerificationDone, isVerificationLoading } = useUserVerification();
    const userCurrency = useAppSelector(getUserCurrency);
    const conversionRate = useAppSelector(getCurrencyConversionRate);

    const { data: projectInfo, isLoading: projectDetailLoading } = useProjectInfoDetailBySlugQuery(projectSlug!, { skip: !projectSlug });
    const { data: availableTokensCount, isLoading: tokensLoading } = useGetAvailableTokensCountByProjectDocumentIdQuery(
        projectInfo?.project?.documentId!,
        {
            skip: !projectInfo?.project?.documentId,
        },
    );

    const project = projectInfo?.project;
    const projectStatus = project?.state ?? ProjectStateEnum.Unknown;

    const isUserInvestor = useMemo(
        () => (isVerificationLoading ? false : isVerificationDone(UserVerificationTypeEnum.INVESTOR)),
        [isVerificationLoading, isVerificationDone],
    );
    const preventBuying = project?.onlyForCurrentInvestor === true && !isUserInvestor;

    const tokenFee = project?.tokenFee ?? 0;
    const tokenPriceWithoutFee = getCurrentNominalPrice(project?.tokenCurrentNominalPrice);
    const tokenPrice = tokenPriceWithoutFee ? tokenPriceWithoutFee + tokenFee : null;
    const convertedTokenPrice =
        tokenPrice && project?.currency ? convertPriceToTargetCurrency(tokenPrice, project.currency, userCurrency, conversionRate) : null;

    useEffect(() => {
        if (!isVerificationLoading && !projectDetailLoading) {
            const isSoon = projectStatus === ProjectInfoStateEnum.Prepared || projectStatus === ProjectInfoStateEnum.Unknown;
            if (isSoon) {
                PopupWindow.fire({
                    title: t("BuyProperty.FailureProjectSoon.Title"),
                    text: t("BuyProperty.FailureProjectSoon.Text"),
                    confirmButtonText: t("BuyProperty.FailureProjectSoon.ButtonText"),
                }).then(() => navigate(Routes.projectDetail.fillPathParams({ projectSlug })));
            } else if (projectStatus === ProjectInfoStateEnum.Closed) {
                PopupWindow.fire({
                    title: t("BuyProperty.FailureProjectClosed.Title"),
                    text: t("BuyProperty.FailureProjectClosed.Text"),
                    confirmButtonText: t("BuyProperty.FailureProjectClosed.ButtonText"),
                }).then(() => navigate(Routes.projectDetail.fillPathParams({ projectSlug })));
            } else if (preventBuying) {
                PopupWindow.fire({
                    title: t("BuyProperty.UserHasToBeInvestor.Title"),
                    text: t("BuyProperty.UserHasToBeInvestor.Text"),
                    confirmButtonText: t("BuyProperty.UserHasToBeInvestor.ButtonText"),
                }).then(() => navigate(Routes.projectDetail.fillPathParams({ projectSlug })));
            }
        }
    }, [isVerificationLoading, navigate, preventBuying, projectDetailLoading, projectSlug, projectStatus, t]);

    if (projectDetailLoading || tokensLoading) {
        return <Loading isFullPage />;
    }

    return (
        <>
            {!isUserInvestor && <VerificationStepper activeVerificationType={UserVerificationTypeEnum.ORDER} />}
            <TopBar />
            <OrderLayout title={t("BuyProperty.Heading")} projectInfo={projectInfo} openInNewTab>
                <Heading level={6} className="mb-[3px] normal-case">
                    {t("Overview")}
                </Heading>
                <div className="!mb-5 grid grid-cols-2 gap-y-1.5 text-sm">
                    <div>{t("BuyPropertyShare.SharesAvailable")}</div>
                    <div className="text-right">{availableTokensCount ?? "-"}</div>
                    <div>{t("BuyPropertyShare.pricePerShare")}</div>
                    <div className="text-right">
                        {isNotNullish(convertedTokenPrice) && <CurrencyFormatter currency={userCurrency} value={convertedTokenPrice} />}
                    </div>
                    <div>{t("BuyPropertyShare.MinSharesToBuy")}</div>
                    <div className="text-right">{project?.minPurchaseQty ?? ""}</div>
                </div>
                {isNotNullish(tokenPrice) && isNotNullish(project) && (
                    <OrderForm availableShare={availableTokensCount ?? 0} project={project} tokenPrice={tokenPrice} />
                )}
            </OrderLayout>
        </>
    );
};
