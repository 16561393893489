import { cva, type VariantProps } from "class-variance-authority";
import { createElement, type FC, type HTMLAttributes } from "react";

import { cn } from "src/utils";

const headingStyles = cva("font-semibold text-primary", {
    variants: {
        level: {
            1: "text-[2.5rem] leading-[3.25rem]",
            2: "text-[2rem] leading-[2.6rem]",
            3: "text-[1.5rem] leading-[2.1rem]",
            4: "text-[1.125rem] leading-[1.575rem]",
            5: "text-[1.125rem] leading-[1.575rem]",
            6: "text-[1rem] leading-[1.4rem]",
        },
    },
    defaultVariants: {
        level: 1,
    },
});

type HeadingProps = Readonly<HTMLAttributes<HTMLHeadingElement> & VariantProps<typeof headingStyles>>;

export const Heading: FC<HeadingProps> = ({ level, className, children, ...props }) =>
    createElement(`h${level ?? 2}`, { className: cn(headingStyles({ level }), className), ...props }, children);
