import { forwardRef } from "react";
import { NavLink } from "react-router-dom";

import { useTranslation } from "src/translations";
import { cn } from "src/utils";
import type { MenuItem } from "./menuItems";

export const NavigationMenuItem = forwardRef<HTMLLIElement, MenuItem & { className?: string }>(
    ({ Icon, titleKey, route, className }, ref) => {
        const { t } = useTranslation();

        return (
            <li ref={ref} className={cn("list-none", className)}>
                <NavLink
                    to={route.path}
                    className={({ isActive }) =>
                        cn(
                            "group flex items-center gap-3",
                            "hover:no-underline focus:text-brand",
                            "border-l-[3px] border-white fill-tertiary py-2 pl-[21px] pr-[24px] text-secondary transition-colors",
                            "hover:fill-brand hover:text-brand",
                            {
                                "border-brand bg-brand-accent fill-brand text-brand": isActive,
                            },
                        )
                    }
                >
                    <Icon className="size-6 text-tertiary transition-colors group-hover:text-brand" />
                    <span>{t(titleKey)}</span>
                </NavLink>
            </li>
        );
    },
);

NavigationMenuItem.displayName = "NavigationMenuItem";
