import { createContext } from "react";

import type { ConfirmContextValue } from "./types";

export const ConfirmContext = createContext<ConfirmContextValue>({
    // Default value for showConfirm just resolves false. This implementation is used only before ConfirmProvider fills context with real function, which probably cannot occur. This implementation is therefore mostly for type-safety.
    showConfirm: () => Promise.resolve(false),
});

export const ConfirmContextConsumer = ConfirmContext.Consumer;
