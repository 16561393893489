import type { FC } from "react";

import { useGetAvailableTokensCountByProjectDocumentIdQuery } from "src/serverApi";
import { Trans, useTranslation } from "src/translations";
import type { ProjectInfo } from "src/types";
import { ProjectDetailCalculator } from "./Calculator";
import { Documents } from "./Documents";
import { FundedRequestInfo } from "./FundedRequestInfo";
import { HowItWorksStepper } from "./HowItWorksStepper";

type ProjectDetailSidebarProps = Readonly<{
    loading: boolean;
    projectInfo?: ProjectInfo;
}>;

export const ProjectDetailSidebar: FC<ProjectDetailSidebarProps> = ({ loading, projectInfo }) => {
    const { t } = useTranslation();

    const informationProspectUrl = projectInfo?.informationProspect?.url || "";

    const { data: availableTokenCount, isLoading: areTokensLoading } = useGetAvailableTokensCountByProjectDocumentIdQuery(
        projectInfo?.project?.documentId!,
        { skip: !projectInfo?.project?.documentId },
    );
    const areTokensAvailable = !areTokensLoading && availableTokenCount !== undefined && availableTokenCount > 0;

    return (
        <div className="flex h-full flex-col">
            {areTokensAvailable && (
                <>
                    <ProjectDetailCalculator loading={loading} projectInfo={projectInfo} />
                    <p className="mt-6 text-xs text-tertiary">
                        <Trans
                            i18nKey="PropertyDetail.Calculator.InfoAboutCalculation.Value"
                            components={{
                                br: <br />,
                                button: informationProspectUrl ? (
                                    <button
                                        className="underline hover:text-black focus:outline-none focus:ring-2 focus:ring-black/50"
                                        type="button"
                                        aria-label={t("PropertyDetail.Calculator.InfoAboutCalculation.AriaLabel")}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(informationProspectUrl, "_blank");
                                        }}
                                    />
                                ) : (
                                    // Fragment due to typing of components prop
                                    // eslint-disable-next-line react/jsx-no-useless-fragment
                                    <></>
                                ),
                            }}
                        />
                    </p>
                </>
            )}

            {!areTokensLoading && !areTokensAvailable && projectInfo?.project?.documentId && (
                <FundedRequestInfo loading={loading} projectDocumentId={projectInfo?.project?.documentId} />
            )}

            <div className="mt-12 flex flex-col lg:gap-12">
                {projectInfo?.project?.id && (
                    <Documents
                        isLoading={loading}
                        projectId={projectInfo.project?.id}
                        informationProspect={projectInfo?.informationProspect}
                        documents={projectInfo?.documents}
                    />
                )}
                <HowItWorksStepper />
            </div>
        </div>
    );
};
