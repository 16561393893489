import { Routes } from "src/routes";
import { typeCheckAppModule } from "src/utils";
import { NAME } from "./constants";
import { SignInPage } from "./SignInPage";

export const signIn = typeCheckAppModule({
    NAME,
    Container: SignInPage,
    route: Routes.signIn,
    secure: false,
});
