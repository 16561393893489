import { type FC, type ReactNode, useCallback, useMemo, useRef, useState } from "react";

import { ConfirmContext } from "../ConfirmContext";
import type { ConfirmContextValue, ConfirmParams } from "../types";
import { ConfirmDialog } from "./ConfirmDialog";

type ConfirmProviderProps = Readonly<{
    children: ReactNode;
}>;

export const ConfirmProvider: FC<ConfirmProviderProps> = ({ children }) => {
    const [opened, setOpened] = useState(false);
    const [params, setParams] = useState<ConfirmParams | null>(null);
    const resolver = useRef<Function>();

    const handleShow = useCallback(
        (confirmParams: ConfirmParams): Promise<boolean> => {
            setParams(confirmParams);
            setOpened(true);
            /* Return Promise<boolean> which can be resolved inside this component,
             * because we saved resolve function into ref. */
            return new Promise((resolve) => {
                resolver.current = resolve;
            });
        },
        [setOpened],
    );

    const modalContext: ConfirmContextValue = useMemo(
        () => ({
            showConfirm: handleShow,
        }),
        [handleShow],
    );

    const handleConfirm = () => {
        resolver.current?.(true);
        setOpened(false);
    };

    const handleClose = () => {
        resolver.current?.(false);
        setOpened(false);
    };

    return (
        <ConfirmContext.Provider value={modalContext}>
            {children}
            <ConfirmDialog opened={opened} onClose={handleClose} onConfirm={handleConfirm} params={params} />
        </ConfirmContext.Provider>
    );
};
