import { zodResolver } from "@hookform/resolvers/zod";
import type { FC } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { Accordion, Button, ControlledFormCheckbox, Loading, PopupWindow } from "src/components";
import { useAddVerificationMutation, useUpdateUserProfileMutation, useUserQuery } from "src/serverApi";
import { UserVerificationTypeEnum } from "src/types";
import { getValOrThrowError, logGtmEvent } from "src/utils";
import { logger } from "../../logger";
import { AdditionalInfo } from "./AdditionalInfo";
import { getAmlFormScheme } from "./AmlFormScheme";
import { QuestionnaireSection } from "./enums";
import { PersonalInfo } from "./PersonalInfo";

export const AmlForm: FC = () => {
    const { t } = useTranslation();

    const { data: user, isLoading: isUserLoading } = useUserQuery();

    const schema = getAmlFormScheme(t);
    type FormData = z.infer<typeof schema>;

    const [addVerification] = useAddVerificationMutation();
    const [updateProfile] = useUpdateUserProfileMutation();

    const methods = useForm<FormData>({
        resolver: zodResolver(schema),
        mode: "onChange",
        defaultValues: {
            firstName: user?.userProfile?.firstName ?? undefined,
            lastName: user?.userProfile?.lastName ?? undefined,
            residentialAddress: user?.userProfile?.residentialAddress ?? undefined,
            countryAddress: user?.userProfile?.countryAddress ?? undefined,
            cityAddress: user?.userProfile?.cityAddress ?? undefined,
            postalCode: user?.userProfile?.postalCode ?? undefined,
            dateOfBirth: user?.userProfile?.dateOfBirth ?? undefined,
            socialSecurityNumber: user?.userProfile?.socialSecurityNumber ?? undefined,
            idOrPassportNumber: user?.userProfile?.idOrPassportNumber ?? undefined,
            countryOfOrigin: user?.userProfile?.countryOfOrigin ?? undefined,
            citizenship: user?.userProfile?.citizenship ?? undefined,
            documentValidUntil: user?.userProfile?.documentValidUntil ?? undefined,
            documentIssuedBy: user?.userProfile?.documentIssuedBy ?? undefined,
        },
    });

    const handleAmlSubmission = async (data: FormData) => {
        try {
            const loggedUser = getValOrThrowError(user);
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { politicallyExposed, originOfFunds, finalCheck, ...restData } = data;
            const politicallyExposeData = politicallyExposed.isChecked
                ? {
                      politicallyExposed: true,
                      sourceOfWealthSalary: politicallyExposed.sourceOfWealthSalary,
                      sourceOfWealthCapital: politicallyExposed.sourceOfWealthCapital,
                      sourceOfWealthBusiness: politicallyExposed.sourceOfWealthBusiness,
                      sourceOfWealthInheritance: politicallyExposed.sourceOfWealthInheritance,
                      sourceOfWealthOther: politicallyExposed.sourceOfWealthOtherChecked
                          ? politicallyExposed.sourceOfWealthOtherValue
                          : undefined,
                  }
                : {
                      politicallyExposed: false,
                  };
            const originOfFundsData = {
                alimonyBenefitsPension: originOfFunds.alimonyBenefitsPension,
                salary: originOfFunds.salary,
                business: originOfFunds.business,
                capital: originOfFunds.capital,
                other: originOfFunds.otherChecked ? originOfFunds.otherValue : undefined,
            };
            await updateProfile({
                profileDocumentId: getValOrThrowError(loggedUser.userProfile.documentId),
                data: {
                    ...restData,
                    noCriminalActivity: data.noCriminalActivity === "yes",
                    countriesWithSanctions: data.countriesWithSanctions === "yes",
                    ...originOfFundsData,
                    ...politicallyExposeData,
                },
            }).unwrap();
            await addVerification({
                userId: getValOrThrowError(loggedUser.id),
                verificationType: UserVerificationTypeEnum.AML,
            }).unwrap();
            logGtmEvent({ event: "user_aml_verified" }, false);
            await PopupWindow.fire({
                title: t("AMLQuestionnaire.SubmissionSuccess.Title"),
                text: t("AMLQuestionnaire.SubmissionSuccess.Text"),
                confirmButtonText: t("AMLQuestionnaire.SubmissionSuccess.ConfirmButton"),
                timer: 5000,
            });
        } catch (error) {
            logger.error("Error submitting AML form", error);
            await PopupWindow.fire({
                title: t("AMLQuestionnaire.SubmissionError.Title"),
                text: t("AMLQuestionnaire.SubmissionError.Text"),
                confirmButtonText: t("AMLQuestionnaire.SubmissionError.ConfirmButton"),
                timer: 5000,
            });
        }
    };

    if (isUserLoading) {
        return <Loading isFullPage />;
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleAmlSubmission)} className="w-full">
                <Accordion defaultValue={[QuestionnaireSection.PersonalInfo, QuestionnaireSection.AdditionalInfo]}>
                    <PersonalInfo user={getValOrThrowError(user)} />
                    <AdditionalInfo />
                </Accordion>
                <Controller
                    name="finalCheck"
                    control={methods.control}
                    render={({ field }) => (
                        <div className="mb-11 mt-7 text-sm">
                            <label className="flex gap-2">
                                <ControlledFormCheckbox checked={field.value} onCheckedChange={field.onChange} />
                                <span className="peer-disabled:cursor-not-allowed">{t("AMLQuestionnaire.FinalCheck.Label")}</span>
                            </label>
                            {methods.formState.errors.finalCheck && (
                                <span className="text-error">{methods.formState.errors.finalCheck.message}</span>
                            )}
                        </div>
                    )}
                />
                <Button size="large" className="mb-8" fullWidth type="submit" isLoading={methods.formState.isLoading}>
                    {t("AMLQuestionnaire.Submit")}
                </Button>
            </form>
        </FormProvider>
    );
};
