import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { isRejectedWithValue } from "@reduxjs/toolkit";

import { Routes } from "src/routes";
import { hasPropOfType, isNumber, isObject } from "src/utils";
import { removeAuthToken } from "./utils";

export const rtkQueryErrorAuth: Middleware = (_store: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        const { payload } = action;
        if (isObject(payload) && hasPropOfType(payload, "status", isNumber) && payload.status === 401) {
            removeAuthToken();
            window.location.href = `${Routes.signIn.path}?next=${window.location.pathname}&sessionExpired=true`;
        }
    }

    return next(action);
};
